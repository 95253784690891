import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useSelector } from "react-redux";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import AccountCircle from "@mui/icons-material/AccountCircle";
import RoutePath from "../util/RoutePath";
import { useHistory } from "react-router-dom";
import AppUtil from "../util/AppUtil";
import { Dashboard } from "@mui/icons-material";

const PlatformSideBarComponent = () => {
  const history = useHistory();

  const myCurrentOrg = useSelector((state) => state.currentOrg);
  const drawerWidth = 300;

  const organisationHandler = () => {
    history.push(
      RoutePath.routeAppPlatformBusinessListing({
        orgId: myCurrentOrg.organisation.id,
      })
    );
  };

  const platformUserDashboardHandler = () => {
    history.push(
      RoutePath.routeAppPlatformUserDashboard({
        orgId: myCurrentOrg.organisation.id,
      })
    );
  };

  const clientsHandler = () => {
    history.push(
      RoutePath.routeAppPlatformClientsListing({
        orgId: myCurrentOrg.organisation.id,
      })
    );
  };
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          <ListItem button onClick={organisationHandler} key="organisation">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Organisation" />
          </ListItem>
        </List>
        <Divider />
        {AppUtil.getUserId() === AppUtil.getUserPlatformAdministratorId() && (
          <List>
            <ListItem
              button
              onClick={platformUserDashboardHandler}
              key="clients"
            >
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </List>
        )}
        {AppUtil.getUserId() === AppUtil.getUserPlatformAdministratorId() && (
          <List>
            <ListItem button onClick={clientsHandler} key="clients">
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Api Client" />
            </ListItem>
          </List>
        )}
        <Divider />
      </Box>
    </Drawer>
  );
};
export default PlatformSideBarComponent;
