import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class V2BusinessScoreRequestBankApi {
  static path = "v2/business-score-request/bank";

  static addBank = ({ bsrId, bankId, accountType, accountNumber, bankAccountHolderName,sanctionLimit, monthlyDrawingPowerLimit }) => {
    let path = V2BusinessScoreRequestBankApi.path;
    const command = {
        bsrId: bsrId,
        bankId: bankId,
        accountType: accountType,
        accountNumber: accountNumber,
        bankAccName: bankAccountHolderName,
        entityType: 0,
        sanctionLimit: (sanctionLimit ? parseInt(sanctionLimit): null),
        monthlyDrawingPowerLimit: monthlyDrawingPowerLimit 
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static changeBankStatus = ({ bsrId, bankId, accountNumber, accountType, action, value }) => {
    let path = V2BusinessScoreRequestBankApi.path;
    const actionValue = {
      "active": value
    }
    const command = {
        bsrId: bsrId,
        bankId: bankId,
        accountNumber: accountNumber,
        accountType: accountType,
        action: action,
        value: actionValue
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PATCH,
      command: command,
    });
  };
  
  static updateBank = ({ bsrId, bankId, accountType, accountNumber, bankAccName,sanctionLimit, monthlyDrawingPowerLimit }) => {
    let path = V2BusinessScoreRequestBankApi.path;
    const command = {
        bsrId: bsrId,
        bankId: bankId,
        accountType: accountType,
        accountNumber: accountNumber,
        bankAccName: bankAccName,
        // entityType: 0,
        sanctionLimit: sanctionLimit,
        monthlyDrawingPowerLimit: monthlyDrawingPowerLimit
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PUT,
      command: command,
    });
  };

  static addBankStatement = ({ bsrId, bankId, accountNumber, accountType,file, filePassword }) => {
    let path = V2BusinessScoreRequestBankApi.path + "/statement";
    const command = {
        bsrId: bsrId,
        bankId: bankId,
        accountNumber: accountNumber,
        accountType: accountType,
        file: file,
        password: (filePassword === "" ? null : filePassword)
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static changeBankStatementActiveStatus = ({ bsrId, attachmentId, action, value }) => {
    let path = V2BusinessScoreRequestBankApi.path + "/statement";
    const actionValue = {
      "active": value
    }
    const command = {
        bsrId: bsrId,
        attachmentId: attachmentId,
        action: action,
        value: actionValue
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PATCH,
      command: command,
    });
  };

  static getAllBanksData = () => {
    let path = "v1/bank/names";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static getBankDetails = ({bsrId, businessBankId}) => {
    let path = "business-score-request/" + bsrId + "/bank/" + businessBankId + "/detail";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static getBankMasterData = () => {
    let path = V2BusinessScoreRequestBankApi.path + "/master";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    })
  };
}
