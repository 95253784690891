import { Grid, Typography } from "@mui/material";
import React from "react";
import WarningIcon from "@mui/icons-material/Warning";

const NoDataFoundComponent = () => {
  return (
    <React.Fragment>
      <Grid container justifyContent="center" alignItems sx={{padding:"8px"}}>
        <Grid item>
          <WarningIcon />
        </Grid>
          <Typography variant="caption">No Data Found</Typography>
      </Grid>
    </React.Fragment>
  );
};
export default NoDataFoundComponent;
