import {
  Button,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ListingCardComponent = (props) => {
  const item = props.item;
  const title = props.title;
  const titleClickHandler = props.titleClickHandler;
  const avatar = props.avatar;
  const subTitle1 = props.subTitle1;
  const subTitle2 = props.subTitle2;
  const subTitle3 = props.subTitle3;
  const subTitleHeader1 = props.subTitleHeader1;
  const subTitleValue1 = props.subTitleValue1;
  const subTitleHeader2 = props.subTitleHeader2;
  const subTitleValue2 = props.subTitleValue2;
  const subTitleHeader3 = props.subTitleHeader3;
  const subTitleValue3 = props.subTitleValue3;
  const column1 = props.column1;
  const column2 = props.column2;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const action1 = props.action1;
  const action2 = props.action2;
  const action3 = props.action3;
  const action4 = props.action4;
  const action5 = props.action5;
  const hint = props.hintText;

  const open = Boolean(anchorEl);

  const isAction = () => {
    return !(action1 || action2 || action3 || action4 || action5);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionHandler1 = () => {
    handleClose();
    props.actionHandler1(item);
  };

  const actionHandler2 = () => {
    handleClose();
    props.actionHandler2(item);
  };
  const actionHandler3 = () => {
    handleClose();
    props.actionHandler3(item);
  };
  const actionHandler4 = () => {
    handleClose();
    props.actionHandler4(item);
  };
  const actionHandler5 = () => {
    handleClose();
    props.actionHandler5(item);
  };
  const titleHandler = () => {
    titleClickHandler(item);
  };

  const renderTitleTxt = () => {
    return (
      <Typography
        variant="h7"
        display="inline"
        gutterBottom
        component="div"
        noWrap
      >
        {title}
      </Typography>
    );
  };

  const renderHintTxt = () => {
    return hint === "" || hint == null ? (
      <></>
    ) : (
      <Grid item marginTop={1} marginBottom={-6}>
        <WarningAmberIcon
          sx={{ color: "orange", height: "2em", paddingRight: "2px" }}
          fontSize="10px"
        />
        <Typography
          variant="caption"
          display="inline-block"
          gutterBottom
          component="div"
          color="orange"
          noWrap
        >
          {hint}
        </Typography>
      </Grid>
    );
  };

  const renderTitle = () => {
    return (
      <React.Fragment>
        {!titleClickHandler && renderTitleTxt()}
        {titleClickHandler && (
          <Link
            onClick={titleHandler}
            color="primary"
            component="button"
            underline="none"
            variant="h6"
          >
            {renderTitleTxt()}
          </Link>
        )}
        <br />
      </React.Fragment>
    );
  };

  const renderSubTitle = ({ subTitle, subTitleHeader, subTitleValue }) => {
    if (subTitle) {
      return (
        <React.Fragment>
          {subTitle}
          <br />
        </React.Fragment>
      );
    } else {
      if (subTitleHeader) {
        return (
          <React.Fragment>
            <Typography
              variant="caption"
              // sx={{ fontWeight: "bold" }}
              display="inline"
            >
              {subTitleHeader} :{" "}
            </Typography>
            <Typography variant="caption" display="inline" noWrap>
              {subTitleValue}
            </Typography>
            <br />
          </React.Fragment>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        p={1}
      >
        <Grid item xs={1}>
          {!avatar && (
            <Avatar
              variant={"square"}
              sx={{
                width: "100%",
                height: "100%",
                fontSize: 80,
                fontWeight: "bold",
                // marginLeft: 2,
              }}
            >
              {title.charAt(0).toUpperCase()}
            </Avatar>
          )}
          {avatar && avatar}
        </Grid>
        <Grid item xs zeroMinWidth>
          {renderTitle()}

          {renderSubTitle({
            subTitle: subTitle1,
            subTitleHeader: subTitleHeader1,
            subTitleValue: subTitleValue1,
          })}
          {renderSubTitle({
            subTitle: subTitle2,
            subTitleHeader: subTitleHeader2,
            subTitleValue: subTitleValue2,
          })}
          {renderSubTitle({
            subTitle: subTitle3,
            subTitleHeader: subTitleHeader3,
            subTitleValue: subTitleValue3,
          })}
        </Grid>
        <Grid item>
          <Grid container direction={"row"}>
            {column1 && (
              <Grid item xs="auto" zeroMinWidth marginRight={2}>
                {column1}
              </Grid>
            )}
            {column2 && (
              <Grid item xs="auto" zeroMinWidth marginRight={2}>
                {column2}
              </Grid>
            )}

            <Grid item xs="auto" zeroMinWidth>
              <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                disabled={isAction()}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Actions
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {action1 && (
                  <MenuItem onClick={actionHandler1}>{action1}</MenuItem>
                )}
                {action2 && (
                  <MenuItem onClick={actionHandler2}>{action2}</MenuItem>
                )}
                {action3 && (
                  <MenuItem onClick={actionHandler3}>{action3}</MenuItem>
                )}
                {action4 && (
                  <MenuItem onClick={actionHandler4}>{action4}</MenuItem>
                )}
                {action5 && (
                  <MenuItem onClick={actionHandler5}>{action5}</MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
          {renderHintTxt()}
        </Grid>
      </Grid>
      <Divider />
    </React.Fragment>
  );
};
export default ListingCardComponent;
