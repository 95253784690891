import { HttpMethodType } from "../../util/HttpMethodType";
import MeekoApiRESTUtil from "../../util/MeekoRESTUtil";

export default class MeekoIndustrySectorApi {
  static getIndustrySector = () => {
    let path = "organisations/industry-types";
    return MeekoApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };
}
