import * as React from "react";
import PlatformAdminDashboardComponent from "../../component/platform_admin_dashboard/PlatformAdminDashboardComponent";

const PlatformAdminDashboardPage = () => {

  return (
    <React.Fragment>
      <PlatformAdminDashboardComponent />
    </React.Fragment>
  );
};

export default PlatformAdminDashboardPage;
