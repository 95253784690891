import * as React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import SystemDefault from "../../util/SystemDefault";

const PaginationStatusBarComponent = (props) => {
  const pageCount = props.pageCount;
  const page = props.page;
  const records = props.records;
  const onPageChangeHandler = (event, value) => {
    props.onPageChangeHandler(value);
  };

  const onRecordsChangeHandler = (event) => {
    props.onPageChangeHandler(1);
    props.onRecordsChangeHandler(event.target.value);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      py={3}
      px={5}
    >
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Num.</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={records}
                label="Records"
                onChange={onRecordsChangeHandler}
              >
                <MenuItem value={SystemDefault.PAGE_SIZE[0]}>5</MenuItem>
                <MenuItem value={SystemDefault.PAGE_SIZE[1]}>10</MenuItem>
                <MenuItem value={SystemDefault.PAGE_SIZE[2]}>20</MenuItem>
                <MenuItem value={SystemDefault.PAGE_SIZE[3]}>50</MenuItem>
                <MenuItem value={SystemDefault.PAGE_SIZE[4]}>100</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item> Records per page</Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Pagination
          count={pageCount}
          page={page}
          onChange={onPageChangeHandler}
          color="primary"
        />
      </Grid>
    </Grid>
  );
};

export default PaginationStatusBarComponent;
