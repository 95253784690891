import React, { useEffect } from "react";
import { BarChart } from "@mui/x-charts";
import StatsBusinessScoreRequestApi from "../../http/v1_apis/StatsBusinessScoreRequestApi";
import { CircularProgress, Typography } from "@mui/material";
import NoDataFoundComponent from "../common/NoDataFoundComponent";

const GbsYearlyChartComponent = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [chartXLabel, setChartXLabel] = React.useState([]);
  const [chartSuccessCount, setChartSuccessCount] = React.useState([]);
  const [chartErrorCount, setChartErrorCount] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    setChartXLabel([]);
    setChartSuccessCount([]);
    setChartErrorCount([]);

    StatsBusinessScoreRequestApi.getYearlyStatsData({
      duration: props.duration,
      apiClientId: props.apiClientId,
    }).then((response) => {
      var lbl = [];
      var sus = [];
      var err = [];
      response.forEach((res) => {
        lbl.push(res["id"]);
        sus.push(res["successCount"]);
        err.push(res["errorCount"]);
      });
      setChartXLabel(lbl);
      setChartSuccessCount(sus);
      setChartErrorCount(err);
      setLoading(false);
    });
  }, [props.apiClientId, props.duration]);

  let myComponent;

  if (chartXLabel.length > 0) {
    myComponent = (
      <BarChart
        xAxis={[{ scaleType: "band", data: chartXLabel }]}
        series={[
          {
            data: chartSuccessCount,
            label: "success",
            color: "#55f74c",
          },
          { data: chartErrorCount, label: "error", color: "#f28e8e" },
        ]}
        width={600}
        height={400}
      />
    );
  } else {
    myComponent = <NoDataFoundComponent />;
  }
  return (
    <div>
      <Typography variant="h4">Yearly GBS Request Status</Typography>
      {loading && <CircularProgress size={24} />}
      {!loading && myComponent}
    </div>
  );
};
export default GbsYearlyChartComponent;
