import * as React from "react";
import BusinessDetailComponent from "../../component/business/BusinessDetailComponent";
import TitleBarComponent from "../../component/common/TitleBarComponent";
import { Grid } from "@mui/material";

const PlatformBusinessDetailPage = () => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <TitleBarComponent title={"Organisation Basic Details"} />
      </Grid>
      <Grid item>
        <BusinessDetailComponent />
      </Grid>
    </Grid>
  );
};

export default PlatformBusinessDetailPage;
