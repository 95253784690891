import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AuthApi from "../../http/theodore/AuthApi";
import AppUtil from "../../util/AppUtil";
import { Link, useHistory } from "react-router-dom";
import RoutePath from "../../util/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { jwtTokenActions } from "../../store/JwtTokenSlice";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StringUtil from "../../util/StringUtil";
import { SnackBarActions } from "../../store/SnackBarSlice";
import Paper from "@mui/material/Paper";
import { SnackBarMessageType } from "../../util/Constant";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loaderActions } from "../../store/LoaderSlice";
import { currentUserInfoActions } from "../../store/CurrentUserInfoSlice";

const SignInComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loader.value);
  const isDisabled = loading;

  const [isLoginByOtp, setIsLoginByOtp] = useState(false);
  const [loginByText, setLoginByText] = useState("Request OTP");

  const [emailMobile, setEmailMobile] = useState("");
  const [emailMobileHelpText, setEmailMobileHelpText] = useState("");
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [isEmailMobileError, setIsEmailMobileError] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [passwordHelpText, setPasswordHelpText] = useState("");

  const [isResendClickable, setIsResendClickable] = useState("none");
  const [resendOtpColor, setResendOtpColor] = useState("grey");
  const [requestOtpLoader, setRequestOtpLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpError, setIsOtpError] = useState(false);
  const [otpHelpText, setOtpHelpText] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    AppUtil.removeUserId();
    AppUtil.removeCurrentOrgId();
    AppUtil.removeExpireTime();
  }, []);


  const emailMobileInputChangeHandler = (event) => {
    setEmailMobile(event.target.value);
    if (event.target.value.trim() === "") {
      setIsEmailMobileError(true);
      setEmailOrMobile("");
      setEmailMobileHelpText("Email or Mobile is required");
    } else {
      const result = StringUtil.isValidEmailMobile(event.target.value);
      if (result.result === true) {
        setIsEmailMobileError(false);
        setEmailOrMobile(result.type);
        setEmailMobileHelpText("");
      } else {
        setIsEmailMobileError(true);
        setEmailOrMobile("");
        setEmailMobileHelpText(result.errMsg);
      }
    }
  };

  const passwordInputChangeHandler = (event) => {
    setPassword(event.target.value);
    if (event.target.value.trim() === "") {
      setIsPasswordError(true);
      setPasswordHelpText("password is required");
    } else {
      setIsPasswordError(false);
      setPasswordHelpText("");
    }
  };

  const otpInputChangeHandler = (event) => {
    setOtp(event.target.value);
    if (event.target.value.trim() === "") {
      setIsOtpError(true);
      setOtpHelpText("otp is required");
    } else {
      if (event.target.value.length !== 6) {
        setIsOtpError(true);
        setOtpHelpText("otp length should be 6");
      } else {
        setIsEmailMobileError(false);
        setPassword("");
        setIsOtpError(false);
        setOtpHelpText("");
      }
    }
  };

  const loginOptionHandler = (event) => {
    if (isLoginByOtp) {
      setIsLoginByOtp(false);
      setLoginByText("Request OTP");
    } else {
      requestOtpHandler();
    }
  };

  const requestOtpHandler = (event) => {
    if (emailMobile === "") {
      setIsEmailMobileError(true);
      setEmailOrMobile("");
      setEmailMobileHelpText("Email or Mobile id is required");
      return;
    } else {
      const result = StringUtil.isValidEmailMobile(emailMobile);
      if (result.result === true) {
        setIsEmailMobileError(false);
        setEmailMobileHelpText("");
        setIsLoginByOtp(true);
        setLoginByText("Login by Password");
      } else {
        setIsEmailMobileError(true);
        setEmailOrMobile("");
        setEmailMobileHelpText(result.errMsg);
        return;
      }
    }
    // dispatch(loaderActions.startLoader());
    setRequestOtpLoader(true);
    if (emailOrMobile === "email") {
      AuthApi.requestEmailLoginOtp({ email: emailMobile })
        .then((response) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Otp sent on your email successfully",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
          // dispatch(loaderActions.stopLoader());
          setRequestOtpLoader(false);
          setIsResendClickable("none");
          setResendOtpColor("grey");
          setTimeout(() => {
            setIsResendClickable("");
            setResendOtpColor("");
          }, 15000);
        })
        .catch((reject) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: reject.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
        });
    } else {
      AuthApi.requestMobileLoginOtp({ mobile: emailMobile })
        .then((response) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Otp sent on your Mobile successfully",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
          // dispatch(loaderActions.stopLoader());
          setRequestOtpLoader(false);
          setIsResendClickable("none");
          setResendOtpColor("grey");
          setTimeout(() => {
            setIsResendClickable("");
            setResendOtpColor("");
          }, 15000);
        })
        .catch((reject) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: reject.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
        });
    }
  };

  const requestOtpComponent = () => {
    if (requestOtpLoader) {
      return <CircularProgress sx={{ mx: 2 }} size={25} />;
    } else {
      return (
        <Link
          to={"#"}
          onClick={requestOtpHandler}
          style={{
            textDecoration: "none",
            pointerEvents: isResendClickable,
            color: resendOtpColor,
          }}
          tabIndex={0}
        >
          {"Resend"}
        </Link>
      );
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (isEmailMobileError || isPasswordError || isOtpError) {
      return;
    }
    dispatch(loaderActions.startLoader());
    AuthApi.validateUserCredential({
      type: emailOrMobile,
      emailMobile,
      password,
      otp,
    })
      .then((response) => {
        AppUtil.setJwtToken(response.data.token);
        // TODO  : Store user id in local storage
        dispatch(jwtTokenActions.setJwtToken(response.data.token));
        dispatch(
          currentUserInfoActions.setCurrentUserInfo({
            userDetails: response.data,
          })
        );
        // dispatch(currentUserInfoActions.setCurrentUserId({userId:response.data.userId}));
        AppUtil.setUserId(response.data.userId);
        AppUtil.setExpireTime(response.data.expiry)
        history.push(RoutePath.KEY_HOME);
        dispatch(loaderActions.stopLoader());
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        dispatch(loaderActions.stopLoader());
      });
  };

  return (
    <Paper elevation={3}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          pb={4}
          pt={5}
          sx={{
            marginTop: 14,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img height={40} src="/assets/images/vay.png" alt="mobile" />
          <br />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={onSubmitHandler}>
            <TextField
              variant="outlined"
              error={isEmailMobileError}
              helperText={emailMobileHelpText}
              onChange={emailMobileInputChangeHandler}
              margin="normal"
              required
              fullWidth
              id="emailMobile"
              label="Email or Mobile"
              name="emailMobile"
              autoComplete="emailMobile"
              autoFocus
              disabled={isDisabled}
            />
            {!isLoginByOtp && (
              <TextField
                variant="outlined"
                error={isPasswordError}
                helperText={passwordHelpText}
                onChange={passwordInputChangeHandler}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {isLoginByOtp && (
              <TextField
                variant="outlined"
                error={isOtpError}
                helperText={otpHelpText}
                onChange={otpInputChangeHandler}
                margin="normal"
                required
                fullWidth
                name="otp"
                label="OTP"
                type="text"
                id="otp"
                InputProps={{ endAdornment: requestOtpComponent() }}
              />
            )}
            {/* <Grid container justifyContent="flex-end">
              <nav>
                <Link to={'#'} onClick={requestOtpHandler} style={{textDecoration:'none'}} tabIndex={0}>{requestOtpText}</Link>
              </nav>
            </Grid> */}
            <Grid container justifyContent="flex-end">
              <nav>
                <Link
                  to={"#"}
                  onClick={loginOptionHandler}
                  style={{ textDecoration: "none" }}
                  tabIndex={0}
                >
                  {loginByText}
                </Link>
              </nav>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isDisabled}
            >
              Sign In{" "}
              {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to={RoutePath.APP_FORGOT_PASS} variant="body2">
                  Forgot Password?
                </Link>
              </Grid>
              <Grid item>
                <nav>
                  <Link to={RoutePath.APP_SIGNUP}>{"New User? Sign Up"}</Link>
                </nav>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Paper>
  );
};

export default SignInComponent;
