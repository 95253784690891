import {
  Button,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  Typography,
  InputAdornment,
  CircularProgress,
  Switch,
} from "@mui/material";
import React, { useEffect } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import axios from "axios";
import AppUtil from "../../../util/AppUtil";

import { useDispatch } from "react-redux";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteIcon from "@mui/icons-material/Delete";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useState } from "react";
import { SnackBarActions } from "../../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../../util/Constant";

const GBSBankStatementFileListItemComponent = (props) => {
  const [password, setPassword] = useState();
  const dispatch = useDispatch();
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isLoaderDisabled, setIsLoaderDisabled] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [isFilePasswordValid, setFilePasswordValid] = useState(false);
  const [isFilePasswordTouch, setIsFilePasswordTouch] = useState(false);
  const [filePasswordVisibility, setFilePasswordVisibility] = useState("none");
  const filePasswordErrMsg = "";

  const filePasswordInvalid =
    !isFilePasswordValid && isFilePasswordTouch && isPasswordError;

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (props.item.isFileVerified) {
      setFilePasswordVisibility("none");
      setIsFilePasswordTouch(true);
      setFilePasswordValid(true);
    } else {
      if (props.isCommonPassword) {
        setFilePasswordVisibility("none");
      } else {
        setFilePasswordVisibility("block");
      }
    }
    if (props.isCommonPassword && !props.item.isFileVerified) {
      setPassword(props.commonPassword);
    }
  },[props.item.isFileVerified, props.isCommonPassword, props.commonPassword]);

  const passwordInputChangeHandler = (event) => {
    setIsFilePasswordTouch(true);
    setPassword(event.target.value);
    if (event.target.value.trim() === "") {
      setIsPasswordError(true);
    } else {
      setIsPasswordError(false);
    }
  };

  const filePasswordVerification = (event) => {
    event.preventDefault();
    let formData = new FormData();
    // if(isFilePasswordTouch && !isPasswordError){
    //TODO: API call for verify file password
    setIsLoaderDisabled(false);

    formData.append("bsrId", props.bsrId);
    formData.append("bankId", props.bankDetailItem.bankId);
    formData.append("accountNumber", props.bankDetailItem.accountNumber);
    formData.append("accountType", props.bankDetailItem.accountType);
    formData.append("file", props.item.file);
    if (password) {
      formData.append("password", password);
    }
    axios
      .post(
        AppUtil.getApiBaseUrl() + "v2/business-score-request/bank/statement",
        formData,
        {
          headers: {
            Authorization: AppUtil.getJwtToken(),
            "org-id": AppUtil.getCurrentOrgId(),
            "Content-Type": "multipart/form-data",
            "source-token": AppUtil.getGbsAppSourceToken(),
          },
        }
      )
      .then((response) => {
        if (response.data["status"] === 1) {
          const fileId = response.data.data.attachmentId;
          setFilePasswordValid(true);
          props.changeBankStatementVerifiedStatus(props.item.id, fileId, true);
        } else {
          setFilePasswordValid(false);
          setIsPasswordError(true);
          let errorResponse = response.data["error"];
          while (true) {
            let errorData = errorResponse["args"];
            if (
              Object.keys(errorData).length === 0 ||
              !("message" in errorData)
            ) {
              if ("fileType" in errorData) {
                dispatch(
                  SnackBarActions.openSnackBar({
                    message: errorData["fileType"],
                    messageType: SnackBarMessageType.ERROR,
                  })
                );
              } else {
                dispatch(
                  SnackBarActions.openSnackBar({
                    message: errorResponse["message"],
                    messageType: SnackBarMessageType.ERROR,
                  })
                );
              }
              break;
            }
            errorResponse = errorData;
          }
        }
      })
      .catch((reject) => {
        setFilePasswordValid(false);
        setIsPasswordError(true);
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.response.data.error.args.err,
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });
    setIsLoaderDisabled(true);

    // } else {
    //   setIsFilePasswordTouch(true);
    //   setIsPasswordError(true);
    // }
  };

  const bankStatementActiveStatusChange = (event) => {
    props.bankStatementActiveStatusChange(
      props.item.id,
      props.item.fileId,
      event.target.checked ? true : false
    );
  };

  return (
    <ListItem
      key={props.item.id}
      sx={{
        bgcolor: "#edf4ff",
        borderRadius: 2,
        marginTop: "0.7rem",
        width: "53vw",
      }}
    >
      <Grid container alignItems="center" padding={1}>
        <Grid item container spacing={2} xs={6} md={6} alignItems="center">
          <Grid item>
            <PictureAsPdfIcon color="primary" />
          </Grid>
          <Grid item sx={{ marginLeft: 1.5, width: 320 }}>
            <Typography fontSize={15} value={props.item.fileName}>
              {props.item.fileName}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          xs={8}
          md={6}
          alignItems="center"
          justifyContent="end"
        >
          <Grid item>
            <TextField
              variant="outlined"
              error={filePasswordInvalid}
              helperText={filePasswordErrMsg}
              onChange={passwordInputChangeHandler}
              value={password}
              name="password"
              label="Password"
              size="small"
              style={{ display: filePasswordVisibility }}
              type={showPassword ? "text" : "password"}
              id="password"
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sx={{ width: 90 }}>
            {!isLoaderDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
            {isLoaderDisabled && isFilePasswordValid && (
              <div>
                <DoneAllIcon color="success" />
              </div>
            )}
            {isLoaderDisabled && !isFilePasswordValid && (
              <Button
                onClick={filePasswordVerification}
                disabled={!props.item.active}
              >
                Verify
              </Button>
            )}
          </Grid>
          {/* <Grid item>
            <Tooltip title="Remove" arrow onClick={bankStatementFileRemoveHandler}>
              <IconButton edge="end" aria-label="comments">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid> */}
          {props.item.isFileVerified ? (
            <Grid item>
              <Tooltip title={props.item.active ? "Active" : "Inactive"} arrow>
                <Switch
                  checked={props.item.active}
                  onChange={bankStatementActiveStatusChange}
                  size="small"
                />
              </Tooltip>
            </Grid>
          ) : (
            <Grid item>
              <Tooltip title="Remove" arrow>
                <DeleteIcon
                  style={{ color: "gray" }}
                  onClick={bankStatementActiveStatusChange}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default GBSBankStatementFileListItemComponent;
