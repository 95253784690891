import { Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import UserHomePage from "./UserHomePage";
import RoutePath from "../util/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import AppBarComponent from "../component/AppBarComponent.";
import OrgRoute from "./OrgRoute";
import { Box, Toolbar } from "@mui/material";
import OrgCreatePage from "./org/OrgCreatePage";
import AppUtil from "../util/AppUtil";
import UserApi from "../http/theodore/UserApi";
import { myOrgActions } from "../store/MyOrgSlice";
import AppSideBarComponent from "../component/AppSideBarComponent";
import PlatformRoute from "./PlatformRoute";
import PlatformSideBarComponent from "../component/PlatformSideBarComponent";
import ProfilePage from "./user/ProfilePage";
import { currentUserInfoActions } from "../store/CurrentUserInfoSlice";
import { useHistory } from "react-router-dom";
import SignInPage from "./user/LoginPage";
import NotFoundPage from "./NotFoundPage";

const AppRoute = () => {
  const dispatch = useDispatch();
  const jwtToken = AppUtil.getJwtToken();
  const currentOrg = useSelector((state) => state.currentOrg);
  const isValidJwtToken = jwtToken !== undefined && jwtToken !== null;
  const history = useHistory();

  useEffect(() => {
    if (isValidJwtToken) {
      UserApi.getSelfOrgs().then((response) => {
        dispatch(myOrgActions.setMyOrg(response));
        dispatch(currentUserInfoActions.setCurrentUserInfo(response.data));
      });
    } else {
      history.push(RoutePath.APP_LOGIN);
    }
  }, [dispatch, history, isValidJwtToken]);

  const loadAppSideBarComponent = () => {
    if (currentOrg.organisation !== null) {
      if (currentOrg.organisation.id === AppUtil.getPlatformOrgId()) {
        return <PlatformSideBarComponent />;
      } else {
        return <AppSideBarComponent />;
      }
    }
  };
  return (
    <React.Fragment>
      {isValidJwtToken === false && <SignInPage />}

      <Box sx={{ display: "flex" }}>
        <AppBarComponent />
        {/*{currentOrg.id !== null && <AppSideBarComponent />};*/}
        {loadAppSideBarComponent()}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Switch>
            <Route path={RoutePath.KEY_HOME} exact>
              <UserHomePage />
            </Route>
            <Route path={RoutePath.KEY_PROFILE_DETAILS} exact>
              <ProfilePage />
            </Route>
            <Route path={RoutePath.APP_ORG_CREATE} exact>
              <OrgCreatePage />
            </Route>
            <Route path={RoutePath.APP_ORG_DETAIL}>
              <OrgRoute />
            </Route>
            <Route path={RoutePath.APP_PLATFORM_DETAIL}>
              <PlatformRoute />
            </Route>
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default AppRoute;
