import {
  Box,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import AppUtil from "../../util/AppUtil";
import React from "react";
import Avatar from "@mui/material/Avatar";

const OrgCardComponent = (props) => {
  const onClickHandler = () => {
    props.onCardClickHandler(props.org.id);
  };
  return (
    <Grid item xs={3}>
      <Card
        sx={{
          display: "flex",
          height: "120px",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={onClickHandler}
      >
        {props.org.id === AppUtil.getPlatformOrgId() && (
          <img
            src="/assets/images/GBS-Logo-Unit.png"
            alt="mobile"
            width="35%"
            height="auto"
            style={{ objectFit: "contain" }}
          />
        )}
        {props.org.id !== AppUtil.getPlatformOrgId() && (
          <Avatar
            variant={"square"}
            sx={{
              width: "35%",
              height: 190,
              fontSize: 80,
              fontWeight: "bold",
              alignSelf: "center",
            }}
          >
            {props.org.name.trim().charAt(0).toUpperCase()}
          </Avatar>
        )}
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              boxSizing: "border-box",
            }}
          >
            <Tooltip title={props.org.name} arrow>
              <Typography
                overflow={"hidden"}
                sx={{
                  width: `calc(90%)`,
                  wordBreak: "break-all",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                component={"span"}
              >
                {props.org.id === AppUtil.getPlatformOrgId() && "PLATFORM"}
                {props.org.id !== AppUtil.getPlatformOrgId() && props.org.name}
              </Typography>
            </Tooltip>
            <Tooltip
              title={`${props.org.taxIdentifierType.toUpperCase()} :
                  ${props.org.taxIdentifier}`}
              arrow
            >
              <Typography variant="subtitle1" color="gray">
                {props.org.taxIdentifierType.toUpperCase()} :{" "}
                {props.org.taxIdentifier}
              </Typography>
            </Tooltip>
            <Tooltip title={props.org.country} arrow>
              <Typography variant="subtitle1" color="gray">
                Country: {props.org.country}
              </Typography>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default OrgCardComponent;
