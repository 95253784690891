import React, { useState } from "react";
import ForgotPasswordComponent from "../../component/authorization/ForgotPasswordComponent";

import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import VerifyForgotPasswordOtpComponent from "../../component/authorization/VerifyForgotPasswordOtpComponent";

const ForgotPasswordPage = () => {
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [emailMobile, setEmailMobile] = useState("");
  const [password, setPassword] = useState("");
  const [otpSuccess, setOtpSuccess] = useState(false);

  const changeOtpStatus = (emailOrMobile, emailMobile, password) => {
    setEmailOrMobile(emailOrMobile);
    setEmailMobile(emailMobile);
    setPassword(password);
    setOtpSuccess(true);
  };
  const handleOtpError = () => {
    setOtpSuccess(false);
  };
  return (
    <Paper
      elevation={0}
      sx={{ background: "linear-gradient(to right, #D03C37, #284281)" }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={4}>
          <div>
            {otpSuccess && (
              <VerifyForgotPasswordOtpComponent
                password={password}
                emailOrMobile={emailOrMobile}
                emailMobile={emailMobile}
              />
            )}
            {!otpSuccess && (
              <ForgotPasswordComponent
                purpose="ResetPasswordOTP"
                onSuccess={changeOtpStatus}
                onError={handleOtpError}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ForgotPasswordPage;
