import {Grid, Paper} from "@mui/material";
import * as React from "react";
import BorrowerListingComponent from "../../component/borrower/BorrowerListingComponent";
import FileUploadDialog, {AddSingleBorrowerDialog,} from "../../component/borrower/AddBorrowerButtonComponent";
import TitleBarComponent from "../../component/common/TitleBarComponent";


const OrgBorrowerListPage = () => {

  return (
    <React.Fragment>
      <TitleBarComponent title={'Referred Organisation Overview'} rightComponent={
        <Grid container spacing={2}>
          <Grid item>
            <FileUploadDialog />
          </Grid>
          <Grid item>
            <AddSingleBorrowerDialog />
          </Grid>
        </Grid>}/>
       <Paper elevation={1} sx={{ paddingTop: 2, marginTop: 1}}>
        <BorrowerListingComponent />
        </Paper>
    </React.Fragment>
  );
};

export default OrgBorrowerListPage;
