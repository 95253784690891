import {
  Button, Grid,
  IconButton,
  ListItem, TextField,
  Tooltip,
  Switch, Accordion, AccordionSummary, AccordionDetails, Typography, Link, CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SnackBarActions } from "../../../store/SnackBarSlice";
import { GstinOtpRequestStatus, GstinOtpRequestStatusByValue, PatchRequestActionStatus, SnackBarMessageType } from "../../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import GBSGstinEditModelComponent from "./GBSGstinEditModelComponent";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import SystemDefault from "../../../util/SystemDefault";
import TextDetailsComponent from "../../common/TextDetailsComponent";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GstinApi from "../../../http/v1_apis/GstinApi";
import V2BusinessScoreRequestGstinApi from "../../../http/v2_apis/BusinessScoreRequestGstinApi";
import { stepperActions } from "../../../store/StepperSlice";


const GBSGstinItemComponent = (props) => {
  const accordianId = useSelector((state) => state.stepper.steps[0].accordianId);
  const [otp, setOtp] = useState("");
  const [isOtpTouch, setIsOtpTouch] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const isOtpInvalid = !isOtpValid && isOtpTouch;
  const [otpErrMsg, setOtpErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isResendOtpClickable, setIsResendOtpClickable] = useState("none");
  const [resendOtpColor, setResendOtpColor] = useState("grey");
  const [resendOtpLoader, setResendOtpLoader] = useState(false);
  const [counter, setCounter] = React.useState(0);

  // const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(props.item.otpStatus === GstinOtpRequestStatus.TRIGGERED){
      disableResendOtpHandler();
    }
  },[props]);

  const useStyles = makeStyles({
    sanctionLimitClass: {
      minWidth: 200, marginTop: 10, marginLeft: 5, backgroundColor: "#FFFFFF"
    },
    inputFieldClass: {
      // minWidth: 230
      width: 250,
      // maxWidth: 270
    },
    accordianClass: {
      border: '1px solid #f7f7f7',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    },
    accordianSummaryClass: {
      backgroundColor: "rgba(0,0,0,0.02)",
      borderBottom: '1px solid #cdcdcd',
      color: 'rgba(0,0,0,0.5)',
      // textDecoration: props.item.active ? "none":"line-through" 
    }
  });

  const classes = useStyles()

  const accordianViewChange = () => {
    accordianId === props.item.id ?
      dispatch(stepperActions.changeAccordianId("")) :
      dispatch(stepperActions.changeAccordianId({ value: props.item.id }))
  }

  const verifyOtpBtnHandler = (event) => {
    event.preventDefault();
    let isVerifyOtpFormValid = true;
    setIsOtpTouch(true);
    if (!isOtpValid) {
      isVerifyOtpFormValid = false;
      return;
    }
    setLoading(true);
    if (isVerifyOtpFormValid) {
      V2BusinessScoreRequestGstinApi.verifyGstin({
        bsrId: props.bsrId,
        gstin: props.item.gstin,
        otp: otp,
      })
        .then((response) => {
          props.item.otpVerified = true;
          props.item.otpStatus = response.otpStatus;
          props.updateSingleGstinDetails(props.item.id, props.item);
          setIsOtpValid(true);
          setOtpErrMsg("");
          setLoading(false);
        })
        .catch((reject) => {
          if (reject["errorCode"] === 900) {
            dispatch(
              SnackBarActions.openSnackBar({
                message:
                  reject["errorData"]["error-code"] + " : " + reject["errorData"]["message"],
                messageType: SnackBarMessageType.ERROR,
              })
            );
          } else {
            dispatch(
              SnackBarActions.openSnackBar({
                message: reject["errorMessage"],
                messageType: SnackBarMessageType.ERROR,
              })
            );
          }
          setIsOtpValid(false);
          setOtpErrMsg("Invalid OTP");
          setLoading(false);
        });
    }
    
  };

  const otpInputChangeHandler = (event) => {
    setIsOtpTouch(true);
    setOtp(event.target.value);
    if (event.target.value === "") {
      setIsOtpValid(false);
      setOtpErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE)
    } else {
      setIsOtpValid(true);
      setOtpErrMsg("");
    }
  };

  const gstinActiveStatusChangeHandler = (event) => {
    V2BusinessScoreRequestGstinApi.changeGstinActiveStatus({
      bsrId: props.bsrId,
      gstin: props.item.gstin,
      action: PatchRequestActionStatus.ACTIVE,
      value: (event.target.checked ? true : false)
    }).then((response) => {
      props.gstinActiveStatusChangeHandler(props.item.id, response.active);
    }).catch((error) => {
      dispatch(
        SnackBarActions.openSnackBar({
            message: error.errorMessage,
          messageType: SnackBarMessageType.ERROR,
        })
      );
    })
  };

  const gstinRemoveHandler = (id) => {
    props.removeGstinHandler(id);
  };

  const disableResendOtpHandler = () => {
    setIsResendOtpClickable("none");
    setResendOtpColor("grey");
    setCounter(90);
    const interval = setInterval(() => {
      setCounter((count) => (count - 1));
    }, 1000);
    setTimeout(() => {
      setIsResendOtpClickable("");
      setResendOtpColor("");
      setCounter(0);
      clearInterval(interval);
    }, [90000]);
  };

  const resendOtpHandler = () => {
    setResendOtpLoader(true);
    GstinApi.generateOtp({
      gstin: props.item.gstin,
      username: props.item.gstinUsername
    }).then(()=> {
      disableResendOtpHandler();
      setResendOtpLoader(false);
    }).catch((error)=> {
      dispatch(
        SnackBarActions.openSnackBar({
          message: error.errorMessage,
          messageType: SnackBarMessageType.ERROR
        })
      )
      setResendOtpLoader(false);
    })

  }

  const requestResendOtpComponent = () => {
    if (resendOtpLoader) {
      return <CircularProgress size={25} />;
    } else {
      return (
        <Tooltip title="Resend OTP">
          <Link
          variant="body2"
          to={"#"}
          // disabled={true}
          style={{
            textDecoration: "none",
            pointerEvents: isResendOtpClickable,
            color: resendOtpColor,
            size: 10
          }}
          tabIndex={0}
          onClick={resendOtpHandler}
        >
          {counter === 0 ? "Resend" : `${counter}s`}
        </Link>
        </Tooltip>
        
      );
    }
  };

  return (
    <React.Fragment>
      {props.item.editMode && (
        <GBSGstinEditModelComponent
          changeEditModeHandler={props.updateGstinEditMode}
          bsrId={props.bsrId}
          item={props.item}
          pan={props.pan}
          gstinRemoveHandler={gstinRemoveHandler}
          updateSingleGstinDetails={props.updateSingleGstinDetails}
          resetResendOtpTime={() => setCounter(90)}
        />
      )}
      {!props.item.createMode && (
        <Accordion className={classes.accordianClass} expanded={accordianId === props.item.id}>
          <AccordionSummary
            className={classes.accordianSummaryClass}
            expandIcon={<IconButton onClick={accordianViewChange}><ExpandMoreIcon /></IconButton>}>
            {accordianId === props.item.id ? (
              <Grid container>
                <Grid item xs={6} md={8} >
                  <Typography sx={{ paddingLeft: 2 }}>GSTIN Details</Typography>
                </Grid>
                <Grid item xs={6} md={4} textAlign="end" paddingRight={2}>
                  {/* <Typography>{props.item.active ? "Enable": "Disabled"} <Switch checked={props.item.active} onChange={activeStatusChange} size="small" /></Typography> */}
                  <Typography>Active <Switch checked={props.item.active} onChange={gstinActiveStatusChangeHandler} size="small" /></Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container justifyContent="space-between" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item>
                  <Tooltip title={props.item.gstin} arrow>
                    <Typography sx={{ maxWidth: 300 }} noWrap><b>GSTIN : </b> {props.item.gstin}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={props.item.gstinUsername} arrow>
                    <Typography sx={{ maxWidth: 200 }} noWrap><b>Username : </b> {props.item.gstinUsername}</Typography>
                  </Tooltip>
                </Grid>
                <Grid item textAlign="end">
                  {/* <Typography>{props.item.active ? "Enable": "Disabled"} <Switch checked={props.item.active} onChange={activeStatusChange} size="small" /></Typography> */}
                  <Typography>Active <Switch checked={props.item.active} onChange={gstinActiveStatusChangeHandler} size="small" /></Typography>
                </Grid>
              </Grid>
            )}

          </AccordionSummary>
          <AccordionDetails sx={{ margin: 2, marginTop: 4 }}>
            <ListItem sx={{ border: "1px solid rgba(0,0,0,0.06)", borderRadius: 2, padding: 4, maxWidth: '53vw', backgroundColor: "rgba(0,0,0,0.01)" }}>
              <Grid container spacing={4}>
                {/* <Grid item container spacing={{ xs: 4, md: 3 }} justifyContent="space-between" columns={{ xs: 4, sm: 8, md: 12 }}> */}
                <Grid item container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <TextDetailsComponent
                      caption="GSTIN"
                      value={props.item.gstin}
                    />
                  </Grid>
                  <Grid item>
                    <TextDetailsComponent
                      caption="Username"
                      value={props.item.gstinUsername}
                    />
                  </Grid>
                  <Grid item>
                    <TextDetailsComponent
                      caption="OTP Status"
                      value={GstinOtpRequestStatusByValue(props.item.otpStatus).status}
                      color={GstinOtpRequestStatusByValue(props.item.otpStatus).color}
                    />
                  </Grid>
                  {props.item.otpStatus === GstinOtpRequestStatus.TRIGGERED && (

                    <Grid item container spacing={2} mt={1} alignItems="center">
                      <Grid item>
                        <TextField
                          id="otp"
                          required
                          label="Enter OTP"
                          value={otp}
                          size="small"
                          error={isOtpInvalid}
                          helperText={otpErrMsg}
                          className={classes.inputFieldClass}
                          onChange={otpInputChangeHandler}
                          InputProps={{ endAdornment: requestResendOtpComponent() }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          size="small"
                          variant="outlined"
                          disabled={loading}
                          onClick={verifyOtpBtnHandler}
                        >
                          Verify OTP
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item container pt={4} justifyContent="end">
                  <Grid item>
                    <Button disabled={!props.item.active} onClick={() => props.updateGstinEditMode(props.item.id, true)} startIcon={<EditOutlinedIcon />}>
                      Edit</Button>
                  </Grid>
                </Grid>

              </Grid>
            </ListItem>
          </AccordionDetails >
        </Accordion >
      )}

    </React.Fragment>
  );
};

export default GBSGstinItemComponent;
