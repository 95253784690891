import * as React from "react";
import SystemDefault from "../../util/SystemDefault";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";
import { useDispatch } from "react-redux";

import RefererReferredApi from "../../http/v1_apis/RefererReferredOrgApi";
import ReportsListingComponent from "../../component/report/ReportListingComponent";
import { ListingPageHeaderComponent } from "../../component/report/ListingPageHeaderComponent";
import { CircularProgress } from "@mui/material";

const ReportListingPage = () => {
  const dispatch = useDispatch();
  const [borrowerDetails, setBorrowerDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);

    RefererReferredApi.search({
      searchMode: SystemDefault.SEARCH_MODE,
      page: 0,
      records: 0,
      filter: { referer: false, referred: true },
      select: { organisations: true },
      sort: { createdAtDesc: true },
    })
      .then((response) => {
        setBorrowerDetails(
          Object.entries(response.objects.organisations).map((org) => org[1])
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });
  }, [dispatch]);
  const indicatorSize = 80;

  return (
    <React.Fragment>
      {loading && (
        <CircularProgress
          size={indicatorSize}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: `${-indicatorSize / 2}px`,
            marginLeft: `${-indicatorSize / 2}px`,
          }}
        />
      )}
      {!loading && (
        <div>
          <ListingPageHeaderComponent borrowerDetails={borrowerDetails} />
          <ReportsListingComponent borrowerDetails={borrowerDetails} />
        </div>
      )}
    </React.Fragment>
  );
};

export default ReportListingPage;
