import * as React from "react";
import { Paper } from "@mui/material";
import PlatformAdminDashboardCommonComponent from "./PlatformAdminDashboardCommonComponent";
import PlatformAdminDashboardGbsComponent from "./PlatformAdminDashboardGbsComponent";
import PlatformAdminDashboardFilterComponent from "./PlatformAdminDashboardFilterComponent";
import PlatformAdminDashboardShuriComponent from "./PlatformAdminDashboardShuriComponent";
import { useEffect } from "react";
import ApiClientsApi from "../../http/v1_apis/ApiClients";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { useDispatch } from "react-redux";
import { SnackBarMessageType } from "../../util/Constant";

const PlatformAdminDashboardComponent = (props) => {
  const [duration, setDuration] = React.useState("TOTAL");
  const [apiClientId, setApiClientId] = React.useState(null);
  const [apiClientList, setApiClientList] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // call to get apiClients from db
    ApiClientsApi.search({ searchMode: "3", page: 1, records: 10 })
      .then((res) => {
        setApiClientList(res.results.filter(client => {
          return client.name !== 'platform'
        } ));
      })
      .catch((err) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Couldn't fetch api clients from backend!",
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });
  }, [dispatch]);

  const updateDuration = (value) => {
    if (duration !== value) {
      setDuration(value);
    }
  };

  const updateApiClient = (value) => {
    if (apiClientId !== value) {
      setApiClientId(value);
    }
  };

  return (
    <Paper elevation={3}>
      <PlatformAdminDashboardFilterComponent
        duration={duration}
        apiClientId={apiClientId}
        apiClientList={apiClientList}
        updateDuration={updateDuration}
        updateApiClient={updateApiClient}
      />
      <PlatformAdminDashboardCommonComponent
        duration={duration}
        apiClientId={apiClientId}
        apiClientList={apiClientList}
      />
      <PlatformAdminDashboardGbsComponent
        duration={duration}
        apiClientId={apiClientId}
        apiClientList={apiClientList}
      />
      <PlatformAdminDashboardShuriComponent
        duration={duration}
        apiClientId={apiClientId}
        apiClientList={apiClientList}
      />
    </Paper>
  );
};
export default PlatformAdminDashboardComponent;
