import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GBSGstinComponent from "../../component/gbs/gstin/GBSGstinComponent";
import GBSBankStatementComponent from "../../component/gbs/bank_statement/GBSBankStatementComponent";
import GBSRightSideBarComponent from "../../component/gbs/GBSRightSideBarComponent";
import HelpIcon from "@mui/icons-material/Help";
import { makeStyles } from "@mui/styles";
import Fab from "@mui/material/Fab";
import GBSReviewComponent from "../../component/gbs/GBSReviewComponent";
import BusinessUnitApi from "../../http/v1_apis/BusinessUnitApi";
import BusinessBankApi from "../../http/v1_apis/BusinessBankApi";
import { useDispatch, useSelector } from "react-redux";
import { stepperActions } from "../../store/StepperSlice";
import { SnackBarActions } from "../../store/SnackBarSlice";
import {
  GstinOtpRequestStatus,
  SnackBarMessageType,
} from "../../util/Constant";
import RoutePath from "../../util/RoutePath";
import { useHistory } from "react-router-dom";
import V2BusinessScoreRequestApi from "../../http/v2_apis/BusinessScoreRequestApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuidv4 } from "uuid";

const gbsSteps = ["GSTINs", "Bank A/Cs", "Review"];

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const GbsCreatePage = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    banks: {},
    bankDetailsVerified: false,
  });
  const [gstinDetails, setGstinDetails] = useState({
    gstins: {},
    gstinDetailsVerified: false,
  });
  const [gstinMasterDataMap, setGstinMasterData] = useState({});
  const [bankMasterDataMap, setBankMasterData] = useState({});

  // const [gstinComponentVisible, setGstinComponentVisible] = useState(false);
  // const [bankDetailsComponentVisible, setBankDetailsComponentVisible] = useState(false);
  const [gbsComponentVisible, setGbsComponentVisible] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const gstinRef = React.useRef(null);
  const bankDetailsRef = React.useRef(null);
  const steps = useSelector((state) => state.stepper);
  const bankNames = useSelector((state) => state.bankName.itemList);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    gbsSteps.forEach((label, index) => {
      dispatch(stepperActions.addStep({ step: index, label: label }));
    });
    dispatch(stepperActions.setActiveStep({ value: 0 }));
    dispatch(
      stepperActions.setStepSkippableOrNot({ step: 2, skippable: false })
    );
    setLoading(true);
    setGbsComponentVisible(false);
    BusinessUnitApi.fetch()
      .then((response) => {
        setGstinMasterData({ ...response });
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });

    BusinessBankApi.getAllBusinessBankUnitsData()
      .then((response) => {
        let tempMasterData = {};
        Object.values(response).forEach((bank) => {
          let id = bank.id;
          tempMasterData = {
            ...tempMasterData,
            [id]: {
              ...bank,
            },
          };
          bankNames.forEach((item) => {
            if (item.id === tempMasterData[id].bankId) {
              tempMasterData[id].bankName = item.label;
            }
          });
        });
        setBankMasterData(tempMasterData);
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });

    V2BusinessScoreRequestApi.getBsrDetails({
      bsrId: params.bsrId,
    })
      .then((response) => {
        if (response.property.gstins) {
          let gstinsMap = {};
          response.property.gstins.forEach((gstin) => {
            let id = `${gstin.bsrId}_${gstin.businessGstin.id}`;
            gstinsMap = {
              ...gstinsMap,
              [id]: {
                id: id,
                active: gstin.active,
                createMode: false,
                editMode: false,
                businessGstinId: gstin.businessGstin.id,
                gstin: gstin.businessGstin.gstin,
                gstinUsername: gstin.businessGstin.gstnUsername,
                gstinIsValid: true,
                gstinUsernameIsValid: true,
                otpVerified:
                  gstin.otpStatus === GstinOtpRequestStatus.VALIDATED
                    ? true
                    : false,
                otpStatus: gstin.otpStatus,
                // errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE,
              },
            };
          });
          addUpdateGstinDetails(gstinsMap);
        }
        if (response.property.banks) {
          let banksMap = {};
          response.property.banks.forEach((bank) => {
            let id = `${bank.bsrId}_${bank.bank.id}`;
            let tempMonthlyDrawingLimits = {};
            let tempBankStatements = {};

            bank.drawingPowerLimits["limits"].forEach((item) => {
              let monthlyDrawingPowerLimitId = uuidv4();
              tempMonthlyDrawingLimits = {
                [monthlyDrawingPowerLimitId]: {
                  id: monthlyDrawingPowerLimitId,
                  from: item.from,
                  fromErrMsg: "",
                  fromIsTouch: true,
                  fromIsValid: true,
                  limit: item.limit,
                  limitErrMsg: "",
                  limitIsTouch: true,
                  limitIsValid: true,
                },
                ...tempMonthlyDrawingLimits,
              };
            });

            bank.property.bankStatements.forEach((bankStatement) => {
              let id = `${bank.bsrId}_${bank.bank.id}_${bankStatement.bankStatementDetails.attachmentId}`;
              tempBankStatements = {
                [id]: {
                  id: id,
                  active: bankStatement.bankStatementDetails.active,
                  file: "",
                  fileName:
                    bankStatement.attachmentDetails[0].name.substring(38),
                  fileId: bankStatement.bankStatementDetails.attachmentId,
                  password: "",
                  isFileVerified: true,
                },
                ...tempBankStatements,
              };
            });

            banksMap = {
              ...banksMap,
              [id]: {
                id: id,
                active: bank.active,
                createMode: false,
                editMode: false,
                businessBankId: bank.bank.id,
                monthlyDrawingPowerLimit: tempMonthlyDrawingLimits,
                sanctionLimit: bank.sanctionLimit,
                bankId: bank.bank.bankId,
                accountNumber: bank.bank.number,
                accountType: bank.bank.type,
                bankAccountHolderName: bank.bank.name,
                bankStatements: tempBankStatements,
                accountNumberIsValid: true,
                accountTypeIsValid: true,
                bankNameIsValid: true,
              },
            };
            bankNames.forEach((item) => {
              if (item.id === banksMap[id].bankId) {
                banksMap[id].bankName = item.label;
              }
            });
          });
          addUpdateBankDetails(banksMap);
        }
        setGbsComponentVisible(true);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setLoading(false);
      });
  }, [bankNames, dispatch, params.bsrId]);

  const addUpdateBankDetails = (value) => {
    setBankDetails((prevBankDetails) => ({
      banks: { ...value },
      bankDetailsVerified: prevBankDetails.bankDetailsVerified,
    }));
  };

  const removeBankDetails = () => {
    setBankDetails({
      banks: {},
      bankDetailsVerifed: false,
    });
  };

  const updateBankDetailsVerifiedStatus = (value) => {
    setBankDetails((prevBankDetails) => ({
      banks: { ...prevBankDetails.banks },
      bankDetailsVerified: value,
    }));
  };

  const addUpdateGstinDetails = (value) => {
    setGstinDetails((prevGstinDetails) => ({
      gstins: { ...value },
      gstinDetailsVerified: prevGstinDetails.gstinDetailsVerified,
    }));
  };

  const updateGstinDetailsVerifiedStatus = (value) => {
    setGstinDetails((prevsGstinDetails) => ({
      gstins: { ...prevsGstinDetails.gstins },
      gstinDetailsVerified: value,
    }));
  };

  const removeGstinDetails = () => {
    setGstinDetails({
      gstins: {},
      gstinDetailsVerified: false,
    });
  };

  const isStepOptional = (step) => {
    // return (skipSteps.has(step));
    return steps.steps[step].skippable;
  };

  const isStepSkipped = (step) => {
    // return skipped.has(step);
    return steps.steps[step].skipped;
  };

  const handleNext = (event) => {
    if (isStepSkipped(steps.activeStep)) {
      dispatch(
        stepperActions.setStepSkippedOrNot({
          step: steps.activeStep,
          skipped: false,
        })
      );
    }

    switch (steps.activeStep) {
      case 0:
        gstinRef.current(event);
        break;
      case 1:
        bankDetailsRef.current(event);
        break;
      case 2:
        setSubmitLoading(true);
        V2BusinessScoreRequestApi.submitBsr({
          bsrId: params.bsrId,
        })
          .then((response) => {
            setSubmitLoading(false);
            history.push(RoutePath.APP_ORG_DASHBOARD);
          })
          .catch((error) => {
            dispatch(
              SnackBarActions.openSnackBar({
                message: error.errorMessage,
                messageType: SnackBarMessageType.ERROR,
              })
            );
            setSubmitLoading(false);
          });
        break;
      default:
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Invalid Step",
            messageType: SnackBarMessageType.ERROR,
          })
        );
        break;
    }
  };

  const handleBack = () => {
    dispatch(stepperActions.setActiveStep({ value: steps.activeStep - 1 }));
  };

  const handleSkip = () => {
    if (!isStepOptional(steps.activeStep)) {
      dispatch(
        SnackBarActions.openSnackBar({
          message: "You can't skip a step that isn't optional.",
          messageType: SnackBarMessageType.ERROR,
        })
      );
      return;
    }
    let isSkipValid = true;
    switch (steps.activeStep) {
      case 0:
        Object.values(gstinDetails.gstins).forEach((gstin) => {
          if (gstin.active) {
            isSkipValid = false;
          }
        });
        if (!isSkipValid) {
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Please inactive all the gstins first",
              messageType: SnackBarMessageType.ERROR,
            })
          );
        }
        break;
      case 1:
        Object.values(bankDetails.banks).forEach((bank) => {
          if (bank.active) {
            isSkipValid = false;
          }
        });
        if (!isSkipValid) {
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Please inactive all the banks first",
              messageType: SnackBarMessageType.ERROR,
            })
          );
        }
        break;
      case 2:
        setSubmitLoading(true);
        V2BusinessScoreRequestApi.submitBsr({
          bsrId: params.bsrId,
        })
          .then((response) => {
            setSubmitLoading(false);
            history.push(RoutePath.APP_ORG_DASHBOARD);
          })
          .catch((error) => {
            dispatch(
              SnackBarActions.openSnackBar({
                message: error.errorMessage,
                messageType: SnackBarMessageType.ERROR,
              })
            );
            setSubmitLoading(false);
          });
        break;

      default:
        break;
    }
    if (isSkipValid) {
      dispatch(
        stepperActions.setStepSkippedOrNot({
          step: steps.activeStep,
          skipped: true,
        })
      );
      dispatch(
        stepperActions.setStepCompletedOrNot({
          step: steps.activeStep,
          completed: false,
        })
      );
      dispatch(stepperActions.setActiveStep({ value: steps.activeStep + 1 }));
    }
  };

  const openDrawerHandler = () => {
    setOpenDrawer(true);
  };

  const closeDrawerHandler = () => {
    setOpenDrawer(false);
  };

  return (
    <React.Fragment>
      <Stepper
        activeStep={steps.activeStep}
        nonLinear
        sx={{ margin: 5, marginTop: 1 }}
      >
        {Object.values(steps.steps).map((item, index) => {
          return (
            <Step key={item.label} completed={item.completed}>
              <StepLabel color="inherit">{item.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          // style={{ minHeight: "100vh" }}
        >
          {loading && <CircularProgress size={25} />}

          {steps.activeStep === 0 && (
            <Grid item xs={9}>
              {!loading && gbsComponentVisible && (
                <GBSGstinComponent
                  gbsComponentVisible={gbsComponentVisible}
                  gstinRef={gstinRef}
                  gstinDetails={gstinDetails}
                  gstinMasterDataMap={gstinMasterDataMap}
                  addUpdateGstinDetails={addUpdateGstinDetails}
                  removeGstinDetails={removeGstinDetails}
                  updateGstinDetailsVerifiedStatus={
                    updateGstinDetailsVerifiedStatus
                  }
                  setGstinMasterData={setGstinMasterData}
                />
              )}
            </Grid>
          )}
          {steps.activeStep === 1 && (
            <Grid item xs={9}>
              {gbsComponentVisible && (
                <GBSBankStatementComponent
                  gbsComponentVisible={gbsComponentVisible}
                  bankDetailsRef={bankDetailsRef}
                  bankDetails={bankDetails}
                  bankMasterDataMap={bankMasterDataMap}
                  addUpdateBankDetails={addUpdateBankDetails}
                  removeBankDetails={removeBankDetails}
                  updateBankDetailsVerifiedStatus={
                    updateBankDetailsVerifiedStatus
                  }
                  setBankMasterData={setBankMasterData}
                />
              )}
            </Grid>
          )}
          {steps.activeStep === 2 && (
            <Grid item xs={9}>
              {gbsComponentVisible && (
                <GBSReviewComponent
                  gstinDetails={gstinDetails}
                  bankDetails={bankDetails}
                />
              )}
            </Grid>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={steps.activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {gbsComponentVisible && isStepOptional(steps.activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button onClick={handleNext}>
              {!submitLoading &&
                (steps.activeStep === gbsSteps.length - 1 ? "Submit" : "Next")}
              {submitLoading && <CircularProgress sx={{ mx: 2 }} size={25} />}
            </Button>
          </Box>
        </Grid>
      </React.Fragment>

      <Fab
        color="default"
        aria-label="help"
        className={classes.fab}
        onClick={openDrawerHandler}
      >
        <HelpIcon fontSize="large" />
      </Fab>
      {openDrawer && (
        <GBSRightSideBarComponent closeDrawerHandler={closeDrawerHandler} />
      )}
    </React.Fragment>
  );
};

export default GbsCreatePage;
