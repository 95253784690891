import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";
import TheodoreApiRESTUtil from "../../util/TheodoreRESTUtil";

export default class OrgApi {
  static path = "organisations";

  static get = ({ id }) => {
    let path = OrgApi.path + "/" + id;
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static getAll = () => {
    let path = OrgApi.path;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };


  static isExistTaxid = ({ taxid }) => {
    let path =  "orgexistence/India/PAN/"+taxid;
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };
}
