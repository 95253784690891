import {
    Tooltip,
    Typography, Box,
    Grid,
    Card, CardContent
} from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';


const GBSViewDetailGSTINComponent = (props) => {
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    return (
        <React.Fragment>
            <Grid container spacing={2} direction="row" >
                {Object.values(props.gstins).map((gstin) => {
                    return (<Grid item xs={12} sm={6} md={3} key={gstin.id}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={1.5}>
                                    <Grid item container spacing={2}>
                                        <Grid item sx={{ width: 150 }} color="text.secondary">
                                            GSTIN :
                                        </Grid>
                                        <Grid item>
                                        <Tooltip title={<div>{gstin.gstin}</div>} arrow>
                                            <Typography>{gstin.gstin}</Typography>
                                        </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2}>
                                        <Grid item sx={{ width: 150 }} color="text.secondary">
                                            Username :
                                        </Grid>
                                        <Grid item>
                                            {gstin.gstinUsername}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )})}
            </Grid>

        </React.Fragment>
    );
};

export default GBSViewDetailGSTINComponent;
