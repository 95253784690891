import { createSlice } from "@reduxjs/toolkit";
import { SnackBarMessageType } from "../util/Constant";

const SnackBarSlice = createSlice({
  name: "snackBar",
  initialState: { messageType: SnackBarMessageType.WARNING },
  reducers: {
    openSnackBar(state, action) {
      state.SnackbarOpen = true;
      state.SnackbarMessage = action.payload.message;
      state.messageType = action.payload.messageType;
    },
    clearSnackBar(state) {
      state.SnackbarOpen = false;
      state.SnackbarOpen = false;
      state.SnackbarOpen = false;
    },
  },
});

export const SnackBarActions = SnackBarSlice.actions;
export default SnackBarSlice.reducer;
