import { Grid, Typography } from "@mui/material";
import React from "react";

const HowGBSHelpComponent = () => {
  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={6} md={6}>
          <img src="/assets/images/customer2.png" alt="mobile" />
          <Typography variant="h5">Attract new customers</Typography>
          <Typography variant="h6">
            A higher score indicates that your business is stable and growing. A
            great signal for customers to prefer you over your competitors.
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6}>
          <img src="/assets/images/supply-chain2.png" alt="mobile" />
          <Typography variant="h5">Better terms from Suppliers</Typography>
          <Typography variant="h6">
            The strength and stability of your business will also ensure that
            suppliers feel that they can grow with you – and hence offer your
            better services and better terms.
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={6} md={6}>
          <img src="/assets/images/customer2.png" alt="mobile" />
          <Typography variant="h5">Attract new customers</Typography>
          <Typography variant="h6">
            A higher score indicates that your business is stable and growing. A
            great signal for customers to prefer you over your competitors.
          </Typography>
        </Grid>
      </Grid>
      {/*  <Grid item justify="flex-end">*/}
      {/*    mahesh test*/}
      {/*  </Grid>*/}
      {/*  <Grid item>*/}
      {/*    <img src="/assets/images/supply-chain2.png" alt="mobile" />*/}
      {/*    <Typography variant="h5">Better terms from Suppliers</Typography>*/}
      {/*    <Typography variant="h6">*/}
      {/*      The strength and stability of your business will also ensure that*/}
      {/*      suppliers feel that they can grow with you – and hence offer your*/}
      {/*      better services and better terms.*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*  /!*</Grid>*!/*/}
      {/*  /!*</Grid>*!/*/}
      {/*  /!*<Grid item md={6}>*!/*/}
      {/*  /!*  <Grid container justifyContent="space-between">*!/*/}
      {/*  /!*    <Grid item>*!/*/}
      {/*  /!*      <img src="/assets/images/graph2.png" alt="mobile" />*!/*/}
      {/*  /!*      <Typography variant="h5">*!/*/}
      {/*  /!*        Tips to improve business health*!/*/}
      {/*  /!*      </Typography>*!/*/}
      {/*  /!*      <Typography variant="h6">*!/*/}
      {/*  /!*        By checking your score regularly, you will know exactly what*!/*/}
      {/*  /!*        factors to focus on to keep making your business health better*!/*/}
      {/*  /!*        and better.*!/*/}
      {/*  /!*      </Typography>*!/*/}
      {/*  /!*    </Grid>*!/*/}
      {/*  /!*  </Grid>*!/*/}
      {/*  /!*</Grid>*!/*/}
      {/*</Grid>*/}
    </React.Fragment>
  );
};

export default HowGBSHelpComponent;
