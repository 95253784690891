import * as React from "react";
import { Chip, CircularProgress, Paper, Typography } from "@mui/material";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import BusinessApi from "../../http/v1_apis/BusinessApi";
import SystemDefault from "../../util/SystemDefault";
import PaginationStatusBarComponent from "../common/PaginationStatusBarComponent";
import ListingCardComponent from "../common/ListingCardComponent";
import DateUtil from "../../util/DateUtil";
import RoutePath from "../../util/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";

const BusinessListingComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const myCurrentOrg = useSelector((state) => state.currentOrg);
  const [organisations, setOrganisations] = React.useState({});
  const [businesses, setBusinesses] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [records, setRecords] = React.useState(SystemDefault.PAGE_SIZE[0]);
  const [pageCount, setPageCount] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);
    setOrganisations({});
    setBusinesses([]);
    BusinessApi.search({
      searchMode: SystemDefault.SEARCH_MODE,
      page: page,
      records: records,
      filter: {},
      select: { organisations: true },
      sort: { createdAtDesc: true },
    }).then((response) => {
      setOrganisations(response.objects.organisations);
      setBusinesses(response.results);
      setPageCount(Math.ceil(response.total / records));
      setLoading(false);
    });
  }, [page, records]);

  const onIsFiStatusChangeHandler = (id, value) => {
    let newBusniesses = businesses.map((business) => {
      if (business.id === id) {
        business.isFinancialInstitute = value;
      }
      return business;
    });
    setBusinesses([]);
    setBusinesses(newBusniesses);
  };

  const isFinancialInstituteCLickHandler = (id, isFinancialInstitute) => {
    BusinessApi.updateFinancialInstitute({
      id: id,
      isFinancialInstitute: !isFinancialInstitute,
    })
      .then((response) => {
        onIsFiStatusChangeHandler(id, !isFinancialInstitute);
        if (isFinancialInstitute) {
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Successfully unmarked as financial institute",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
        } else {
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Successfully marked as financial institute",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
        }
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Failed to complete Action",
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });
  };

  const markAsFIHandler = (business) => {
    isFinancialInstituteCLickHandler(business.id, false);
  };
  const unmarkAsFIHandler = (business) => {
    isFinancialInstituteCLickHandler(business.id, true);
  };

  const onPageChangeHandler = (value) => {
    setPage(value);
  };
  const onRecordsChangeHandler = (value) => {
    setRecords(value);
  };

  const redirectToOrgDetailPage = (business) => {
    history.push(
      RoutePath.routeAppPlatformBusinessDetail({
        platformId: myCurrentOrg?.organisation?.id,
        businessId: business.id,
      })
    );
  };

  const loadData = () => {
    return (
      <React.Fragment>
        {businesses?.map((business) => {
          return (
            <ListingCardComponent
              key={business.id}
              item={business}
              title={organisations[business.id].name}
              titleClickHandler={redirectToOrgDetailPage}
              subTitleHeader1="PAN"
              subTitleValue1={business.taxIdentifier}
              subTitleHeader2="Created at"
              subTitleValue2={DateUtil.convertToDateFormat(business.createdAt)}
              column1={
                business.isFinancialInstitute && (
                  <Chip
                    // icon={<AccountBalanceIcon />}
                    label={<Typography noWrap>Financial Institute</Typography>}
                    color="success"
                    // variant="outlined"
                  />
                )
              }
              action1="View"
              actionHandler1={redirectToOrgDetailPage}
              action2={
                business.isFinancialInstitute && "Unmark as Financial Institute"
              }
              actionHandler2={
                business.isFinancialInstitute && unmarkAsFIHandler
              }
              action3={
                !business.isFinancialInstitute && "Mark As Financial Institute"
              }
              actionHandler3={!business.isFinancialInstitute && markAsFIHandler}
            />
          );
        })}
        <PaginationStatusBarComponent
          pageCount={pageCount}
          page={page}
          onPageChangeHandler={onPageChangeHandler}
          records={records}
          onRecordsChangeHandler={onRecordsChangeHandler}
        />
      </React.Fragment>
    );
  };

  return (
    <Paper elevation={3}>
      {loading && <CircularProgress size={24} />}
      {!loading && businesses?.length <= 0 && <NoDataFoundComponent />}
      {loadData()}
    </Paper>
  );
};

export default BusinessListingComponent;
