import {
  Button,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  Typography,
  List,
  ListItemSecondaryAction,
  CircularProgress,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  FormControl,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SnackBarActions } from "../../../store/SnackBarSlice";
import { BankAccountType, SnackBarMessageType } from "../../../util/Constant";
import SystemDefault from "../../../util/SystemDefault";
import { v4 as uuidv4 } from "uuid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GBSBankStatementMonthlyDrawingItemComponent from "./GBSBankStatementMonthlyDrawingItemComponenet";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { blockInvalidNumber } from "../../../util/Constant";
import { loaderActions } from "../../../store/LoaderSlice";
import V2BusinessScoreRequestBankApi from "../../../http/v2_apis/BusinessScoreRequestBankApi";
import { stepperActions } from "../../../store/StepperSlice";

const GBSBankDetailsEditModelComponent = (props) => {
  const [tempBankName, setTempBankName] = useState("");
  const [tempBankId, setTempBankId] = useState("");
  const [tempBankNameIsTouch, setTempBankNameIsTouch] = useState(false);
  const [tempBankNameIsValid, setTempBankNameIsValid] = useState(false);
  const tempBankNameInvalid = !tempBankNameIsValid && tempBankNameIsTouch;

  const [tempBankAccountNumber, setTempBankAccountNumber] = useState();
  const [tempBankAccountNumberIsTouch, setTempBankAccountNumberIsTouch] =
    useState(false);
  const [tempBankAccountNumberIsValid, setTempBankAccountNumberIsValid] =
    useState(false);
  const tempBankAccountNumberInvalid =
    !tempBankAccountNumberIsValid && tempBankAccountNumberIsTouch;
  const [tempBankAccountNumberErrMsg, setTempBankAccountNumberErrMsg] =
    useState("");

  const [tempBankAccountType, setTempBankAccountType] = useState();
  const [tempBankAccountTypeIsTouch, setTempBankAccountTypeIsTouch] =
    useState(false);
  const [tempBankAccountTypeIsValid, setTempBankAccountTypeIsValid] =
    useState(false);
  const tempBankAccountTypeInvalid =
    !tempBankAccountTypeIsValid && tempBankAccountTypeIsTouch;

  const [tempBankAccountHolderName, setTempBankAccountHolderName] =
    useState("");

  const [tempSanctionLimit, setTempSanctionLimit] = useState("");
  const [tempSanctionLimitIsTouch, setTempSanctionLimitIsTouch] =
    useState(false);
  const [tempSanctionLimitIsValid, setTempSanctionLimitIsValid] =
    useState(false);
  const tempSanctionLimitInvalid =
    !tempSanctionLimitIsValid && tempSanctionLimitIsTouch;
  const [tempSanctionLimitErrMsg, setTempSanctionLimitErrMsg] = useState("");

  const [tempMonthlyDrawingPowerLimit, setTempMonthlyDrawingPowerLimit] =
    useState({});

  const [sanctionLimitVisibility, setSanctionLimitVisibility] =
    useState("none");

  const loading = useSelector((state) => state.loader.value);
  const dispatch = useDispatch();
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setCreateMode(props.item.createMode);
    setEditMode(props.item.editMode);
    if (props.item.bankName !== "") {
      setTempBankName(props.item.bankName);
      setTempBankId(props.item.bankId);
      setTempBankNameIsTouch(true);
      setTempBankNameIsValid(true);
    }
    if (props.item.accountNumber !== "") {
      setTempBankAccountNumber(props.item.accountNumber);
      setTempBankAccountNumberIsTouch(true);
      setTempBankAccountNumberIsValid(true);
    }
    if (props.item.accountType in BankAccountType) {
      setTempBankAccountType(props.item.accountType);
      setTempBankAccountTypeIsTouch(true);
      setTempBankAccountTypeIsValid(true);
      if (
        props.item.accountType === BankAccountType.CC ||
        props.item.accountType === BankAccountType.OD
      ) {
        if (Object.values(props.item.monthlyDrawingPowerLimit).length === 0) {
          addNewMonthlyDrawingLimitClickHandler();
        }
        setSanctionLimitVisibility("block");
      } else {
        setSanctionLimitVisibility("none");
      }
    }
    if (props.item.sanctionLimit !== "") {
      setTempSanctionLimit(props.item.sanctionLimit);
      setTempSanctionLimitIsTouch(true);
      setTempSanctionLimitIsValid(true);
    }

    setTempMonthlyDrawingPowerLimit(props.item.monthlyDrawingPowerLimit);
    setTempBankAccountHolderName(props.item.bankAccountHolderName);
  }, [
    props.item.accountNumber,
    props.item.accountType,
    props.item.bankAccountHolderName,
    props.item.bankId,
    props.item.bankName,
    props.item.createMode,
    props.item.editMode,
    props.item.monthlyDrawingPowerLimit,
    props.item.sanctionLimit,
  ]);

  const useStyles = makeStyles({
    inputFieldClass: {
      // minWidth: 230
      width: 250,
      // maxWidth: 270
    },
  });

  const classes = useStyles();

  const addNewMonthlyDrawingLimitClickHandler = (event) => {
    setTempMonthlyDrawingPowerLimit((prevMonthlyDrawingPowerLimitMap) => {
      let id = uuidv4();
      return {
        ...prevMonthlyDrawingPowerLimitMap,
        [id]: {
          id,
          from: "",
          fromErrMsg: "",
          fromIsTouch: false,
          fromIsValid: false,
          limit: "",
          limitErrMsg: "",
          limitIsTouch: false,
          limitIsValid: false,
        },
      };
    });
  };

  const monthlyPowerLimitRemoveHandler = (id) => {
    setTempMonthlyDrawingPowerLimit((prevMonthlyDrawingPowerLimitMap) => {
      let monthlyDrawingPowerLimit = { ...prevMonthlyDrawingPowerLimitMap };
      delete monthlyDrawingPowerLimit[id];
      return { ...monthlyDrawingPowerLimit };
    });
  };

  const monthlyDrawingPowerLimitFromInputHandler = (id, value) => {
    setTempMonthlyDrawingPowerLimit((prevMonthlyDrawingPowerLimitMap) => {
      let monthlyDrawingPowerLimit = { ...prevMonthlyDrawingPowerLimitMap };
      if (value === "") {
        monthlyDrawingPowerLimit[id].fromIsValid = false;
        monthlyDrawingPowerLimit[id].fromErrMsg =
          SystemDefault.DEFAULT_ERROR_MESSAGE;
      } else if (value.length !== 6) {
        monthlyDrawingPowerLimit[id].fromIsValid = false;
        monthlyDrawingPowerLimit[id].fromErrMsg = "OTP length should be 6";
      } else {
        monthlyDrawingPowerLimit[id].fromIsValid = true;
        monthlyDrawingPowerLimit[id].fromErrMsg = "";
      }
      monthlyDrawingPowerLimit[id].fromIsTouch = true;
      monthlyDrawingPowerLimit[id].from = value;

      return { ...monthlyDrawingPowerLimit };
    });
  };

  const monthlyDrawingPowerLimitValueInputHandler = (id, value) => {
    setTempMonthlyDrawingPowerLimit((prevMonthlyDrawingPowerLimitMap) => {
      let monthlyDrawingPowerLimit = { ...prevMonthlyDrawingPowerLimitMap };
      if (value === "") {
        monthlyDrawingPowerLimit[id].limitIsValid = false;
        monthlyDrawingPowerLimit[id].limitErrMsg =
          SystemDefault.DEFAULT_ERROR_MESSAGE;
      } else {
        monthlyDrawingPowerLimit[id].limitIsValid = true;
        monthlyDrawingPowerLimit[id].limitErrMsg = "";
      }
      monthlyDrawingPowerLimit[id].limitIsTouch = true;
      monthlyDrawingPowerLimit[id].limit = value;
      return { ...monthlyDrawingPowerLimit };
    });
  };

  const bankNameInputChangeHandler = (event, newValue) => {
    setTempBankNameIsTouch(true);
    if (newValue === null) {
      setTempBankName("");
      setTempBankId("");
      setTempBankNameIsValid(false);
    } else {
      setTempBankName(newValue.label);
      setTempBankId(newValue.id);
      setTempBankNameIsValid(true);
    }
  };

  const accountNumberInputChangeHandler = (event) => {
    setTempBankAccountNumberIsTouch(true);
    setTempBankAccountNumber(event.target.value);

    if (event.target.value.length === 0) {
      setTempBankAccountNumberIsValid(false);
      setTempBankAccountNumberErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else {
      setTempBankAccountNumberIsValid(true);
      setTempBankAccountNumberErrMsg("");
    }
  };
  const bankAccountHolderNameInputChangeHandler = (event) => {
    setTempBankAccountHolderName(event.target.value);
  };

  const accountTypeInputChangeHandler = (event) => {
    setTempBankAccountTypeIsTouch(true);
    event.target.value === BankAccountType.CC ||
    event.target.value === BankAccountType.OD
      ? setSanctionLimitVisibility("block")
      : setSanctionLimitVisibility("none");
    setTempBankAccountType(event.target.value);
    setTempBankAccountTypeIsValid(true);
  };

  const sanctionLimitInputChangeHandler = (event) => {
    setTempSanctionLimitIsTouch(true);
    setTempSanctionLimit(event.target.value);

    if (event.target.value === "") {
      setTempSanctionLimitIsValid(false);
      setTempSanctionLimitErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else {
      setTempSanctionLimitErrMsg("");
      setTempSanctionLimitIsValid(true);
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let isFormValid = true;
    let monthlyPowerLimitsList = [];
    setTempBankNameIsTouch(true);
    setTempBankAccountNumberIsTouch(true);
    setTempBankAccountTypeIsTouch(true);
    if (
      !tempBankNameIsValid ||
      !tempBankAccountNumberIsValid ||
      !tempBankAccountTypeIsValid
    ) {
      isFormValid = false;
      return;
    }
    if (sanctionLimitVisibility === "block") {
      setTempSanctionLimitIsTouch(true);
      if (!tempSanctionLimitIsValid) {
        isFormValid = false;
        return;
      }
      if (Object.values(tempMonthlyDrawingPowerLimit).length > 0) {
        Object.values(tempMonthlyDrawingPowerLimit).forEach((item) => {
          monthlyPowerLimitsList.push({
            from: item.from,
            limit: item.limit,
          });
        });
      }
    }
    if (isFormValid) {
      dispatch(loaderActions.startLoader());
      if (createMode) {
        V2BusinessScoreRequestBankApi.addBank({
          bsrId: props.bsrId,
          bankId: tempBankId,
          accountType: tempBankAccountType,
          accountNumber: tempBankAccountNumber,
          bankAccountHolderName: tempBankAccountHolderName,
          sanctionLimit: tempSanctionLimit,
          monthlyDrawingPowerLimit: monthlyPowerLimitsList,
        })
          .then((response) => {
            props.setIsSanctionLimitDisabled(sanctionLimitVisibility);
            props.item.createMode = false;
            props.item.active = response.active;
            props.item.editMode = false;
            props.item.bankName = tempBankName;
            props.item.bankNameIsValid = true;
            props.item.bankId = tempBankId;
            props.item.accountNumber = tempBankAccountNumber;
            props.item.accountNumberIsValid = true;
            props.item.accountType = tempBankAccountType;
            props.item.accountTypeIsValid = true;
            props.item.bankAccountHolderName = tempBankAccountHolderName;
            props.item.sanctionLimit = tempSanctionLimit;
            props.item.monthlyDrawingPowerLimit = tempMonthlyDrawingPowerLimit;
            props.item.businessBankId = response.businessBankId;
            setCreateMode(false);
            props.updateSingleBankDetails(props.item.id, props.item);
            props.setMonthlyDrawingPowerLimit(tempMonthlyDrawingPowerLimit);
            handleClose(false);
            dispatch(
              stepperActions.changeAccordianId({ value: props.item.id })
            );

            dispatch(loaderActions.stopLoader());
          })
          .catch((error) => {
            dispatch(
              SnackBarActions.openSnackBar({
                message: error.errorMessage,
                messageType: SnackBarMessageType.ERROR,
              })
            );
            dispatch(loaderActions.stopLoader());
          });
      } else {
        V2BusinessScoreRequestBankApi.updateBank({
          bsrId: props.bsrId,
          bankId: tempBankId,
          accountType: tempBankAccountType,
          accountNumber: tempBankAccountNumber,
          bankAccName: tempBankAccountHolderName,
          sanctionLimit: tempSanctionLimit,
          monthlyDrawingPowerLimit: monthlyPowerLimitsList,
        })
          .then((response) => {
            props.item.createMode = false;
            props.item.editMode = false;
            props.item.active = response.active;
            props.item.bankAccountHolderName = tempBankAccountHolderName;
            props.item.sanctionLimit = tempSanctionLimit;
            props.item.monthlyDrawingPowerLimit = tempMonthlyDrawingPowerLimit;
            setCreateMode(false);
            props.updateSingleBankDetails(props.item.id, props.item);
            props.setMonthlyDrawingPowerLimit(tempMonthlyDrawingPowerLimit);
            handleClose(false);
            dispatch(
              stepperActions.changeAccordianId({ value: props.item.id })
            );

            dispatch(loaderActions.stopLoader());
          })
          .catch((error) => {
            dispatch(
              SnackBarActions.openSnackBar({
                message: error.errorMessage,
                messageType: SnackBarMessageType.ERROR,
              })
            );
            dispatch(loaderActions.stopLoader());
          });
      }
    }
  };

  const handleClose = (createModeValue) => {
    if (createModeValue) {
      props.bankDetailsRemoveHandler(props.item.id);
      setEditMode(false);
    } else {
      props.changeEditModeHandler(props.item.id, false);
    }
  };

  return (
    <Dialog
      open={editMode}
      onClose={() => handleClose(createMode)}
      fullWidth={true}
      maxWidth="md"
      // aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="edit-bank-details-title" onClose={handleClose}>
        Enter Bank Details
      </DialogTitle>
      <DialogContent style={{ padding: 30, alignItems: "center" }}>
        <Grid container spacing={4}>
          <Grid item container spacing={3}>
            {/* <Grid item container spacing={2}> */}
            <Grid item>
              <Autocomplete
                required
                labelId="bankName"
                id="bankId"
                options={props.bankNames}
                helperText={
                  tempBankNameInvalid && SystemDefault.DEFAULT_ERROR_MESSAGE
                }
                autoHighlight
                value={tempBankName}
                disabled={props.item.active}
                className={classes.inputFieldClass}
                onChange={bankNameInputChangeHandler}
                renderInput={(params) => (
                  <TextField
                    error={tempBankNameInvalid}
                    {...params}
                    label="Bank Name *"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <TextField
                id="account"
                required
                label="Account Number"
                type="number"
                onKeyDown={blockInvalidNumber}
                inputProps={{ min: 0 }}
                value={tempBankAccountNumber}
                disabled={props.item.active}
                error={tempBankAccountNumberInvalid}
                helperText={tempBankAccountNumberErrMsg}
                className={classes.inputFieldClass}
                onChange={accountNumberInputChangeHandler}
              />
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel
                  error={tempBankAccountTypeInvalid}
                  disabled={props.item.active}
                  id="account-type-label"
                >
                  Account Type *
                </InputLabel>
                <Select
                  required
                  labelId="account-type-label"
                  id="accountType"
                  label="Account Type"
                  placeholder="Account Type"
                  helperText={
                    tempBankAccountNumberInvalid &&
                    SystemDefault.DEFAULT_ERROR_MESSAGE
                  }
                  error={tempBankAccountTypeInvalid}
                  disabled={props.item.active}
                  value={tempBankAccountType}
                  className={classes.inputFieldClass}
                  onChange={accountTypeInputChangeHandler}
                >
                  <MenuItem value={BankAccountType.SAVINGS} key="savings">
                    Savings
                  </MenuItem>
                  <MenuItem value={BankAccountType.CURRENT} key="current">
                    Current
                  </MenuItem>
                  <MenuItem value={BankAccountType.CC} key="cash_credit">
                    Cash Credit (CC)
                  </MenuItem>
                  <MenuItem value={BankAccountType.OD} key="overdraft">
                    Overdraft (OD)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* </Grid>
            <Grid item container spacing={2}> */}
            <Grid item>
              <TextField
                id="bankAccountHolderName"
                label="Account Holder Name"
                value={tempBankAccountHolderName}
                className={classes.inputFieldClass}
                onChange={bankAccountHolderNameInputChangeHandler}
              />
            </Grid>
            <Grid item sx={{ display: sanctionLimitVisibility }}>
              <TextField
                id="sanctionLimit"
                label="Sanction Limit"
                type="number"
                error={tempSanctionLimitInvalid}
                onKeyDown={blockInvalidNumber}
                inputProps={{ min: 0 }}
                value={tempSanctionLimit}
                className={classes.inputFieldClass}
                onChange={sanctionLimitInputChangeHandler}
                helperText={tempSanctionLimitErrMsg}
              />
            </Grid>
            <Grid
              item
              sx={{ display: sanctionLimitVisibility, marginLeft: -1 }}
            >
              <Typography style={{ marginLeft: 15 }}>
                Monthly Drawing Power Limit
              </Typography>
              <List key="monthlyLimit">
                {Object.values(tempMonthlyDrawingPowerLimit).map((item) => {
                  return (
                    <GBSBankStatementMonthlyDrawingItemComponent
                      item={item}
                      // bankDetailId={props.item.id}
                      key={item.id}
                      monthlyPowerLimitRemoveHandler={
                        monthlyPowerLimitRemoveHandler
                      }
                      monthlyDrawingPowerLimitValueInputHandler={
                        monthlyDrawingPowerLimitValueInputHandler
                      }
                      monthlyDrawingPowerLimitFromInputHandler={
                        monthlyDrawingPowerLimitFromInputHandler
                      }
                    />
                  );
                })}

                <ListItem key="add_new_item">
                  <ListItemSecondaryAction>
                    <Tooltip title="Add New" arrow>
                      <IconButton
                        edge="end"
                        aria-label="comments"
                        onClick={addNewMonthlyDrawingLimitClickHandler}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          {/* </Grid> */}

          {/* <Grid item>
            <Tooltip title="Remove" arrow onClick={bankDetailsRemoveHandler} >
              <IconButton edge="end" aria-label="comments">
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(createMode)} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmitHandler}>
          {loading && <CircularProgress sx={{ mx: 2 }} size={25} />}
          {!loading && "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GBSBankDetailsEditModelComponent;
