import { Container, Paper, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import GBSReviewBankStatementComponent from "./bank_statement/GBSReviewBankStatementComponent";
import GBSReviewGstinComponent from "./gstin/GBSReviewGstinComponent";
import NoDataFoundComponent from "../common/NoDataFoundComponent";

const GBSReviewComponent = (props) => {
  const currentOrg = useSelector((state) => state.currentOrg);
  const steps = useSelector((state) => state.stepper);

  return (
    <Paper elevation={3}>
      <Container>
        {currentOrg?.organisation?.id !== undefined && (
          <Typography pt={5} pb={2} variant="h6">
            GSTIN Details <br />
          </Typography>
        )}
        {(!steps.steps[0].skipped && props.gstinDetails.gstinDetailsVerified) ? (
          <GBSReviewGstinComponent
            gstinDetails={props.gstinDetails.gstins}
          />
        ) : <NoDataFoundComponent />}
        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        {currentOrg?.organisation?.id !== undefined && (
          <Typography pb={2} variant="h6">
            Bank Details <br />
          </Typography>
        )}
        {(!steps.steps[1].skipped && props.bankDetails.bankDetailsVerified) ? (
          <GBSReviewBankStatementComponent
            bankDetails={props.bankDetails.banks}
          />
        ) : <NoDataFoundComponent />}
        <br />
      </Container>
    </Paper>
  );
}

export default GBSReviewComponent;