import axios from "axios";
import StatusCodes from "http-status-codes";
import AppUtil from "./AppUtil";
import { HttpMethodType } from "./HttpMethodType";
import { logoutUtil } from "./LogoutUtil";
import { refreshJwtToken } from "./AppUtil";

export default class TheodoreApiRESTUtil {
    static axiosInstance = axios.create({ baseURL: AppUtil.getTheodoreApiBaseUrl() });
    static callApi = ({
      path,
      params = {},
      httpMethodType,
      command,
    }) => {
      this.axiosInstance.interceptors.request.use(refreshJwtToken)
      return TheodoreApiRESTUtil.callScopedAPI({
        path,
        params,
        httpMethodType,
        command,
      });
    };
    static callScopedAPI = ({
      path,
      params = {},
      httpMethodType,
      command,
    }) =>
      new Promise((resolve, reject) => {
        let request = {};
        // attach command
        if (command) {
          request = command;
        }
        const requestHeaders = {};
        let jwtToken = AppUtil.getJwtToken();
        if (jwtToken !== undefined && jwtToken !== null){
          requestHeaders["Authorization"] = jwtToken;
        }
        // create post url
        const url = `/${path}`;
        // make request
        TheodoreApiRESTUtil.requestExecutor({
          url,
          request,
          headers: requestHeaders,
          httpMethodType,
          params,
        }).then((response) => {
          const { status, data } = response;
          switch (status) {
            case StatusCodes.OK:
              resolve(data);
              break;
            
            case StatusCodes.NOT_FOUND:
              if (data) {
                reject({
                  errorMessage: data.error.message,
                });
              }
              break;

            case StatusCodes.UNAUTHORIZED:
                logoutUtil()
            if (data) {
                reject({
                  errorMessage: data.error.message,
                });
              }
              break;
  
            case StatusCodes.SERVICE_UNAVAILABLE:
              if (data) {
                reject({
                  errorMessage: data.error.message,
                });
              }
              break;
  
            case StatusCodes.INTERNAL_SERVER_ERROR:
              if (data) {
                reject({
                  errorMessage: data.error.message,
                });
              }
              break;

            case StatusCodes.CONFLICT:
              if (data) {
                reject({
                  errorMessage: data.error.message,
                });
              }
              break;

            case StatusCodes.BAD_REQUEST:
              if (data) {
                reject({
                  errorMessage: data.error.message,
                });
              }
              break;

            case StatusCodes.FORBIDDEN:
              if (data) {
                reject({
                    errorMessage: data.error.message,
                });
              }
              break;
              
            default:
              reject("apiError.errorMessage");
              break;
          }
        });
      });
  
    static requestExecutor = ({
      url,
      request,
      headers,
      httpMethodType,
      params,
    }) => {
      switch (httpMethodType) {
        case HttpMethodType.GET:
          return TheodoreApiRESTUtil.axiosInstance.get(url, {
              headers,
              validateStatus: () => true,
              params,
            });
  
        case HttpMethodType.POST:
          return TheodoreApiRESTUtil.axiosInstance.post(url, request, {
            headers,
            validateStatus: () => true,
          });
        case HttpMethodType.PUT:
          return TheodoreApiRESTUtil.axiosInstance.put(url, request, {
            headers,
            validateStatus: () => true,
          });
        case HttpMethodType.DELETE:
          return TheodoreApiRESTUtil.axiosInstance.delete(url, {
            headers,
            validateStatus: () => true,
          });
        case HttpMethodType.PATCH:
          return TheodoreApiRESTUtil.axiosInstance.patch(url, request, {
            headers,
            validateStatus: () => true,
          });
        default:
          break;
      }
      return false;
    };
  }