import { Route, Switch, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentOrgActions } from "../store/CurrentOrgSlice";
import RoutePath from "../util/RoutePath";
import PlatformBusinessListingPage from "./platform/PlatformBusinessListingPage";
import PlatformBusinessDetailPage from "./platform/PlatformBusinessDetailPage";
import PlatformApiClientListingPage from "./platform/PlatformApiClientListingPage";
import V2BusinessScoreRequestBankApi from "../http/v2_apis/BusinessScoreRequestBankApi";
import { bankNameActions } from "../store/BankNameSlice";
import { industrySectorActions } from "../store/IndustrySectorSlice";
import PlatformAdminDashboardPage from "./platform/PlatformAdminDashboardPage";
import MeekoIndustrySectorApi from "../http/meeko/MeekoIndustrySectorApi";

const OrgRoute = () => {
  const parmas = useParams();
  const dispatch = useDispatch();
  const myOrgList = useSelector((state) => state.myOrg);
  const industrySectorList = useSelector(
    (state) => state.industrySector.itemList
  );

  useEffect(() => {
    V2BusinessScoreRequestBankApi.getAllBanksData()
      .then((response) => {
        const bankNames = response.map((bank) => {
          return {
            label: bank.name,
            id: bank.id,
          };
        });
        dispatch(bankNameActions.addUpdateBankName(bankNames));
      })
      .catch((reject) => {});
    if (industrySectorList.length == 0) {
      MeekoIndustrySectorApi.getIndustrySector()
        .then((response) => {
          dispatch(industrySectorActions.addUpdateIndustrySector(response));
          industrySectorList = response;
        })
        .catch((reject) => {});
    }
  }, [dispatch, industrySectorList.length]);

  useEffect(() => {
    if (myOrgList.length > 0) {
      myOrgList?.forEach((item) => {
        if (item.organisation.id === parmas.orgId) {
          dispatch(currentOrgActions.setCurrentOrg(item));
        }
      });
    }
  }, [myOrgList, dispatch, parmas.orgId]);

  return (
    <React.Fragment>
      <Switch>
        <Route path={RoutePath.APP_PLATFORM_BUSINESS_LIST} exact>
          <PlatformBusinessListingPage />
        </Route>
        <Route path={RoutePath.APP_PLATFORM_BUSINESS_DETAIL} exact>
          <PlatformBusinessDetailPage />
        </Route>
        <Route path={RoutePath.APP_PLATFORM_CLIENT_LIST} exact>
          <PlatformApiClientListingPage />
        </Route>
        <Route path={RoutePath.APP_PLATFORM_ADMIN_DASHBOARD} exact>
          <PlatformAdminDashboardPage />
        </Route>
        {/*<Route path="*">*/}
        {/*<NotFoundPage />*/}
        {/*</Route>*/}
      </Switch>
    </React.Fragment>
  );
};
export default OrgRoute;
