import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import RoutePath from "../../util/RoutePath";
import UserApi from "../../http/theodore/UserApi";
import Paper from "@mui/material/Paper";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SystemDefault from "../../util/SystemDefault";
import StringUtil from "../../util/StringUtil";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../store/LoaderSlice";
import DoneIcon from "@mui/icons-material/Done";
import PasswordResetApi from "../../http/theodore/PasswordResetApi";

function ForgotPasswordComponent(props) {
  const dispatch = useDispatch();

  const [emailMobile, setEmailMobile] = useState("");
  const [emailMobileHelpText, setEmailMobileHelpText] = useState("");
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [emailMobileIsValid, setEmailMobileIsValid] = useState(false);
  const [isEmailMobileError, setIsEmailMobileError] = useState(false);
  const [emailMobileTouch, setEmailMobileTouch] = useState(false);
  const [emailMobileIsUpdated, setEmailMobileIsUpdated] = useState(false);
  const [emailMobileIsInprogress, setEmailMobileIsInprogress] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordErrMsg, setPasswordErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [passwordTouch, setPasswordTouch] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const [confirmPasswordErrMsg, setConfirmPasswordErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [confirmPasswordTouch, setConfirmPasswordTouch] = useState(false);
  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const inputEmailMobileInvalid = !emailMobileIsValid && emailMobileTouch;
  const inputPasswordInvalid = !passwordIsValid && passwordTouch;
  const inputConfirmPasswordInvalid =
    !confirmPasswordIsValid && confirmPasswordTouch;
  const loading = useSelector((state) => state.loader.value);
  const isDisabled = loading;

  const emailMobileInputChangeHandler = (event) => {
    // setEmailMobileIsExist(true);
    setEmailMobileIsUpdated(true);
    setEmailMobile(event.target.value);
    setEmailMobileTouch(true);
    if (event.target.value.trim() === "") {
      setIsEmailMobileError(true);
      setEmailMobileHelpText("Email or Mobile is required");
    } else {
      const result = StringUtil.isValidEmailMobile(event.target.value);
      if (result.result === true) {
        setIsEmailMobileError(false);
        setEmailMobileIsValid(true);
        setEmailOrMobile(result.type);
        setEmailMobileHelpText("");
      } else {
        setIsEmailMobileError(true);
        setEmailMobileIsValid(false);
        setEmailOrMobile("");
        setEmailMobileHelpText(result.errMsg);
      }
    }
  };

  const emailMobileFocusOutEventHandler = () => {
    if (props.purpose === "forget_password") {
      if (emailMobileIsValid && emailMobileTouch && emailMobileIsUpdated) {
        setEmailMobileIsInprogress(true);
        UserApi.checkUserExistenceByEmailMobile({
          emailMobile: emailMobile,
        }).then((response) => {
          setEmailMobileIsUpdated(false);
          // setEmailMobileIsExist(response.data.userPresent);
          if (!response.data.userPresent) {
            setIsEmailMobileError(true);
            setEmailMobileHelpText("Email Mobile does not exist in system");
          }
          setEmailMobileIsInprogress(false);
        });
      }
    }
  };

  const passwordInputChangeHandler = (event) => {
    setPassword(event.target.value);
    setPasswordTouch(true);
    const result = StringUtil.isValidPassword(event.target.value);
    if (result.result === true) {
      setPasswordIsValid(true);
      setPasswordErrMsg("");
    } else {
      setPasswordIsValid(false);
      setPasswordErrMsg(result.errMsg);
    }
  };

  const confirmPasswordInputChangeHandler = (event) => {
    setConfirmPasswordTouch(true);
    const result = StringUtil.isValidConfirmPassword(
      password,
      event.target.value
    );

    if (result.result === true) {
      setConfirmPasswordIsValid(true);
      setConfirmPasswordErrMsg("");
    } else {
      setConfirmPasswordIsValid(false);
      setConfirmPasswordErrMsg(result.errMsg);
    }
  };

  const emailMobileVerificationComponent = () => {
    if (emailMobileIsInprogress && !isEmailMobileError) {
      return <CircularProgress sx={{ mx: 2 }} size={25} />;
    } else if (!isEmailMobileError && !inputEmailMobileInvalid) {
      return <DoneIcon color="success" />;
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    setEmailMobileTouch(true);
    setPasswordTouch(true);
    setConfirmPasswordTouch(true);

    if (
      inputEmailMobileInvalid ||
      isEmailMobileError ||
      !passwordIsValid ||
      !confirmPasswordIsValid
    ) {
      return;
    }

    dispatch(loaderActions.startLoader());
    PasswordResetApi.genrateOtp({
      emailMobile: emailMobile,
      type: emailOrMobile,
    })
      .then((response) => {
        props.onSuccess(emailOrMobile,  emailMobile, password);
        dispatch(
          SnackBarActions.openSnackBar({
            message:
              emailOrMobile === "email"
                ? "OTP has been sent to your Email Address successfully"
                : "OTP has been sent to your Mobile Number successfully",
            messageType: SnackBarMessageType.SUCCESS,
          })
        );
        dispatch(loaderActions.stopLoader());
        // } else {
        //   dispatch(
        //     SnackBarActions.openSnackBar({
        //       message: "OTP Generation Failed",
        //       messageType: SnackBarMessageType.ERROR,
        //     })
        //   );
        //   dispatch(loaderActions.stopLoader());
        // }
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        dispatch(loaderActions.stopLoader());
      });
  };

  return (
    <Paper elevation={3}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          pb={4}
          pt={5}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img height={40} src="/assets/images/vay.png" alt="mobile" />
          <br />
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <br />
          <form onSubmit={onSubmitHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={isEmailMobileError}
                  helperText={emailMobileHelpText}
                  onChange={emailMobileInputChangeHandler}
                  onBlur={emailMobileFocusOutEventHandler}
                  margin="normal"
                  required
                  fullWidth
                  id="emailMobile"
                  label="Email or Mobile"
                  name="emailMobile"
                  autoComplete="emailMobile"
                  autoFocus
                  InputProps={{
                    endAdornment: emailMobileVerificationComponent(),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={inputPasswordInvalid}
                  helperText={inputPasswordInvalid && passwordErrMsg}
                  onChange={passwordInputChangeHandler}
                  required
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  id="password"
                  // InputProps={{ // <-- This is where the toggle button is added.
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton
                  //         aria-label="toggle password visibility"
                  //         onClick={handleClickShowPassword}
                  //       >
                  //         {showPassword ? <Visibility /> : <VisibilityOff />}
                  //       </IconButton>
                  //     </InputAdornment>
                  //   )
                  // }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={inputConfirmPasswordInvalid}
                  helperText={
                    inputConfirmPasswordInvalid && confirmPasswordErrMsg
                  }
                  onChange={confirmPasswordInputChangeHandler}
                  required
                  fullWidth
                  name="confirm password"
                  label="Confirm New Password"
                  type={showPassword ? "text" : "password"}
                  id="confirm_password"
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isDisabled}
            >
              Generate Otp{" "}
              {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to={RoutePath.APP_LOGIN} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Paper>
  );
}

export default ForgotPasswordComponent;
