export default class DateUtil {
  static quarterMonths = {
    Q1: [3, 4, 5],
    Q2: [6, 7, 8],
    Q3: [9, 10, 11],
    Q4: [0, 1, 2],
  };
  static convertToDateFormat(timestamp) {
    let d = new Date(timestamp * 1000);
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return d.toLocaleDateString("en-US", options);
  }

  static getMonthFromInt(month) {
    const MONTHS = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "Mar",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec",
    };

    return MONTHS[month];
  }

  static getCurrentQuarter() {
    const currentDate = new Date();
    

    const currentMonth = currentDate.getMonth();
    let currentQuarter = [];
    for (let q in this.quarterMonths) {
      if (this.quarterMonths[q].includes(currentMonth)) {
        currentQuarter = q;
        break;
      }
    }
    return currentQuarter;
  }

  static getPreviousQuarter() {
    
    let currentQuarter = this.getCurrentQuarter();
    let quarterYear =
      currentQuarter === "Q4"
        ? new Date().getFullYear() - 1
        : new Date().getFullYear();
    let quarter = Object.entries(this.quarterMonths).at(
      Object.keys(this.quarterMonths).indexOf(currentQuarter) - 1
    );

    let lastDate = new Date(quarterYear,quarter[1][2]+1,1);
    lastDate = new Date(lastDate - 1).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    return {
      quarter,
      year: quarterYear,
      to : lastDate,
    };
  }

  static getLastYearQuarter() {
    let quarterYear = new Date().getFullYear() - 1;
    let quarter = [
      this.getCurrentQuarter(),
      this.quarterMonths[this.getCurrentQuarter()],
    ];
    let firstDate = new Date();
    firstDate.setFullYear(quarterYear);
    firstDate.setMonth(quarter[1][0], 1);
    firstDate = new Date(firstDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return {
      quarter,
      year: quarterYear,
      from : firstDate,
    };
  }

}

