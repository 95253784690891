import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import StatsCardComponent from "../dashboard/StatsCardComponent";
import GbsMonthlyChartComponent from "../dashboard/GbsMonthlyChartComponent";
import StatsBusinessScoreRequestApi from "../../http/v1_apis/StatsBusinessScoreRequestApi";
import GbsYearlyChartComponent from "../dashboard/GbsYearlyChartComponent";
import GbsScoreCategoryChartComponent from "../dashboard/GbsScoreCategoryChartComponent";

const PlatformAdminDashboardGbsComponent = (props) => {
  const [total, setTotal] = React.useState(0);
  const [success, setSuccess] = React.useState(0);
  const [error, setError] = React.useState(0);

  useEffect(() => {
    setSuccess(0);
    setError(0);
    setTotal(0);

    StatsBusinessScoreRequestApi.getStatsStatusCount({
      filter: {
        duration: props.duration,
        apiClientId: props.apiClientId,
        generatedBy: "USER",
      },
    }).then((response) => {
      var ttl = 0;
      response.forEach((res) => {
        if (res["statsStatus"] === "ERROR") {
          setError(res["count"]);
          ttl = ttl + res["count"];
        } else if (res["statsStatus"] === "SUCCESS") {
          setSuccess(res["count"]);
          ttl = ttl + res["count"];
        }
      });
      setTotal(ttl);
    });
  }, [props.duration, props.apiClientId]);

  return (
    <Container sx={{ m: 2, p: 5 }}>
      <Typography variant="h2">GBS Stats</Typography>
      <Box sx={{ p: 3 }} />

      <Grid container spacing={5}>
        <Grid item>
          <StatsCardComponent title="Total" value={total} />
        </Grid>
        <Grid item>
          <StatsCardComponent title="Success" value={success} />
        </Grid>
        <Grid item>
          <StatsCardComponent title="Error" value={error} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Grid container sx={{ pt: 3 }}>
            <Grid item>
              <GbsYearlyChartComponent
                duration={props.duration}
                apiClientId={props.apiClientId}
              />
            </Grid>
            <Grid item>
              <GbsScoreCategoryChartComponent
                duration={props.duration}
                apiClientId={props.apiClientId}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box sx={{ p: 3 }} />
          <GbsMonthlyChartComponent
            duration={props.duration}
            apiClientId={props.apiClientId}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
export default PlatformAdminDashboardGbsComponent;
