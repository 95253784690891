import { Typography } from "@mui/material";
import React from "react";
const TextDetailsComponent = (props) => {
    const caption = props.caption;
    const value = props.value;
    const color = (props.color !=="" ? props.color : "#000000");
    return (
        <React.Fragment>
            <Typography variant="caption" display="block">
                {caption}
            </Typography>
            <Typography variant="subtitle1" display="block" style={{marginTop: 4, fontWeight:600, color: color}}>
                {value === "" ? <span style={{color:"#cdcdcd"}}>None</span>: value}
            </Typography>
        </React.Fragment>

    );
};
export default TextDetailsComponent;