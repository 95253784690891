import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import ReportRequestApi from "../../http/v2_apis/ShuriReportRequestApi";
import { SnackBarActions } from "../../store/SnackBarSlice";
import {
  GstReportRequestStatus,
  GstReportRequestStatusByValue,
  SnackBarMessageType,
} from "../../util/Constant";
import DateUtil from "../../util/DateUtil";
import SystemDefault from "../../util/SystemDefault";
import ListingCardComponent from "../common/ListingCardComponent";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import PaginationStatusBarComponent from "../common/PaginationStatusBarComponent";
import { ReportFilter } from "./ReportFilterComponent";
import AppUtil from "../../util/AppUtil";

const ReportsListingComponent = (props) => {
  const dispatch = useDispatch();
  const borrowerDetails = props.borrowerDetails;
  const [reportList, setReportList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [filterReportType, setFilterReportType] = React.useState("ALL");
  const [pageCount, setPageCount] = React.useState(0);
  const [records, setRecords] = React.useState(SystemDefault.PAGE_SIZE[0]);

  const onRecordsChangeHandler = (value) => {
    setRecords(value);
  };

  const onClickDownloadHandler = (report) => {
    ReportRequestApi.downloadReport({
      ReportAttachmentid: report.outputProperty.reportAttachmentId,
      forOrgId: report.inputProperty.reportOrgId,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${report.reportType}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: err.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });
  };

  const onPageChangeHandler = (value) => {
    setPage(value);
  };

  const fetchReportData = React.useCallback(() => {
    setLoading(true);

    ReportRequestApi.search({
      searchMode: SystemDefault.SEARCH_MODE,
      page: page,
      records: records,
      filter: {
        reportType: filterReportType === "ALL" ? null : filterReportType,
      },
      select: {},
      sort: { createdAtAsc: false, createdAtDesc: true },
      forOrgId: AppUtil.getCurrentOrgId(),
    })
      .then((response) => {
        setReportList(response.results);
        setPageCount(Math.ceil(response.total / records));
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setLoading(false);
      });
  }, [dispatch, filterReportType, page, records]);

  React.useEffect(() => {
    fetchReportData();
  }, [records, page, fetchReportData]);

  React.useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  const getNameFromPan = (pan) => {
    let borrower = borrowerDetails.filter(
      (borrower) => borrower.taxIdentifier === pan
    );
    return borrower[0]?.name;
  };

  const renderAvatar = (report) => {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <AssessmentIcon sx={{ fontSize: "70px" }} />
        <Typography variant="h6" color="gray">
          {report.reportType === "PURCHASE_RELATIONSHIP" ? "Purchase" : "Sales"}
        </Typography>
      </Grid>
    );
  };

  const loadData = () => {
    return (
      <React.Fragment>
        <div>
          {reportList &&
            reportList.map((report) => {
              let reportPan = getNameFromPan(
                report.inputProperty.reportTaxIdentifier
              );
              return (
                <ListingCardComponent
                  key={report.id}
                  item={report}
                  avatar={renderAvatar(report)}
                  title={
                    <Grid container sx={{ mb: -2 }}>
                      <Typography>{reportPan}</Typography>
                      <Typography sx={{ marginLeft: "8px" }} color={"gray"}>
                        ({report.inputProperty.reportTaxIdentifier})
                      </Typography>
                    </Grid>
                  }
                  subTitle3={
                    <Typography variant="caption" sx={{ mb: -1 }}>
                      {DateUtil.convertToDateFormat(report.createdAt)}
                    </Typography>
                  }
                  subTitle1={
                    <Typography variant="caption">
                      Referrer PAN :
                      {report.inputProperty.referrerTaxIdentifier
                        ? report.inputProperty.referrerTaxIdentifier
                        : "NONE"}
                    </Typography>
                  }
                  subTitle2={
                    <Typography variant="caption">
                      {report.inputProperty.fromYyyymm.toString().slice(0, 4)}{" "}
                      {DateUtil.getMonthFromInt(
                        report.inputProperty.fromYyyymm.toString().slice(4, 6)
                      )}
                      {" - "}
                      {report.inputProperty.toYyyymm
                        .toString()
                        .slice(0, 4)}{" "}
                      {DateUtil.getMonthFromInt(
                        report.inputProperty.toYyyymm.toString().slice(4, 6)
                      )}
                    </Typography>
                  }
                  column1={
                    <Box>
                      <Chip
                        label={
                          GstReportRequestStatusByValue(report.status).status
                        }
                        color={
                          GstReportRequestStatusByValue(report.status).color
                        }
                      />
                    </Box>
                  }
                  hintText={
                    report.status === GstReportRequestStatus.DATA_NOT_FOUND
                      ? " Hint: No data found for this pan"
                      : ""
                  }
                  action1={
                    report.status === GstReportRequestStatus.COMPLETED
                      ? "Download"
                      : undefined
                  }
                  actionHandler1={onClickDownloadHandler}
                />
              );
            })}
        </div>
        <PaginationStatusBarComponent
          key="pagination"
          pageCount={pageCount}
          page={page}
          onPageChangeHandler={onPageChangeHandler}
          records={records}
          onRecordsChangeHandler={onRecordsChangeHandler}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Paper elevation={3} sx={{ mt: 3 }}>
        {
          <ReportFilter
            filterReportType={filterReportType}
            setFilterReportType={setFilterReportType}
            fetchReportData={fetchReportData}
          />
        }
        <Divider style={{ marginBottom: 10 }} />
        {loading && (
          <Grid container justifyContent={"center"} direction={"row"}>
            <CircularProgress size={24} sx={{ margin: "18px" }} />
          </Grid>
        )}
        {!loading && reportList?.length <= 0 && <NoDataFoundComponent />}

        {loadData()}
      </Paper>
    </React.Fragment>
  );
};

export default ReportsListingComponent;
