import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class BusinessUnitApi {
  static path = "v1/business-units";

  static fetch = () => {
    let path = BusinessUnitApi.path + "";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };
}