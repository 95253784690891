import { Box, Button, Grid, Slide, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import RoutePath from "../../util/RoutePath";

const CheckGBSComponent = () => {
  const data = [
    { id:"1", imagePath: "/assets/images/confidential.png", txt: "It's confidential" },
    { id:"2",
      imagePath: "/assets/images/quickNSample.png",
      txt: "It’s quick and simple",
    },
    { id:"3",
      imagePath: "/assets/images/periodicalHealth.png",
      txt: "A periodical health check",
    },
  ];
  const history = useHistory();

  const routeChange = () => {
    history.push(RoutePath.APP_LOGIN);
  };

  return (
    <Grid container justifyContent="space-between">
      <Slide in direction="right" mountOnEnter>
        <Grid item sm={8}>
          <Typography variant="h6">
            <Box my={5}>
              The <b>Vayana - Good Business Score (GBS)</b>, powered by CRIF
              helps you attract new customers and solid suppliers. In short,
              it’s Good for Business! So go ahead and find out your GBS score -
              it will take just a few minutes, and it is absolutely FREE!
            </Box>
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            {data.map((item) => (
              <Grid item xs={12} sm={2} key={item.id}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <img src={item.imagePath} alt="mobile" />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      <Box fontWeight="bold">{item.txt}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Box my={10} mx={10}>
            <nav>
              {/*<Link to="/gbs/score" className="btn btn-primary">*/}
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={routeChange}
                name="btn_gbs_frm_check"
                // startIcon={<LoginIcon />}
              >
                <h2>Login for GBS</h2>
              </Button>
              {/*</Link>*/}
            </nav>
          </Box>
        </Grid>
      </Slide>
      <Grid item sm={4}>
        <Slide in direction="left" mountOnEnter>
          <img src="/assets/images/mobile-app.png" alt="mobile" />
        </Slide>
      </Grid>
    </Grid>
  );
};

export default CheckGBSComponent;
