import { createSlice } from "@reduxjs/toolkit";

const initState = { userDetails: {} };
const CurrentUserInfoSlice = createSlice({
  name: "currentUserInfo",
  initialState: initState,
  reducers: {
    setCurrentUserInfo(state, action) {
      state.userDetails = action.payload;
    },
    removeCurrentUserInfo(state) {
      state.userDetails = {};
    },
  },
});

export const currentUserInfoActions = CurrentUserInfoSlice.actions;
export default CurrentUserInfoSlice.reducer;
