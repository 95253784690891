import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import * as React from "react";
import { SnackBarActions } from "../store/SnackBarSlice";
import Paper from "@mui/material/Paper";

const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { SnackbarMessage, SnackbarOpen, messageType } = useSelector(
    (state) => state.snackBar
  );

  const handleClose = () => {
    dispatch(SnackBarActions.clearSnackBar());
  };

  return (
    <Paper elevation={3}>
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={messageType}
          sx={{ width: "100%" }}
        >
          {SnackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SnackbarComponent;
