import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import SystemDefault from "../../util/SystemDefault";
import ApiClientsApi from "../../http/v1_apis/ApiClients";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";
import AppUtil from "../../util/AppUtil";
import ApiClientListingComponent from "../../component/api_clients/ApiClientListingComponent";

const PlatformApiClientListingPage = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [isNameValid, setNameValid] = React.useState(false);
  const [isNameTouch, setNameTouch] = React.useState(false);
  const inputNameInvalid = !isNameValid && isNameTouch;
  const [nameHelperText, setNameHelperText] = React.useState("");
  const [isLoaderDisabled, setLoaderVisibility] = React.useState(false);

  const clientNameRegex = new RegExp("^[a-zA-Z0-9]+$");

  const createApiClientButtonHandler = () => {
    // setCaptcha(CaptchaUtil.generateCaptcha());
    setOpen(true);
  };

  const nameInputChangeHandler = (event) => {
    setName(event.target.value);
    setNameTouch(true);
    if (event.target.value.trim() === "") {
      setNameValid(false);
      setNameHelperText(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else if (!clientNameRegex.test(event.target.value)) {
      setNameValid(false);
      setNameHelperText("Only alphanumeric is allowed");
    } else {
      setNameValid(true);
      setNameHelperText("");
    }
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setNameHelperText("");
    setNameValid(false);
    setNameTouch(false);
  };

  const onCreateApiClientSubmitHandler = (event) => {
    event.preventDefault();
    setNameTouch(true);
    if (!isNameValid) {
      return;
    }
    setLoaderVisibility(true);
    ApiClientsApi.create({
      name,
    })
      .then((response) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Api Client Successfully Created",
            messageType: SnackBarMessageType.SUCCESS,
          })
        );
        setLoaderVisibility(false);
        setName("");
        setNameHelperText("");
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setLoaderVisibility(false);
      });
  };

  return (
    <React.Fragment>
      {/* <Paper> */}
      {AppUtil.getUserId() === AppUtil.getUserPlatformAdministratorId() && (
        <Grid container justifyContent="space-between">
          <Grid item px={2} py={2}>
            <Typography component="h1" variant="h5">
              API Client
            </Typography>
          </Grid>
          <Grid item px={2} py={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={createApiClientButtonHandler}
            >
              Create Api Client
            </Button>
            {/* </Stack> */}
          </Grid>
        </Grid>
      )}

      {/* </Paper> */}

      <Paper elevation={1} sx={{ marginTop: 3 }}>
        <ApiClientListingComponent />
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Api Client</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={name}
            fullWidth
            error={inputNameInvalid}
            helperText={nameHelperText}
            onChange={nameInputChangeHandler}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="btn"
            color="primary"
            variant="contained"
            component="span"
            onClick={onCreateApiClientSubmitHandler}
            disabled={isLoaderDisabled}
            style={{ marginRight: "0.7rem" }}
          >
            Submit
            {isLoaderDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        <br />
      </Dialog>
    </React.Fragment>
  );
};

export default PlatformApiClientListingPage;
