import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid, TextField,
  Typography,
  Autocomplete, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@mui/material";
import { useDispatch } from "react-redux";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import React, { useEffect, useState } from "react";
import StringUtil from "../../util/StringUtil";
import ReportRequestApi from "../../http/v2_apis/ShuriReportRequestApi";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { GstReportType, SnackBarMessageType } from "../../util/Constant";
import RoutePath from "../../util/RoutePath";
import {
  DatePicker,
  LocalizationProvider
} from "@mui/x-date-pickers";
import dayjs from "dayjs";

const ReportCreateComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const myCurrentOrg = useSelector((state) => state.currentOrg);
  const borrowerDetails = location.state;

  const [title, setTitle] = React.useState("");

  const [referrerPan, setReferrerPan] = React.useState("");
  const [referrerPanIsValid, setReferrerPanIsValid] = useState(true);
  const [referrerPanErrMsg, setReferrerPanErrMsg] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openConsent, setOpenConsent] = React.useState(false);

  const [fromDate, setFromDate] = React.useState(
    dayjs().subtract(1, "year").add(1, "month")
  );

  const [reportOrg, setReportOrg] = useState("");
  const [reportOrgIsValid, setReportOrgIsValid] = useState(false);
  const [reportOrgTouch, setReportOrgTouch] = useState(false);
  const [reportOrgErrMsg, setReportOrgErrMsg] = useState("");
  const reportOrgInvalid = !reportOrgIsValid && reportOrgTouch;

  const [toDate, setToDate] = React.useState(dayjs());

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [reportType, setReportType] = React.useState("");
  const [borrowerNameList, setBorrowerNameList] = React.useState([]);
  const params = useParams();
  const referrerPanInputChangeHandler = (event) => {
    const inputPan = event.target.value.toUpperCase().trim();
    setReferrerPan(inputPan);
    const isValidPan = StringUtil.isValidPan(inputPan);
    if (inputPan === "") {
      setReferrerPanIsValid(true);
      setReferrerPanErrMsg("");
    } else if (!isValidPan.result) {
      setReferrerPanIsValid(false);
      setReferrerPanErrMsg(isValidPan.errMsg);
    } else {
      setReferrerPanIsValid(true);
      setReferrerPanErrMsg("");
    }
  };

  const handleOpenConsent = () => {
    setOpenConsent(true);
  };

  const handleCloseConsent = () => {
    setOpenConsent(false);
  };

  useEffect(() => {
    if (params.reportType === "purchase") {
      setTitle("Generate Purchase Relationship Report");
      setReportType(GstReportType.PURCHASE_RELATIONSHIP);
    } else if (params.reportType === "sales") {
      setTitle("Generate Sales Relationship Report");
      setReportType(GstReportType.SALES_RELATIONSHIP);
    }

    setBorrowerNameList(
      Object.entries(borrowerDetails).map((i) => {
        return { name: i[1].name, id: i[0], taxIdentifier: i[1].taxIdentifier };
      })
    );
  }, [borrowerDetails, params.reportType]);

  const fromDateInputChangeHandler = (newFromDate) => {
    if (newFromDate) {
      setFromDate(newFromDate);
    }
    if (newFromDate.$d !== "Invalid Date") {
      isDateValid(toDate, newFromDate);
    }
  };

  const toDateInputChangeHandler = (newToDate) => {
    if (newToDate) {
      setToDate(newToDate);
    }
    isDateValid(newToDate, fromDate);
  };

  const ReportOrgChangeHandler = (event) => {
    setReportOrgTouch(true);
    setReportOrg(event.target.innerText);
    if (!event.target.innerText) {
      setReportOrgIsValid(false);
      setReportOrgErrMsg("Please select Report Organisation");
    } else {
      setReportOrgIsValid(true);
      setReferrerPanErrMsg("");
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setErrorMessage("");

    setReportOrgTouch(true);
    if (!reportOrgIsValid) {
      return;
    }
    if (!isDateValid()) {
      return;
    }
    if (!referrerPanIsValid) {
      return;
    }
    setIsDisabled(true);
    ReportRequestApi.validateReportRequest({
      requesterOrgId: borrowerDetails.filter((i) => {
        if (reportOrg.search(i.taxIdentifier) !== -1) {
          return true;
        }
        return false;
      })[0]?.id,
      fromYYYYMM: dayjs(fromDate).format("YYYYMM"),
      toYYYYMM: dayjs(toDate).format("YYYYMM"),
      referrerTaxIdentifier: referrerPan,
      reportType: reportType,
    })
      .then((response) => {
        if(response.isValid){
        if (response.reportStatus === 40) {
          handleOpenConsent();
        } else if(response.reportStatus === 50){
          handleOpenConsent()
        }else{
          submitCreateRequest()
        }
        setIsDisabled(false);
      }
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setIsDisabled(false);
      });
  };

  const submitCreateRequest = () => {
    ReportRequestApi.create({
      requesterOrgId: borrowerDetails.filter((i) => {
        if (reportOrg.search(i.taxIdentifier) !== -1) {
          return true;
        }
        return false;
      })[0]?.id,
      fromYYYYMM: dayjs(fromDate).format("YYYYMM"),
      toYYYYMM: dayjs(toDate).format("YYYYMM"),
      referrerTaxIdentifier: referrerPan,
      reportType: reportType,
    })
      .then((response) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Report Generated",
            messageType: SnackBarMessageType.SUCCESS,
          })
        );
        history.push(
          RoutePath.routeAppOrgGstReportsListingPage({
            orgId: myCurrentOrg.organisation.id,
          })
        );
        setIsDisabled(false);
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setIsDisabled(false);
      });
  };

  const isDateValid = (validToDate = toDate, validFromDate = fromDate) => {
    if (
      validToDate.$d === "Invalid Date" ||
      validFromDate.$d === "Invalid Date"
    ) {
      setErrorMessage("Invalid Date, please enter again");
      return false;
    }
    if (validToDate.$d > Date.now()) {
      setErrorMessage(
        "To Date parameter must be less than or equal to current date"
      );
      return false;
    } else if (validFromDate.$d > Date.now()) {
      setErrorMessage(
        "From Date parameter must be less than or equal to current date"
      );
      return false;
    } else if (validFromDate.$d > validToDate.$d) {
      setErrorMessage("From Date paramater must be less than To Date");
      return false;
    }

    const diffTime = Math.abs(validToDate.$d - validFromDate.$d);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays >= 460) {
      setErrorMessage("Period should not be greater than 15 months");
      return false;
    }
    setErrorMessage("");

    return true;
  };

  return (
    <div>
      <Dialog
        open={openConsent}
        onClose={handleCloseConsent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Re-generate Report?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Similar report has already been generated do you want to continue?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConsent}>Cancel</Button>
          <Button onClick={submitCreateRequest} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        pb={5}
        pt={5}
        sx={{
          margin: "auto",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
        }}
      >
        <Typography variant="h5" mb={5} align="center">
          {title}
        </Typography>
        <form onSubmit={onSubmitHandler} noValidate>
          <Grid
            container
            direction="column"
            sx={{ width: "100%", justifyContent: "center" }}
          >
            <Grid item sx={{ mt: 2 }}>
              <TextField
                id="reportType"
                value={
                  reportType === "PURCHASE_RELATIONSHIP"
                    ? "Purchase Relationship Report"
                    : "Sales Relationship Report"
                }
                label="Report Type"
                // sx={{ width: "100%" }}
                disabled={true}
                required
                fullWidth
              ></TextField>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  id="borrowerId"
                  options={borrowerNameList.map(
                    (i) => `${i.name} (${i.taxIdentifier})`
                  )}
                  onChange={ReportOrgChangeHandler}
                  value={reportOrg}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={reportOrgInvalid}
                      label="Report Organisation"
                      // helperText={reportOrgErrMsg}
                      value={reportOrg}
                      helperText={reportOrgErrMsg}
                      required
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                fullWidth
                name="referrerPan"
                value={referrerPan}
                id="referrerPan"
                label="Referrer PAN"
                error={!referrerPanIsValid}
                helperText={referrerPanErrMsg}
                onChange={referrerPanInputChangeHandler}
              />
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    views={["month", "year"]}
                    value={fromDate}
                    onChange={fromDateInputChangeHandler}
                    fullWidth
                    label="From Date"
                    disableFuture
                    // sx={{ width: "40%" }}
                    required
                    format="YYYY/MM"
                  />

                  <DatePicker
                    views={["month", "year"]}
                    value={toDate}
                    onChange={toDateInputChangeHandler}
                    // sx={{ width: "40%" }}
                    label="To Date"
                    disableFuture
                    format="YYYY/MM"
                    required
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            {errorMessage && (
              <Typography sx={{ fontSize: "12px", color: "red" }}>
                {errorMessage}
              </Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              // fullWidth
              sx={{ mt: 3, mb: 2 }}
              disabled={isDisabled}
              onClick={onSubmitHandler}
            >
              Submit
              {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
            </Button>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default ReportCreateComponent;
