import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import StringUtil from "../util/StringUtil";

import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
  Grid
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AppUtil from "../util/AppUtil";
import { useHistory, useLocation } from "react-router-dom";
import RoutePath from "../util/RoutePath";
import { currentOrgActions } from "../store/CurrentOrgSlice";
import { gstinActions } from "../store/GstinDetailsSlice";
import { bankDetailsActions } from "../store/BankDetailsSlice";
import HomeIcon from "@mui/icons-material/Home";
import { appBarActions } from "../store/AppBarSlice";
import SupportTicketApi from "../http/v1_apis/SupportTicketApi";
import SystemDefault from "../util/SystemDefault";
import { SnackBarActions } from "../store/SnackBarSlice";
import { SnackBarMessageType } from "../util/Constant";
import CaptchaUtil from "../util/CaptchaUtil";
import AuthApi from "../http/theodore/AuthApi";
import { cleanupReduxSlice } from "../store/CleanupReduxSlice";

const AppBarComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const appBar = useSelector((state) => state.appBar);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [titleLength, setTitleLength] = React.useState("");
  const [isTitleValid, setTitleValid] = React.useState(false);
  const [isTitleTouch, setTitleTouch] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [isQueryValid, setQueryValid] = React.useState(false);
  const [isQueryTouch, setQueryTouch] = React.useState(false);
  const [captchaAnswerText, setCaptchaAnswer] = React.useState("");
  const [captchaErrMsg, setCaptchaErrMsg] = React.useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [isCaptchaVerified, setCaptchaVerification] = React.useState(false);
  const [isCaptchaTouch, setCaptchaTouch] = React.useState(false);
  const captchaInvalid = !isCaptchaVerified && isCaptchaTouch;
  const inputTitleInvalid = !isTitleValid && isTitleTouch;
  const [titleHelperText, setTitleHelperText] = React.useState("0 / 250");
  const inputQueryInvalid = !isQueryValid && isQueryTouch;
  const [queryErrMsg, setQueryErrMsg] = React.useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [isLoaderDisabled, setLoaderVisibility] = React.useState(false);
  const [captcha, setCaptcha] = React.useState({});
  const location = useLocation();

  const homeButtonHandler = () => {
    setAnchorEl(false);
    dispatch(appBarActions.setAppBarTitle(""));
    dispatch(currentOrgActions.removeCurrentOrg());
    dispatch(gstinActions.resetGstinDetails());
    dispatch(bankDetailsActions.resetBankDetails());
    history.push(RoutePath.KEY_HOME);
  };

  const logOutHandler = () => {
    AuthApi.logout().then((response) => {
      AppUtil.removeJwtToken();
      AppUtil.removeUserId();
      cleanupReduxSlice(dispatch);
      history.push(RoutePath.APP_LOGIN);
    });
    setAnchorEl(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setTitle("");
    setTitleHelperText("0 / 250");
    setTitleValid(false);
    setTitleTouch(false);
    setQuery("");
    setQueryValid(false);
    setQueryTouch(false);
    setCaptchaTouch(false);
    setCaptchaVerification(false);
    setQueryErrMsg("");
    setCaptchaAnswer("");
    setCaptchaErrMsg("");
  };

  const helpButtonHandler = () => {
    setCaptcha(CaptchaUtil.generateCaptcha());
    setOpen(true);
    setAnchorEl(false);
  };

  const titleInputChangeHandler = (event) => {
    setTitle(event.target.value);
    setTitleTouch(true);
    setTitleLength(`${event.target.value.length + 1} / 250`);
    const result = StringUtil.isValidText(event.target.value);
    if(result.result === true){
      if (event.target.value.length > 250) {
        setTitleValid(false);
        setTitleHelperText("Max 250 characters");
      } else {
        setTitleValid(true);
        setTitleHelperText(titleLength);
      }
    } else {
      setTitleValid(false);
      setTitleHelperText(result.errMsg);
    } 
  };

  const queryInputChangeHandler = (event) => {
    setQuery(event.target.value);
    setQueryTouch(true);
    const result = StringUtil.isValidText(event.target.value);
    if(result.result === true){
        setQueryValid(true);
    } else {
      setQueryValid(false);
      setQueryErrMsg(result.errMsg);
    } 
  };

  const captchaInputChangeHandler = (event) => {
    setCaptchaAnswer(event.target.value);
    setCaptchaTouch(true);
    if (event.target.value.trim() === "") {
      setCaptchaVerification(false);
      setCaptchaErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else if (event.target.value === captcha.answer) {
      setCaptchaVerification(true);
      setCaptchaErrMsg("");
    } else {
      setCaptchaVerification(false);
    }
  };

  const onSupportTicketSubmitHandler = (event) => {
    event.preventDefault();
    const url = location.pathname;
    setTitleTouch(true);
    setCaptchaTouch(true);
    setQueryTouch(true);
    if (title.length === "") {
      setTitleHelperText(SystemDefault.DEFAULT_ERROR_MESSAGE);
    }
    if (captchaAnswerText !== captcha.answer) {
      setCaptchaVerification(false);
      setCaptchaAnswer("");
      setCaptchaErrMsg("Invalid Captcha");
      setCaptcha(CaptchaUtil.generateCaptcha());
    }
    if (!isTitleValid || !isQueryValid || !isCaptchaVerified) {
      return;
    }
    setLoaderVisibility(true);
    SupportTicketApi.createTicket({
      title,
      query,
      url,
    })
      .then((response) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Ticket Successfully Generated",
            messageType: SnackBarMessageType.SUCCESS,
          })
        );
        setLoaderVisibility(false);
        setTitle("");
        setTitleHelperText("0 / 250");
        setQuery("");
        setOpen(false);
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error["errorMessage"],
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setQuery("");
        setLoaderVisibility(false);
      });
  };

  const profileButtonHandler = () => {
    setAnchorEl(false);
    dispatch(appBarActions.setAppBarTitle(""));
    history.push(RoutePath.KEY_PROFILE_DETAILS);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton color="inherit" size="Large" onClick={homeButtonHandler}>
          <HomeIcon />
          <Typography variant="h6"> Home</Typography>
        </IconButton>
        <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="center">
          <Typography variant="h6">
            {appBar.title !== null && appBar.title}
          </Typography>
        </Box>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={homeButtonHandler}>
              <IconButton>
                <HomeOutlinedIcon />
              </IconButton>{" "}
              Home
            </MenuItem>
            <MenuItem onClick={profileButtonHandler}>
              <IconButton>
                <AccountCircle />
              </IconButton>
              Profile
            </MenuItem>
            <MenuItem onClick={helpButtonHandler}>
              <IconButton>
                <HelpOutlineOutlinedIcon />
              </IconButton>{" "}
              Contact Us
            </MenuItem>
            <MenuItem onClick={logOutHandler}>
              <IconButton>
                <LogoutIcon />
              </IconButton>
              Log Out
            </MenuItem>
          </Menu>
        </div>
        <Dialog open={open} onClose={handleClose} fullWidth={true}>
          <DialogTitle>Post Your Query</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Subject"
              value={title}
              fullWidth
              inputProps={{ maxLength: 250 }}
              error={inputTitleInvalid}
              helperText={titleHelperText}
              onChange={titleInputChangeHandler}
            />

            <TextField
              autoFocus
              margin="dense"
              id="query"
              label="Please describe your query. Please paste the error message, if any."
              value={query}
              multiline
              rows={8}
              fullWidth
              error={inputQueryInvalid}
              helperText={inputQueryInvalid && queryErrMsg}
              onChange={queryInputChangeHandler}
            />
            <Grid
              container
              spacing={2}
              marginTop={1}
              marginBottom={1}
              alignItems="center"
            >
              <Grid item>
                <img src={captcha.number1Img} width={64} height={64} alt="captcha 1"></img>
              </Grid>
              <Grid item>
                <Typography variant="h4">{captcha.operationSign}</Typography>
              </Grid>
              <Grid item>
                <img src={captcha.number2Img} width={64} height={64} alt="captcha 2"></img>
              </Grid>
              <Grid item marginLeft={2}>
                <TextField
                  autoFocus
                  variant="outlined"
                  value={captchaAnswerText}
                  error={captchaInvalid}
                  helperText={captchaInvalid && captchaErrMsg}
                  onChange={captchaInputChangeHandler}
                  name="captchaAnswer"
                  id="captchaAnswer"
                  label="Captcha Answer"
                  size="small"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className="btn"
              color="primary"
              variant="contained"
              component="span"
              onClick={onSupportTicketSubmitHandler}
              disabled={isLoaderDisabled}
              style={{ marginRight: "0.7rem" }}
            >
              Submit{" "}
              {isLoaderDisabled && (
                <CircularProgress sx={{ mx: 2 }} size={25} />
              )}
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <br />
        </Dialog>
      </Toolbar>
    </AppBar>
  );
};
export default AppBarComponent;
