import RESTUtil from "../../util/RESTUtil";
import TheodoreApiRESTUtil from "../../util/TheodoreRESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class AuthApi {
  static validateUserCredential = ({ type, emailMobile, password, otp }) => {
    let path = "authtokens";
    const command = {
      handle: emailMobile,
      password: password === "" ? null : password,
      handleType: type,
      tokenDurationInMins: 20,
    };
    if (otp !== "") {
      command["otp"] = otp;
    }
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static requestEmailLoginOtp = ({ email }) => {
    let path = "users/email/otps";
    const command = { email: email, otpPurposeType: "LoginOTP" };
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static requestMobileLoginOtp = ({ mobile }) => {
    let path = "users/mobile/otps";
    const command = { mobile: mobile, otpPurposeType: "LoginOTP" };
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static updateToken = () => {
    let path = "authtokens";
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PUT,
    });
  };

  static logout = () => {
    let path = "logout";
    const command = {};
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

}
