import * as React from "react";
import {
  CircularProgress, Typography
} from "@mui/material";
import SystemDefault from "../../util/SystemDefault";
import RefererReferredApi from "../../http/v1_apis/RefererReferredOrgApi";
import PaginationStatusBarComponent from "../common/PaginationStatusBarComponent";
import ListingCardComponent from "../common/ListingCardComponent";
import DateUtil from "../../util/DateUtil";
import Paper from "@mui/material/Paper";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import RoutePath from "../../util/RoutePath";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";


const BorrowerListingComponent = () => {
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);
  const currentOrg = useSelector((state) => state.currentOrg);

  const [records, setRecords] = React.useState(SystemDefault.PAGE_SIZE[0]);
  const [loading, setLoading] = React.useState(false);
  const [borrowerData, setBorrowerData] = React.useState([]);
  const [orgMap, setOrgMap] = React.useState({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    RefererReferredApi.search({
      searchMode: SystemDefault.SEARCH_MODE,
      page: page,
      records: records,
      filter: {referer:false, referred:true},
      select: {organisations: true},
      sort: { createdAtDesc: true },
    }).then((response) => {
      setBorrowerData(response.results);
      setOrgMap(response.objects.organisations);
      setPageCount(Math.ceil(response.total / records));
      setLoading(false);
    
    }).catch((error)=> {
      dispatch(
        SnackBarActions.openSnackBar({
          message: error.errorMessage,
          messageType: SnackBarMessageType.ERROR,
        })
      );
      setLoading(false);
    });
  }, [page, records, dispatch]);




  const onPageChangeHandler = (value) => {
    setPage(value);
  };
  const onRecordsChangeHandler = (value) => {
    setRecords(value);
  };

  const onClickViewHandler = (item) => {
    history.push(
      RoutePath.routeFiBorrowerDetail({
        borrowerId: item.referredId,
        orgId: currentOrg?.organisation?.id,
      })
    );
  };
  // const getColour = () => {
  //   return '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
  // }

  const loadData = () => {
    return (
      <React.Fragment>
        {borrowerData.map((item) => {
          return (
            <ListingCardComponent
              key={item.referredId}
              item={item}
              title={orgMap[item.referredId]?.name}
              subTitle1={
                <Typography variant="caption" display="inline">
                  PAN : {orgMap[item.referredId]?.taxIdentifier}
                </Typography>
              }
              subTitle2={
                <Typography variant="caption" display="inline">
                  Created at : {DateUtil.convertToDateFormat(item.createdAt)}
                </Typography>
              }
              action1={"View"}
              actionHandler1={onClickViewHandler}
            />
          );
        })}
        <PaginationStatusBarComponent
          pageCount={pageCount}
          page={page}
          onPageChangeHandler={onPageChangeHandler}
          records={records}
          onRecordsChangeHandler={onRecordsChangeHandler}
        />
      </React.Fragment>
    );
  };

  return (
    <Paper elevation={1}>
      {loading && <CircularProgress size={24} />}
      {!loading && borrowerData?.length <= 0 && <NoDataFoundComponent />}
      {!loading && loadData()}
    </Paper>
  );
};

export default BorrowerListingComponent;
