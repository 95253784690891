import { createSlice } from "@reduxjs/toolkit";

const initState = { title: null };
const AppBarSlice = createSlice({
  name: "appBar",
  initialState: initState,
  reducers: {
    setAppBarTitle(state, action) {
      state.title = action.payload;
    },
    removeAppBarTitle(state) {
      state.title = null;
    },
  },
});

export const appBarActions = AppBarSlice.actions;
export default AppBarSlice.reducer;
