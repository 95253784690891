import * as React from "react";
import {
  Avatar,
  Chip,
  CircularProgress,
  Paper, Typography
} from "@mui/material";
import BusinessScoreRequestApi from "../../http/v1_apis/BusinessScoreRequestApi";
import SystemDefault from "../../util/SystemDefault";
import { useDispatch, useSelector } from "react-redux";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import PaginationStatusBarComponent from "../common/PaginationStatusBarComponent";
import ListingCardComponent from "../common/ListingCardComponent";
import DateUtil from "../../util/DateUtil";
import {
  BusinessScoreRequestStatus,
  BusinessScoreRequestStatusByValue,
  SnackBarMessageType, BusinessScoreRequestTypeByValue,
  CallType
} from "../../util/Constant";
import AttachmentApi from "../../http/v1_apis/AttachmentApi";
import RoutePath from "../../util/RoutePath";
import { useHistory } from "react-router-dom";
import BorrowerFiListingDialog from "./ShareScoreWithfiDialogComponent";
import RefererReferredApi from "../../http/v1_apis/RefererReferredOrgApi";
import { SnackBarActions } from "../../store/SnackBarSlice";

const BusinessScoreRequestListingComponent = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [businessScoreRequests, setBusinessScoreRequests] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [records, setRecords] = React.useState(SystemDefault.PAGE_SIZE[0]);
  const [pageCount, setPageCount] = React.useState(0);
  const [isShare, setIsShare] = React.useState(false);
  const [shareScoreDialog, setShareScoreDialog] = React.useState(true);
  const [fiBorrowerData, setFiBorrowerData] = React.useState([]);
  const [orgMap, setOrgMap] = React.useState({});
  const [businessScoreRequestToShare, setBusinessScoreRequestToShare] =
    React.useState({});
  const myCurrentOrg = useSelector((state) => state.currentOrg);
  const history = useHistory();

  React.useEffect(() => {
    setLoading(true);
    setBusinessScoreRequests([]);
    BusinessScoreRequestApi.search({
      searchMode: SystemDefault.SEARCH_MODE,
      page: page,
      records: records,
      select: { businessUnits: false },
      sort: { createdAtDesc: true },
    }).then((response) => {
      setBusinessScoreRequests(response.results);
      setPageCount(Math.ceil(response.total / records));
      setLoading(false);
    });
  }, [page, records, myCurrentOrg]);

  const onPageChangeHandler = (value) => {
    setPage(value);
  };

  const onRecordsChangeHandler = (value) => {
    setRecords(value);
  };

  const editModeHandler = (businessScoreRequest) => {
    history.push(
      RoutePath.routeAppOrgGbsEdit({
        bsrId: businessScoreRequest.id,
        orgId: businessScoreRequest.organisationId,
      })
    );
  };

  const viewDetailsHandler = (businessScoreRequest) => {
    history.push(
      RoutePath.routeAppOrgGbsViewDetails({
        bsrId: businessScoreRequest.id,
        orgId: businessScoreRequest.organisationId,
      })
    );
  };

  const shareScoreRequestHandler = (businessScoreRequest) => {

    RefererReferredApi.search({
      searchMode: SystemDefault.SEARCH_MODE,
      filter: { referer: true, referred: false },
      select: { organisations: true },
      sort: { createdAtDesc: true },
    }).then((response) => {
      if (response.results.length <= 0) {
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Your organisation has not been linked with any Referer",
            messageType: SnackBarMessageType.ERROR,
          })
        );
      } else {
        setFiBorrowerData(response.results);
        setOrgMap(response.objects.organisations);
        setBusinessScoreRequestToShare(businessScoreRequest);
        setIsShare(true);
        setShareScoreDialog(true);
      }
    });
  };

  const renderAvatar = (businessScoreRequest) => {
    return (
      <React.Fragment>
        <Avatar
          variant={"square"}
          sx={{
            background: "",
            width: "90%",
            height: "90%",
            fontSize: 50,
            fontWeight: "bold",
          }}
        >
          Q{businessScoreRequest.quarter}
        </Avatar>
        <Avatar
          variant={"square"}
          sx={{
            background: "",
            width: "90%",
            height: "10%",
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {businessScoreRequest.year}
        </Avatar>
      </React.Fragment>
    );
  };

  const onClickDownloadHandler = (businessScoreRequest) => {
    AttachmentApi.download({
      attachmentId: businessScoreRequest.borrowerReportAttachmentId,
      callType: CallType.SELF_ORG,
      forOrgId: "",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const renderScoreRequestType = (businessScoreRequest) => {
    return (
      <Typography variant="caption" display="inline">
        Score Request Type :
        <Chip
          label={
            BusinessScoreRequestTypeByValue(
              businessScoreRequest.scoreRequestType
            ).type
          }
        />
      </Typography>
    );
  };
  const renderMessage = (businessScoreRequest) => {
    let errorMsg = "";
    if (
      businessScoreRequest.status !== BusinessScoreRequestStatus.DRAFT &&
      businessScoreRequest.status !==
        BusinessScoreRequestStatus.PROCESSED_WITH_ERROR &&
      businessScoreRequest.status !==
        BusinessScoreRequestStatus.REPORT_GENERATED &&
      businessScoreRequest.status !== BusinessScoreRequestStatus.EXPIRED
    ) {
      errorMsg =
        "Score generation is in progress... GBS will also be shared on your email.";
      return (
        <Typography
          variant="caption"
          color={
            BusinessScoreRequestStatusByValue(businessScoreRequest.status)
              .color + ".main"
          }
        >
          {errorMsg}
        </Typography>
      );
    }
  };

  const ShareScoreDialogCloseHandler = () => {
    setShareScoreDialog(false);
  };

  const ShareScoreDialogOpenHandler = () => {
    setShareScoreDialog(true);
  };

  const loadData = () => {
    return (
      <React.Fragment>
        {businessScoreRequests?.map((businessScoreRequest) => {
          return (
            <ListingCardComponent
              key={businessScoreRequest.id}
              item={businessScoreRequest}
              avatar={renderAvatar(businessScoreRequest)}
              title={DateUtil.convertToDateFormat(
                businessScoreRequest.createdAt
              )}
              subTitle1={renderScoreRequestType(businessScoreRequest)}
              subTitle2={
                <Typography
                  variant="caption"
                  color={
                    BusinessScoreRequestStatusByValue(
                      businessScoreRequest.status
                    ).color + ".main"
                  }
                >
                  {renderMessage(businessScoreRequest)}
                </Typography>
              }
              column1={
                <Typography variant="h6">
                  {businessScoreRequest.score}
                </Typography>
              }
              column2={
                <Chip
                  label={
                    BusinessScoreRequestStatusByValue(
                      businessScoreRequest.status
                    ).status
                  }
                  color={
                    BusinessScoreRequestStatusByValue(
                      businessScoreRequest.status
                    ).color
                  }
                />
              }
              action1={
                businessScoreRequest.status ===
                  BusinessScoreRequestStatus.DRAFT && "Edit"
              }
              actionHandler1={editModeHandler}
              action2={"View"}
              actionHandler2={viewDetailsHandler}
              action3={
                businessScoreRequest.status ===
                  BusinessScoreRequestStatus.REPORT_GENERATED && "Download"
              }
              actionHandler3={onClickDownloadHandler}
              action4={
                businessScoreRequest.status ===
                  BusinessScoreRequestStatus.REPORT_GENERATED && "Share"
              }
              actionHandler4={shareScoreRequestHandler}
            />
          );
        })}
        <PaginationStatusBarComponent
          key="pagination"
          pageCount={pageCount}
          page={page}
          onPageChangeHandler={onPageChangeHandler}
          records={records}
          onRecordsChangeHandler={onRecordsChangeHandler}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {isShare && (
        <BorrowerFiListingDialog
          openDialog={shareScoreDialog}
          fiData={fiBorrowerData}
          orgMap={orgMap}
          handleclose={ShareScoreDialogCloseHandler}
          handleopen={ShareScoreDialogOpenHandler}
          scoreRequest={businessScoreRequestToShare}
        />
      )}
      <Paper elevation={2}>
        {loading && <CircularProgress size={24} />}
        {!loading && businessScoreRequests?.length <= 0 && (
          <NoDataFoundComponent />
        )}
        {loadData()}
      </Paper>
    </React.Fragment>
  );
};

export default BusinessScoreRequestListingComponent;
