import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class RefererReferredApi {
  static path = "v1/linked-org";
  static search = ({ searchMode, page, records, filter, select, sort }) => {
    let path = RefererReferredApi.path + "/search";
    let command = {
      searchMode: searchMode,
      page: page,
      records: records,
    };
    if (filter && Object.keys(filter).length > 0) {
      command.filter = filter;
    }
    if (select && Object.keys(select).length > 0) {
      command.select = select;
    }
    if (sort && Object.keys(sort).length > 0) {
      command.sort = sort;
    }
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static search_file_data = ({searchMode, page, records, filter, select, sort }) => {
    let path = RefererReferredApi.path + "/file/search";
    let command = {
      searchMode: searchMode,
      page: page,
      records: records,
    };
    if (filter && Object.keys(filter).length > 0) {
      command.filter = filter;
    }
    if (select && Object.keys(select).length > 0) {
      command.select = select;
    }
    if (sort && Object.keys(sort).length > 0) {
      command.sort = sort;
    }
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static uploadReferredFile = ({ BorrowerFile }) => {
    let path = RefererReferredApi.path + "/file";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: BorrowerFile,
    });
  };

  static createReferredData = ({ data }) => {
    let path = RefererReferredApi.path + "/bulk-create";
    let command = {
      data: data,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static singleCreate = ({name, pan, adminEmail}) => {
    let path = RefererReferredApi.path;
    const command = {
      name,
      country:"India",
      taxIdentifierType:"PAN",
      taxIdentifier:pan,
      adminEmail,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static getReferredFile=({ fileId })=>{
    let path = RefererReferredApi.path + "/file/"+fileId;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  }

  static searchReferredDetail = ({ searchMode, page, records, filter, select, sort }) => {
    let path = RefererReferredApi.path + "/file-detail/search";
    let command = {
      searchMode: searchMode,
      page: page,
      records: records,
    };
    if (filter && Object.keys(filter).length > 0) {
      command.filter = filter;
    }
    if (select && Object.keys(select).length > 0) {
      command.select = select;
    }
    if (sort && Object.keys(sort).length > 0) {
      command.sort = sort;
    }
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

}
