import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import BusinessScoreShareRequestApi from "../../http/v1_apis/ShareScoreApi";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { loaderActions } from "../../store/LoaderSlice";

const BorrowerFiListingDialog = (props) => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    React.useState(false);
  const [selectedFiData, setSelectedFiData] = React.useState({});

  const scoreRequest = props.scoreRequest;

  const handleClose = () => {
    props.handleclose();
  };

  const fiClickHandler = (item) => {
    setSelectedFiData(item);
    setOpenConfirmationDialog(true);
    props.handleclose();
  };

  const handleConfirmationDialogClose = () => {
    setOpenConfirmationDialog(false);
    props.handleclose();
  };

  const handleConfirmShareData = () => {
    props.handleclose();
    dispatch(loaderActions.startLoader());
    let scoreRequestId = scoreRequest.id;
    let fiOrganisationID = selectedFiData.refererId;
    BusinessScoreShareRequestApi.create({
      scoreRequestId,
      fiOrganisationID,
    })
      .then((response) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message:
              "Successfully shared this score with selected referer",
            messageType: SnackBarMessageType.SUCCESS,
          })
        );
        dispatch(loaderActions.stopLoader());
        setOpenConfirmationDialog(false);
      })
      .catch((error) => {
        setOpenConfirmationDialog(false);
        dispatch(
            SnackBarActions.openSnackBar({
              message:error.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
      });
  };

  return (
    <div>
      <ConfirmationDialog
        msg={
          <Typography>
            Once you shared selected score with <b>{selectedFiData.name}</b>
            then you are not able to revert it.
          </Typography>
        }
        msg2={<Typography>Are you sure to share this score?</Typography>}
        openConfirmationDialog={openConfirmationDialog}
        handleConfirmationDialogClose={handleConfirmationDialogClose}
        // severity={"info"}
        handleConfirmShareData={handleConfirmShareData}
      />
      <Dialog
        PaperProps={{
          sx: {
            width: "100%",
            maxHeight: 360,
          },
        }}
        open={props.openDialog}
        onClose={handleClose}
      >
        <DialogTitle>Select organisation to share selected score</DialogTitle>
        {Object.values(props.fiData).map((item) => {
          return (
            <ListFi
              key={item.refererId}
              listData={item}
              org={props.orgMap[item.refererId]}
              fiClickHandler={fiClickHandler}
            />
          );
        })}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ListFi = (props) => {
  const key = props.key;
  const listData = props.listData;
  const org = props.org;

  const fiClickHandler = () => {
    props.fiClickHandler(listData);
  };

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      <ListItem key={key} alignItems="flex-start">
        <ListItemButton onClick={fiClickHandler}>
          <ListItemAvatar>
            <Avatar variant={"square"}>
              {org.name.charAt(0).toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          <ListItemText  primary={org.name} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export const ConfirmationDialog = (props) => {
  const loading = useSelector((state) => state.loader.value);
  const isDisabled = loading;
  const confirmationMsg = props.msg;
  const msg2 = props.msg2;
  const severity = props.severity;

  const handleClose = () => {
    props.handleConfirmationDialogClose();
  };

  const handleConfirm = () => {
    props.handleConfirmShareData();
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          sx: { width: "100%" },
        }}
        open={props.openConfirmationDialog}
        onClose={handleClose}
      >
        <Box p={1}>
          <DialogContent>
            {severity && <Alert severity={severity}>{confirmationMsg}</Alert>}
            {!severity && confirmationMsg}
            <br />
            {props.msg2 ? msg2 : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirm} variant={"contained"} disabled={isDisabled}>
              Confirm {isDisabled && <CircularProgress sx={{mx:2}} size={25}/>}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default BorrowerFiListingDialog;
