import {
  CircularProgress,
  Container, Link,
  List, Paper, Typography,
  Button, Grid
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import GBSGstinItemComponent from "./GBSGstinItemComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SnackBarActions } from "../../../store/SnackBarSlice";
import { GstinOtpRequestStatus, SnackBarMessageType } from "../../../util/Constant";
import { stepperActions } from "../../../store/StepperSlice";
import AddGstinBankDetailsComponent from "../../common/AddGstinBankDetailsComponent";
import V2BusinessScoreRequestGstinApi from "../../../http/v2_apis/BusinessScoreRequestGstinApi";

const GBSGstinComponent = (props) => {
  const currentOrg = useSelector((state) => state.currentOrg);
  const pan = currentOrg?.organisation?.taxIdentifier;
  const [gstinMap, setGstinMap] = useState({});
  const [gstinMasterDataMap, setGstinMasterData] = useState({});
  const steps = useSelector((state)=> state.stepper);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [openModelForAddGstin, setOpenModelForAddGstin] = useState(false)
  
  const dispatch = useDispatch();

  useEffect(()=>{
    setGstinMasterData(props.gstinMasterDataMap);
    if(props.gbsComponentVisible){
      setGstinMap({...props.gstinDetails.gstins});
    }
  }, [props.gbsComponentVisible, props.gstinMasterDataMap, props.gstinDetails]);


  const updateGstinMap = (value) => {
    setGstinMap({...value});
    props.addUpdateGstinDetails(value);
  }

  const updateGstinMasterData = () => {
    let tempMasterData = {...gstinMasterDataMap};
    Object.values(gstinMap).forEach((item)=> {
      delete tempMasterData[item.businessGstinId]
    })
    setGstinMasterData(tempMasterData);
    props.setGstinMasterData(tempMasterData);
  };
  
  const removeGstinFromMasterData = (id) => {
    let gstinMasterData = {...gstinMasterDataMap};
    delete gstinMasterData[id];
    setGstinMasterData(gstinMasterData);
  }

  const gstinActiveStatusChangeHandler = (id, value) => {
        let gstinItems = { ...gstinMap };
        gstinItems[id].active = value;
        updateGstinMap(gstinItems);
  };

  const updateGstinEditMode = (id, value) => {
      let gstinItems = { ...gstinMap };
      gstinItems[id].editMode = value;
      updateGstinMap(gstinItems);
};


  const businessGstinIdChangeHandler = (id,value) => {
      let gstinItems = { ...gstinMap };
      gstinItems[id].businessGstinId = value;
      updateGstinMap(gstinItems);
  }

  const otpVerifiedInputChangeHandler = (id, value) => {
      let gstinItems = { ...gstinMap };
      gstinItems[id].otpVerified = value;
      updateGstinMap(gstinItems);
  };

  const updateSingleGstinDetails = (id, value) => {
    let gstinItems = {...gstinMap}
    gstinItems[id] = {...value}
    updateGstinMap(gstinItems);
  }

  const addNewGstinClickHandler = () => {
      let id = uuidv4();
      let newGstinsMap =  {
        [id]: {
          id,
          createMode: true,
          editMode: true,
          active: true,
          businessGstinId:"",
          gstin: "",
          gstinIsValid: false,
          gstinUsername: "",
          gstinUsernameIsValid: false,
          otpVerified: false,
          otpStatus: GstinOtpRequestStatus.NONE,
        },
        ...gstinMap,
      };
      updateGstinMap(newGstinsMap);
      dispatch(stepperActions.changeAccordianId({value: id}));
      props.updateGstinDetailsVerifiedStatus(false);
      dispatch(stepperActions.setStepCompletedOrNot({step: steps.activeStep, completed: false}));
  };

  const addGstinFromMasterDataHandler = (id, value) => {
    setLoading(true);
    V2BusinessScoreRequestGstinApi.addGstin({
      bsrId: params.bsrId,
      gstin: value.gstin,
      username: value.gstnUsername
    }).then((response)=> {
      let newGstinsMap =  {
        [id]: {
            id,
            createMode: false,
            active: response.active,
            editMode: false,
            businessGstinId:value.businessGstinId,
            gstin: value.gstin,
            gstinIsValid: true,
            gstinUsername: value.gstnUsername,
            gstinUsernameIsValid: true,
            otpVerified: false,
            otpStatus: response.otpStatus,
        },
        ...gstinMap,
      }
      updateGstinMap(newGstinsMap);
      removeGstinFromMasterData(id);
      dispatch(stepperActions.changeAccordianId({value: id}));
      props.updateGstinDetailsVerifiedStatus(false);
      dispatch(stepperActions.setStepCompletedOrNot({step: steps.activeStep, completed: false}));
      setLoading(false);
    }).catch((error)=> {
      dispatch(
        SnackBarActions.openSnackBar({
          message: error.errorMessage,
          messageType: SnackBarMessageType.ERROR
        })
      )
      setLoading(false);
    })
};

  const removeGstinHandler = (id) => {
    let gstinItems = {...gstinMap};
    delete gstinItems[id];
    updateGstinMap(gstinItems);
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let isAllGstinDetailsDisabled = true;

    if (Object.values(gstinMap).length === 0) {
      dispatch(
        SnackBarActions.openSnackBar({
          message: "Atleast one GSTIN must be added",
          messageType: SnackBarMessageType.ERROR,
        })
      );
      return;
    }
    let isFormValid = true;
    Object.values(gstinMap).forEach((item)=>{
      if(item.active){
        isAllGstinDetailsDisabled = false;
        if(item.otpVerified === false){
          isFormValid = false;
        }
      }
    })
    if(isAllGstinDetailsDisabled){
      dispatch(
        SnackBarActions.openSnackBar({
          message: "Atleast one gstin must be enabled",
          messageType: SnackBarMessageType.ERROR
        })
      )
      isFormValid = false;
      return;
    }
    if (!isFormValid) {
      dispatch(
        SnackBarActions.openSnackBar({
          message: "Validate all GSTIN(s) by clicking on GET OTP button",
          messageType: SnackBarMessageType.ERROR,
        })
      );
      return;
    }
    props.updateGstinDetailsVerifiedStatus(true);
    dispatch(stepperActions.setStepCompletedOrNot({step: steps.activeStep, completed: true}));
    dispatch(stepperActions.setStepSkippableOrNot({step: steps.activeStep+1, skippable: true}));
    dispatch(stepperActions.setActiveStep({value: steps.activeStep+1}));
  };
  props.gstinRef.current = onSubmitHandler 



  return (
    <Paper elevation={3}>
      <Container sx={{paddingBottom: 5}}>
      {openModelForAddGstin && <AddGstinBankDetailsComponent
            open={openModelForAddGstin}
            addNewUnit={addNewGstinClickHandler}
            handleClose={setOpenModelForAddGstin}
            addUnitFromMasterData={addGstinFromMasterDataHandler}
            updateMasterData={updateGstinMasterData}
            masterData={gstinMasterDataMap}
            type="gstin"
          />}
        {/* {setGstinMap(gstins.gstinDetails)} */}
        <Grid container padding={2} pt={5} alignItems="center">
          <Grid item xs={6}>
          {currentOrg?.organisation?.id !== undefined && (
          <Typography variant="h6">
            PAN : {pan} <br />
          </Typography>
        )}
          </Grid>
          <Grid item xs={6} textAlign="end">
            <Button 
              id="add-gstin-button" 
              onClick={()=>setOpenModelForAddGstin(true)} 
              variant="outlined"
              color="primary" 
              size="small"
              type="gstin"
              // aria-controls={
              //   openAddGstinDropdown ? "add-gstin-menu" : undefined
              // }
              // aria-haspopup="true"
              // aria-expanded={openAddGstinDropdown ? "true" : undefined}
            >
              Add GSTIN
            </Button>
            {/* <Menu
            id="add-gstin-menu"
            anchorEl={anchorEl}
            open={openAddGstinDropdown}
            fullWidth
            onClose={handleAddGstinBtnClose}
            MenuListProps={{
              "aria-labelledby": "add-gstin-menu",
            }}
          >
            <MenuItem
              // data-report-type={GstReportType.PURCHASE_RELATIONSHIP}
              onClick={addNewGstinClickHandler}
              key="purchase"
            >
              Purchase Report
            </MenuItem>
            <MenuItem
              // data-report-type={GstReportType.SALES_RELATIONSHIP}
              onClick={addNewGstinClickHandler}
              key="sales"
            >
              Sales Report
            </MenuItem>
          </Menu> */}
          </Grid>
        </Grid>
            {/* {currentOrg?.organisation?.id !== undefined && (
          <Typography px={2} pt={5} pb={1} variant="h6">
            Add GSTIN for PAN : {pan} <br />
          </Typography>
        )} */}
          <Typography px={2} fontSize="12px">
            Ensure you have
            <Link
              href="https://services.gst.gov.in/services/login"
              target="blank"
            >
              {" "}
              enabled API access on Government portal
            </Link> for all GSTINs.
          </Typography>

          <Typography px={2} pb={3} fontSize="12px">
          OTP will be sent to the GSTN registered mobile number
          </Typography>

        <form id="gstinForm" noValidate onSubmit={onSubmitHandler}>
          {!loading && <List key="gstins" sx={{padding: 2}}>
            {Object.values(gstinMap).map((item) => {
              return (
                <GBSGstinItemComponent
                  item={item}
                  otpVerifiedInputChangeHandler={otpVerifiedInputChangeHandler}
                  gstinActiveStatusChangeHandler={gstinActiveStatusChangeHandler}
                  removeGstinHandler={removeGstinHandler}
                  updateGstinEditMode={updateGstinEditMode}
                  // getOtpClickHandler={getOtpClickHandler}
                  bsrId={params.bsrId}  
                  key={item.id}
                  pan={pan}
                  businessGstinIdChangeHandler={businessGstinIdChangeHandler}
                  updateSingleGstinDetails={updateSingleGstinDetails}
                />
              );
            })}
            {/* <ListItem key="add_new_item" sx={{marginTop: 3,marginBottom: 3}}>
              <ListItemSecondaryAction>
                <Tooltip title="Add New" arrow>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={addNewClickHandler}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem> */}
          </List>}
          {loading && <CircularProgress size={24} />}
        </form>
      </Container>
    </Paper>
  );
};

export default GBSGstinComponent;
