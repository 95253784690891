import { createSlice } from "@reduxjs/toolkit";

const GstinDetailsSlice = createSlice({
  name: "gstinDetails",
  initialState: {gstinDetails: {}, gstinVerified: false},
  reducers: {
    resetGstinDetails(state, action){
      state.gstinDetails = {};
      state.gstinVerified = false;
    },
    updateGstinVerified(state, action){
      state.gstinVerified = action.payload.value;
    },
    addUpdateGstins(state, action) {
      state.gstinDetails = action.payload;
    },
    gstinChange(state, action) {
      state.gstinDetails[action.payload.id].gstin = action.payload.gstin;
    },
    gstinUsernameChange(state, action) {
      state.gstinDetails[action.payload.id].gstnUsername = action.payload.gstnUsername;
    },
    gstinIsTouchChange(state, action) {
      state.gstinDetails[action.payload.id].gstinIsTouch = action.payload.gstinIsTouch;
    },
    gstinIsValidChange(state, action) {
      state.gstinDetails[action.payload.id].gstinIsValid = action.payload.gstinIsValid;
    },
    usernameIsTouchChange(state, action) {
      state.gstinDetails[action.payload.id].usernameIsTouch = action.payload.usernameIsTouch;
    },
    usernameIsValidChange(state, action) {
      state.gstinDetails[action.payload.id].usernameIsValid = action.payload.usernameIsValid;
    },
    otpVerifiedChange(state, action) {
      state.gstinDetails[action.payload.id].otpVerified = action.payload.otpVerified;
    },
    gstinErrorMsgChange(state, action) {
      state.gstinDetails[action.payload.id].gstinErrMsg = action.payload.gstinErrMsg;
    },
    removeGstin(state, action) {
      delete state.gstinDetails[action.payload.id];
    },
    updateBusinessUnitId(state, action){
      state.gstinDetails[action.payload.id].businessUnitId = action.payload.businessUnitId;
    }
  },
});

export const gstinActions = GstinDetailsSlice.actions;
export default GstinDetailsSlice.reducer;
