import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class StatsBusinessApi {
  static path = "v1/stats-business";

  static getStatsStatusCount = ({ duration, apiClientId }) => {
    let path = StatsBusinessApi.path + "/get";

    let command = {
      filter: {
        apiClientId: apiClientId,
        duration: { type: duration },
      },
    };

    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };
}
