import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Grid } from "@mui/material";

const LandingAppBarComponent = () => {
  return (
    <AppBar
      color="default"
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item>
          <img
            src="/assets/images/powered-by-crif.png"
            alt="gbs logo"
            height={60}
          />
        </Grid>{" "}
        <Grid item>
          <img
            src="/assets/images/GBS-Logo-Unit.png"
            alt="gbs logo"
            height={100}
          />
        </Grid>
      </Grid>
    </AppBar>
  );
};
export default LandingAppBarComponent;
