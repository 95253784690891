import {
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  Box,
  Grid,
  CircularProgress,
  CssBaseline,
} from "@mui/material";

import * as React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import RoutePath from "../../util/RoutePath";
import SystemDefault from "../../util/SystemDefault";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../store/LoaderSlice";
import PasswordResetApi from "../../http/theodore/PasswordResetApi";

const VerifyForgotPasswordOtpComponent = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const password = props.password;
  const emailMobile = props.emailMobile;
  const emailOrMobile =props.emailOrMobile;

  const [otp, setOtp] = useState("");
  const [otpErrMsg, setOtpErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [otpTouch, setOtpTouch] = useState(false);
  const [otpIsValid, setOtpIsValid] = useState(false);

  const inputOtpInvalid = !otpIsValid && otpTouch;
  const [counter, setCounter] = React.useState(90);

  const loading = useSelector((state) => state.loader.value);
  const isDisabled = loading;


  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const otpInputChangeHandler = (event) => {
    setOtp(event.target.value);
    setOtpTouch(true);
    if (event.target.value.trim() === "") {
      setOtpIsValid(false);
      setOtpErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else {
      setOtpIsValid(true);
      setOtpErrMsg("");
    }
  };

  const requestEmailMobileOtpHandler = (event) => {
    loaderActions.startLoader();
    PasswordResetApi.genrateOtp({
      type: emailOrMobile,
      emailMobile: emailMobile,
    })
      .then((response) => {
        loaderActions.stopLoader();
        setCounter(90);
        dispatch(
          SnackBarActions.openSnackBar({
            message:
              emailOrMobile === "email"
                ? "Otp sent on your Email Address successfully"
                : "Otp sent on your Mobile Number successfully",
            messageType: SnackBarMessageType.SUCCESS,
          })
        );
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        dispatch(loaderActions.stopLoader());
      });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    setOtpTouch(true);

    if (!otpIsValid) {
      return;
    }
    dispatch(loaderActions.startLoader());

    if (emailOrMobile === "email") {
      PasswordResetApi.setWithEmailOtp({
        email: emailMobile,
        otpString: otp,
        newPassword: password,
      })
        .then((response) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: "password reset successfully",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
          setTimeout(function () {
            history.push(RoutePath.APP_LOGIN);
          }, 1500);
          dispatch(loaderActions.stopLoader());
        })
        .catch((reject) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: reject.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
        });
    } else {
      PasswordResetApi.setWithMobileOtp({
        mobile: emailMobile,
        otpString: otp,
        newPassword: password,
      })
        .then((response) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: "password reset successfully",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
          setTimeout(function () {
            history.push(RoutePath.APP_LOGIN);
          }, 1500);
          dispatch(loaderActions.stopLoader());
        })
        .catch((reject) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: reject.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
        });
    }
  };

  return (
    <Paper elevation={3}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          pb={4}
          pt={5}
          sx={{
            marginTop: 18,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img height={40} src="/assets/images/vay.png" alt="mobile" />
          <br />

          <Typography component="h1" variant="h5" marginBottom={4}>
            Forgot Password
          </Typography>

          <form onSubmit={onSubmitHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name="emailMobile"
                  required
                  fullWidth
                  id="emailMobile"
                  label="Email or Mobile"
                  defaultValue={emailMobile}
                  autoFocus
                  disabled
                />{" "}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item> </Grid>
                  <Grid item>
                    {counter === 0 ? (
                      <Link
                        to={"#"}
                        onClick={requestEmailMobileOtpHandler}
                        variant="body2"
                      >
                        Resend OTP
                      </Link>
                    ) : (
                      counter
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={inputOtpInvalid}
                  helperText={inputOtpInvalid && otpErrMsg}
                  onChange={otpInputChangeHandler}
                  name="OTP"
                  required
                  fullWidth
                  id="OTP"
                  label="OTP"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isDisabled}
                >
                  Submit{" "}
                  {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
                </Button>
              </Grid>
            </Grid>
            {/* <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to={RoutePath.APP_LOGIN} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid> */}
          </form>
        </Box>
      </Container>
    </Paper>
  );
};

export default VerifyForgotPasswordOtpComponent;
