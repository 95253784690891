import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class StatsBusinessScoreRequestApi {
  static path = "v1/stats-business-score-request";

  static getStatsStatusCount = ({ filter, groupBy }) => {
    let path = StatsBusinessScoreRequestApi.path + "/get";

    let command = {
      filter: {
        apiClientId: filter?.apiClientId,
        duration: { type: filter?.duration },
        generatedBy: "USER",
        statsStatus: filter?.statsStatus
      },
      groupBy: { statsStatus: true, scoreCategory: groupBy?.scoreCategory },
    };

    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static getYearlyStatsData = ({ duration, apiClientId }) => {
    let path = StatsBusinessScoreRequestApi.path + "/get-yearly-stats";

    let command = {
      filter: {
        apiClientId: apiClientId,
        duration: { type: duration },
        generatedBy: "USER",
      },
      groupBy: { statsStatus: true, year: true },
    };

    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static getMonthlyStatsData = ({ duration, apiClientId }) => {
    let path = StatsBusinessScoreRequestApi.path + "/get-monthly-stats";

    let command = {
      filter: {
        apiClientId: apiClientId,
        duration: { type: duration },
        generatedBy: "USER",
      },
      groupBy: { statsStatus: true, year: true, month: true },
    };

    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };
}
