import axios from "axios";
import StatusCodes from "http-status-codes";
import AppUtil, { refreshJwtToken } from "./AppUtil";
import { HttpMethodType } from "./HttpMethodType";
import RoutePath from "./RoutePath";
import { createBrowserHistory } from "history";
import { CallType } from "./Constant";

export default class ShuriRESTUtil {
  static axiosInstance = axios.create({
    baseURL: AppUtil.getShuriApiBaseUrl(),
  });
  static callApi = ({
    path,
    params = {},
    httpMethodType,
    command,
    callType = CallType.ON_BEHALF_OF_REFERRED_ORG,
    forOrgId = null,
    isDownloadRequest = false,
  }) => {
    this.axiosInstance.interceptors.request.use(refreshJwtToken);
    return ShuriRESTUtil.callScopedAPI({
      path,
      params,
      httpMethodType,
      command,
      isDownloadRequest,
      callType,
      forOrgId,
    });
  };
  static callScopedAPI = ({
    path,
    params = {},
    httpMethodType,
    command,
    isDownloadRequest = false,
    callType,
    forOrgId,
  }) =>
    new Promise((resolve, reject) => {
      let request = {};
      // attach command
      if (command) {
        request = command;
      }
      const requestHeaders = {};
      let orgId = AppUtil.getCurrentOrgId();
      let jwtToken = AppUtil.getJwtToken();
      if (orgId !== undefined && orgId !== null) {
        requestHeaders["org-id"] = orgId;
      }
      if (jwtToken !== undefined && jwtToken !== null) {
        requestHeaders["jwt-token"] = jwtToken;
      }
      requestHeaders["source-token"] = AppUtil.getGbsAppSourceToken();

      requestHeaders["call-type"] = callType;
      if (forOrgId !== null && forOrgId !== undefined) {
        requestHeaders["for-org-id"] = forOrgId;
      }
      // create post url
      const url = `/${path}`;
      // make request
      ShuriRESTUtil.requestExecutor({
        url,
        request,
        headers: requestHeaders,
        httpMethodType,
        params,
        isDownloadRequest,
      }).then((response) => {
        const { status, data } = response;
        switch (status) {
          case StatusCodes.OK:
            if (isDownloadRequest === true) {
              resolve(data);
            } else {
              if (!data["error"]) {
                resolve(data);
              } else if (response.data?.error) {
                if (Object.values(response.data["error"].args).length === 0) {
                  reject({
                    errorMessage: response.data["error"].message,
                    errorData: response.data["error"].args,
                  });
                } else {
                  reject({
                    errorMessage: response.data["error"].args.message,
                    errorData: response.data["error"].args,
                  });
                }
              }
            }

            break;

          case StatusCodes.UNAUTHORIZED:
            AppUtil.removeJwtToken();
            createBrowserHistory().push(RoutePath.APP_LOGIN);
            window.location.reload();
            if (data) {
              reject({ error: "UNAUTHORIZED" });
            }
            break;

          case StatusCodes.SERVICE_UNAVAILABLE:
            if (data) {
              reject({ error: "SERVICE_UNAVAILABLE" });

              //  todo: handle additional error here
            }
            // TODO: 404 page
            break;

          case StatusCodes.INTERNAL_SERVER_ERROR:
            if (Object.values(response.data["error"].args).length === 0) {
              reject({
                errorMessage: response.data["error"].message,
                errorData: response.data["error"].args,
              });
            } else {
              reject({
                errorMessage: response.data["error"].args.message,
                errorData: response.data["error"].args,
              });
            }
            break;

          case StatusCodes.FORBIDDEN:
            if (Object.values(response.data["error"].args).length === 0) {
              reject({
                errorMessage: response.data["error"].message,
                errorData: response.data["error"].args,
              });
            } else {
              reject({
                errorMessage: response.data["error"].args.message,
                errorData: response.data["error"].args,
              });
            }
            break;
          case StatusCodes.BAD_REQUEST:
            if (Object.values(response.data).length === 0) {
              reject({
                errorMessage: "Unable to decode error",
              });
            } else {
              reject({
                errorMessage: response.data["errorMessage"],
                errorData: response.data["errorData"],
              });
            }
            break;
          default:
            reject("errorCode", "apiError.errorMessage", "apiError.errorData");
            break;
        }
      });
    });

  static requestExecutor = ({
    url,
    request,
    headers,
    httpMethodType,
    params,
    isDownloadRequest,
  }) => {
    switch (httpMethodType) {
      case HttpMethodType.GET:
        if (isDownloadRequest) {
          return ShuriRESTUtil.axiosInstance.get(url, {
            headers,
            validateStatus: () => true,
            params,
            responseType: "blob",
          });
        } else {
          return ShuriRESTUtil.axiosInstance.get(url, {
            headers,
            validateStatus: () => true,
            params,
          });
        }

      case HttpMethodType.POST:
        return ShuriRESTUtil.axiosInstance.post(url, request, {
          headers,
          validateStatus: () => true,
        });
      case HttpMethodType.PUT:
        return ShuriRESTUtil.axiosInstance.put(url, request, {
          headers,
          validateStatus: () => true,
        });
      case HttpMethodType.DELETE:
        return ShuriRESTUtil.axiosInstance.delete(url, {
          headers,
          validateStatus: () => true,
        });
      case HttpMethodType.PATCH:
        return ShuriRESTUtil.axiosInstance.patch(url, request, {
          headers,
          validateStatus: () => true,
        });
      default:
        break;
    }
    return false;
  };
}
