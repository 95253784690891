import { createSlice } from "@reduxjs/toolkit";

const industrySectorSlice = createSlice({
  name: "industrySector",
  initialState: { itemList: [] },
  reducers: {
    addUpdateIndustrySector(state, action) {
      state.itemList = Object.keys(action.payload.data).map((key) => {
        return { id: Number(key), name: action.payload.data[key] };
      });
    },
  },
});

export const industrySectorActions = industrySectorSlice.actions;
export default industrySectorSlice.reducer;
