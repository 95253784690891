export default class CaptchaUtil {
    static generateCaptcha() {
        const mathSigns = ["+","-","x"];
        let number1 = Math.floor(Math.random() * 9)+1;
        let number2 = Math.floor(Math.random() * 9)+1;
        let operationSign = mathSigns[Math.floor(Math.random()*mathSigns.length)];
        let answer;

        let number1Img = `/assets/images/captchas/${number1}.png`;
        let number2Img = `/assets/images/captchas/${number2}.png`;

        switch(operationSign){
            case "+":
                answer = number1 + number2;
                break;

            case "-":
                answer = number1 - number2;
                break;

            case "x":
                answer = number1 * number2;
                break;

            default:
                answer = 0;
                break;
        }
        
        let captchaObject = {
            "number1": number1,
            "number2": number2,
            "operationSign": operationSign,
            "number1Img": number1Img,
            "number2Img": number2Img,
            "answer": answer
        }
      return captchaObject;
    }
  }
  