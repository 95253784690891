import * as React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";

import ReactPlayer from "react-player/youtube";
import CloseIcon from "@mui/icons-material/Close";

const GBSRightSideBarComponent = (props) => {
  const drawerWidth = 300;
  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Box py={10}>
        <Tooltip title="Close" arrow>
          <IconButton onClick={props.closeDrawerHandler}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Divider />
        <Box px={3} py={5}>
          <Typography>
            Before you proceed, do make sure you have
            <Link
              href="https://services.gst.gov.in/services/login"
              target="blank"
            >
              {" "}
              enabled API access on Government portal.
            </Link>
          </Typography>
          <Box my={10}>
            <Typography>For more details please refer this video</Typography>{" "}
            <ReactPlayer
              url="https://www.youtube.com/watch?v=W6HLhP2Yyec"
              width="200"
              height="100"
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
export default GBSRightSideBarComponent;
