import { createSlice } from "@reduxjs/toolkit";
import AppUtil from "../util/AppUtil";

const CurrentOrgSlice = createSlice({
  name: "currentOrg",
  initialState: { organisation: null, userAccessInfo: null },
  reducers: {
    setCurrentOrg(state, action) {
      state.organisation = action.payload.organisation;
      state.userAccessInfo = action.payload.userAccessInfo;
      AppUtil.setCurrentOrgId(action.payload.organisation.id);
    },
    removeCurrentOrg(state) {
      state.organisation = null;
      state.userAccessInfo = null;
      AppUtil.removeCurrentOrgId();
    },
  },
});

export const currentOrgActions = CurrentOrgSlice.actions;
export default CurrentOrgSlice.reducer;
