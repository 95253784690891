import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";
import TheodoreApiRESTUtil from "../../util/TheodoreRESTUtil";

export default class BusinessApi {
  static path = "v1/business";

  static createBusiness = ({ name, pan }) => {
    let path = "organisations";
    const command = {
      name:name,
      taxIdentifier:pan,
      taxIdentifierType:"PAN",
      country:"India",
      services: [],
      adminEmail: null,
      counterParty: null,
      timezone: null,
      locale: null,
    };
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static get = ({ id }) => {
    let path = BusinessApi.path + "/" + id;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static isTaxIdentifierExist = ({ pan }) => {
    let path = BusinessApi.path + "/is-exist/tax-identifier/"+pan+"/tax-identifier-type/PAN/country/India";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static updateFinancialInstitute = ({ id, isFinancialInstitute }) => {
    let path = BusinessApi.path + "/financial-institute";
    const command = {
      id,
      isFinancialInstitute,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PUT,
      command: command,
    });
  };

  static updateIndustrySector = ({ id, industrySectorId }) => {
    let path = BusinessApi.path+"/industry-sector";
    const command = {
      id,
      industrySectorId,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PUT,
      command: command,
    });
  };

  static search = ({ searchMode, page, records, filter, select, sort }) => {
    let path = BusinessApi.path + "/search";
    let command = {
      searchMode,
      page,
      records,
    };
    if (filter && Object.keys(filter).length > 0) {
      command.filter = filter;
    }
    if (select && Object.keys(select).length > 0) {
      command.select = select;
    }
    if (sort && Object.keys(sort).length > 0) {
      command.sort = sort;
    }
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };
}
