import React from "react";
import { Grid, Typography } from "@mui/material";

const CloseTabMobileAppPage = () => {
  return (
    <Grid container direction="column" alignItems="center" my={10}>
      <Grid item>
        <img height={40} src="/assets/images/vay.png" alt="vayana logo" />
      </Grid>
      <Grid item my={10} mx={5}>
        <Typography variant="h6" fontWeight={900}>
          User Created Successfully...
        </Typography>
        <Typography>
          Kindly close this window and continue with Vardhana
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CloseTabMobileAppPage;
