import {
    Tooltip,
    Typography, Box,
    Grid,
    Card, CardContent
} from "@mui/material";
import React from "react";
import { BankAccountType, BankAccountTypeByValue } from "../../../util/Constant";
import PropTypes from 'prop-types';


const GBSViewDetailBankAccountComponent = (props) => {
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };



    return (
        <React.Fragment>
            <Grid container spacing={2} direction="row" >
                {Object.values(props.banks).map((bank) => {
                    return (<Grid item xl={4} md={12} sm={12} xs={12} key={bank.id}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item container>
                                        <Grid item sx={{ width: 180 }} color="text.secondary">
                                            Bank Name :
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title={<div>{bank.bankName}</div>} arrow>
                                                <Typography sx={{ maxWidth: 220 }} noWrap>{bank.bankName}</Typography>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid item container>
                                        <Grid item sx={{ width: 180 }} color="text.secondary">
                                            A/C No :
                                        </Grid>
                                        <Grid item>
                                            {bank.accountNumber}
                                        </Grid>
                                    </Grid>
                                    <Grid item container>
                                        <Grid item sx={{ width: 180 }} color="text.secondary">
                                            A/C Type :
                                        </Grid>
                                        <Grid item>
                                            {BankAccountTypeByValue(bank.accountType).account}
                                        </Grid>
                                    </Grid>
                                    <Grid item container>
                                        <Grid item sx={{ width: 180 }} color="text.secondary">
                                            A/C Holder Name :
                                        </Grid>
                                        <Grid item>
                                            {bank.bankAccountHolderName}
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="column" spacing={2} sx={{ display: ((bank.accountType === BankAccountType.CC || bank.accountType === BankAccountType.OD) ? "inline" : "none") }}>
                                        <Grid item container>
                                            <Grid item sx={{ width: 180 }} color="text.secondary">
                                                Sanction Limit :
                                            </Grid>
                                            <Grid item>
                                                {bank.sanctionLimit}
                                            </Grid>
                                        </Grid>
                                        {bank.monthlyDrawingPowerLimit.length > 0 && <Grid item textAlign="center" color="text.secondary">
                                            <Typography>Monthly Drawing Power Limits</Typography>
                                        </Grid>}
                                        <Grid item container spacing={2}>
                                            {bank.monthlyDrawingPowerLimit.map((powerLimit) => {
                                                return (
                                                    <Grid item container spacing={2} key={powerLimit.from}>
                                                        <Grid item>
                                                            <Typography color="text.secondary">YYYYMM : </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>{powerLimit.from}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography color="text.secondary">Limit : </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            {powerLimit.limit}
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    )
                })}
            </Grid>

        </React.Fragment>
    );
};

export default GBSViewDetailBankAccountComponent;
