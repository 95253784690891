import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import AppRoute from "./pages/AppRoute";
import RoutePath from "./util/RoutePath";
import SignInPage from "./pages/user/LoginPage";
import SignUpPage from "./pages/user/SingupPage";
import ForgotPasswordPage from "./pages/user/ForgotPasswordPage";
import SnackbarComponent from "./component/SnackBarComponent";
import LandingPage from "./pages/LandingPage";
import NotFoundPage from "./pages/NotFoundPage";
import DisableAccountInfoPage from "./pages/DisableAccountInfoPage";
// import CookieUtil from "./util/CookieUtil";
import SignupPageMobileApp from "./pages/user/SingupPageMobileApp";
import CloseTabMobileAppPage from "./pages/CloseTabMobuleAppPage";

function App() {
  // React.useEffect(() => {
  //   CookieUtil.removeAll()
  // },[]);
    
  return (
    <React.Fragment>
      <SnackbarComponent />
      <Switch>
        <Route path={RoutePath.APP_BASE} exact>
          <LandingPage />
        </Route>
        <Route path={RoutePath.DISABLE_ACCOUNT} exact>
          <DisableAccountInfoPage />
        </Route>
        <Route path={RoutePath.APP_LOGIN} exact>
          <SignInPage />
        </Route>
        <Route path={RoutePath.APP_CLOSE_TAB_MOBILE_APP} exact>
          <CloseTabMobileAppPage />
        </Route>
        <Route path={RoutePath.APP_SIGNUP_MOBILE_APP} exact>
          <SignupPageMobileApp />
        </Route>
        <Route path={RoutePath.APP_SIGNUP} exact>
          <SignUpPage />
        </Route>
        <Route path={RoutePath.APP_FORGOT_PASS} exact>
          <ForgotPasswordPage />
        </Route>
        <Route path={RoutePath.APP_BASE}>
          <AppRoute />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
