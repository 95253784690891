import { createSlice } from "@reduxjs/toolkit";

const bankNameSlice = createSlice({
  name: "bankName",
  initialState: { itemList: [] },
  reducers: {
    addUpdateBankName(state, action) {
      state.itemList = action.payload;
    },
  },
});

export const bankNameActions = bankNameSlice.actions;
export default bankNameSlice.reducer;
