import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { BankAccountTypeByValue } from "../../util/Constant";

const AddGstinBankDetailsComponent = (props) => {

  useEffect(()=> {
    props.updateMasterData()
  },[props])

  const addNewUnitHandler = () => {
    props.addNewUnit();
    props.handleClose(false);
  }

  const addUnitFromMasterDataHandler = (id, value) => {
    props.addUnitFromMasterData(id, value);
    props.handleClose(false);
  }

  const renderGstins = () => {
    return (
      <React.Fragment>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {Object.values(props.masterData).map((item) => {
            return (<ListItem key={item.gstin} alignItems="flex-start">
              <ListItemButton onClick={()=> addUnitFromMasterDataHandler(item.id, item)}>
                <ListItemAvatar>
                            <Avatar variant={"square"}>
                            {item.gstin.charAt(0).toUpperCase()}
                            </Avatar>
                        </ListItemAvatar>
                    
                <ListItemText primary={item.gstin} />
                {/* <Typography>{item.gstin}</Typography> */}
              </ListItemButton>
            </ListItem>);
        })}
        </List>

      </React.Fragment>
    )
  }

  const renderBankAccounts = () => {
    return (
      <React.Fragment>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {Object.values(props.masterData).map((item) => {
            return (<ListItem key={item.id} alignItems="flex-start">
              <ListItemButton onClick={()=> addUnitFromMasterDataHandler(item.id, item)} justifyContent="center" alignItems="center">
                <ListItemAvatar>
                  <Avatar variant={"square"}>
                    {item.bankName.charAt(0).toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <Grid container direction="column" mt={1}>
                  <Grid item>
                    <Typography>{item.bankName}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="overline">{item.number}&nbsp;&nbsp; |&nbsp;&nbsp; {BankAccountTypeByValue(item.type).account}</Typography>
                  </Grid>
                </Grid>
              </ListItemButton>
            </ListItem>);
        })}
          </List>
      </React.Fragment>
    )
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '100%',
          maxHeight: 360,
        },
      }}
      open={props.open}
      onClose={() => props.handleClose(false)}
    // aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="edit-bank-details-title">  Select / Add {props.type === "bank" ? "Bank Account" : "GSTIN"}</DialogTitle>
      <DialogContent style={{ padding: 8, alignItems: "center" }}>

        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem key="addNewUnit" alignItems="flex-start">
            <ListItemButton onClick={addNewUnitHandler}>
              <ListItemAvatar>
                <Avatar variant={"square"}>
                  +
                </Avatar>
              </ListItemAvatar>
              Add New {props.type === "bank" ? "Bank Account" : "GSTIN"}
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Divider style={{padding: 10}}>OR</Divider> */}
        {props.type === "bank" ? renderBankAccounts() : renderGstins()}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose(false)} color="primary">
          Cancel
        </Button>
        {/* <Button color="primary" onClick={onSubmitHandler}>
            {loading && <CircularProgress sx={{ mx: 2 }} size={25} />}
            {!loading && "Save"}
          </Button> */}
      </DialogActions>
    </Dialog>
  );
};



export default AddGstinBankDetailsComponent;
