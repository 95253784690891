import {
  ChartsXAxis, LineChart
} from "@mui/x-charts";
import React, { useEffect } from "react";
import StatsBusinessScoreRequestApi from "../../http/v1_apis/StatsBusinessScoreRequestApi";
import { CircularProgress, Typography } from "@mui/material";
import NoDataFoundComponent from "../common/NoDataFoundComponent";

const GbsMonthlyChartComponent = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [chartXLabel, setChartXLabel] = React.useState([]);
  const [chartSuccessCount, setChartSuccessCount] = React.useState([]);
  const [chartErrorCount, setChartErrorCount] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    setChartXLabel([]);
    setChartSuccessCount([]);
    setChartErrorCount([]);

    StatsBusinessScoreRequestApi.getMonthlyStatsData({
      duration: props.duration,
      apiClientId: props.apiClientId,
    }).then((response) => {
      var lbl = [];
      var sus = [];
      var err = [];
      response.forEach((res) => {
        lbl.push(res["id"]);
        sus.push(res["successCount"]);
        err.push(res["errorCount"]);
      });
      setChartXLabel(lbl);
      setChartSuccessCount(sus);
      setChartErrorCount(err);
      setLoading(false);
    });
  }, [props.apiClientId, props.duration]);

  let myComponent;

  if (chartXLabel.length > 0) {
    myComponent = (
      <div>
        <LineChart
          xAxis={[
            {
              id: "year-month",
              scaleType: "band",
              data: chartXLabel,
              valueFormatter: (value) => value.toString(),
            },
          ]}
          series={[
            {
              curve: "linear",
              data: chartSuccessCount,
              label: "success",
              color: "#55f74c",
            },
            {
              curve: "linear",
              data: chartErrorCount,
              label: "error",
              color: "#f28e8e",
            },
          ]}
          width={1400}
          height={600}
        >
          {/* <BarPlot /> */}
          {/* <LinePlot /> */}
          <ChartsXAxis
            label="Years-Month"
            position="bottom"
            axisId="year-month"
            sx={{ textOrientation: "upright", writingMode: "vertical" }}
          />
          {/* <ChartsYAxis label="Results" position="left" /> */}
        </LineChart>
      </div>
    );
  } else {
    myComponent = <NoDataFoundComponent />;
  }
  return (
    <div>
      <Typography variant="h4">Monthly GBS Request Status</Typography>

      {loading && <CircularProgress size={24} />}
      {!loading && myComponent}
    </div>
  );
};
export default GbsMonthlyChartComponent;
