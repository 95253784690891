import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Link from "@mui/material/Link";
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));

const BasicPopover=(props)=> {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>

            <Link
                sx={{ textDecoration: 'none' }}
                component="button"
                variant="body2"
                onClick={handleClick}
                onMouseEnter={handleClick}
                onMouseLeave={handleClose}
            >
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <WarningOutlinedIcon sx={{color: "#efa512"}}/>
                    <span>more info</span>
                </div>
            </Link>
            <Popover
                PaperProps={{
                    style: { width: 300},
                }}
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent,
                }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
            <Typography sx={{ p: 2}}>Message</Typography>
            <Divider/>
            <Typography sx={{ p: 2,wordWrap: 'break-word'}}>
                <Alert severity={"warning"}>{props.content?props.content:"-"}</Alert></Typography>
            </Popover>
        </div>
    );
}

export default BasicPopover;