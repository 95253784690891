import { Refresh } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { GstReportType } from "../../util/Constant";

export const ReportFilter = (props) => {
  const [disableRefresh, setDisableRefresh] = React.useState(false);
  const [counter, setCounter] = React.useState(0);

  const fetchReportData = props.fetchReportData;
  const setFilterReportType = props.setFilterReportType;
  const filterReportType = props.filterReportType;

  const searchButtonHandler = () => {
    fetchReportData();
  };

  const handleRefreshClick = () => {
    setDisableRefresh(true);
    setCounter(30);
    const interval = setInterval(() => {
      setCounter((count) => count - 1);
    }, 1000);
    setTimeout(() => {
      setDisableRefresh(false);
      clearInterval(interval);
    }, [30000]);
    fetchReportData();
  };

  const handleFilterReportTypeChange = (event) => {
    setFilterReportType(event.target.value);
  };
  return (
    <Grid container direction="row" sx={{ padding: "20px 16px" }}>
      <Grid item xs={3}>
        <Typography variant="h6" color="gray">
          Search Filters
        </Typography>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="right"
        alignItems="center"
        columnGap={5}
        xs={9}
        item
        sx={{ paddingRight: "32px" }}
      >
        <Grid item sx={{ paddingRight: "40px" }}>
          <FormControl size="small">
            <InputLabel id="report-type-label">Report Type </InputLabel>
            <Select
              value={filterReportType}
              label="Report Type *"
              style={{ minWidth: 250, fontSize: 14 }}
              fullWidth
              labelId="report-type-label"
              onChange={handleFilterReportTypeChange}
            >
              <MenuItem value={"ALL"}>ALL</MenuItem>
              <MenuItem value={GstReportType.PURCHASE_RELATIONSHIP}>
                Purchase Relationship
              </MenuItem>
              <MenuItem value={GstReportType.SALES_RELATIONSHIP}>
                Sales Relationship
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={searchButtonHandler}>
            Search
          </Button>
        </Grid>
        <Grid item>
          <IconButton
            disabled={disableRefresh}
            sx={{ cursor: disableRefresh ? "not-allowed" : "pointer" }}
            onClick={handleRefreshClick}
          >
            {counter === 0 ? (
              <Refresh color="primary" />
            ) : (
              <Typography>{counter} s</Typography>
            )}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
