import React from "react";
import {
  Paper,
} from "@mui/material";
import ReportCreateComponent from '../../component/report/ReportCreateComponent'


const ReportCreatePage = () => {

  return (
    <div>
      <Paper
        elevation={1}
        sx={{
          margin: "auto",
          width: "40%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ReportCreateComponent />
      </Paper>
    </div>
  );
};

export default ReportCreatePage;
