import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import { currentOrgActions } from "../store/CurrentOrgSlice";
import RoutePath from "../util/RoutePath";
import { useHistory } from "react-router-dom";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ArticleIcon from "@mui/icons-material/Article";
import BusinessApi from "../http/v1_apis/BusinessApi";
import AppUtil from "../util/AppUtil";
import { gstinActions } from "../store/GstinDetailsSlice";
import { bankDetailsActions } from "../store/BankDetailsSlice";
import AssessmentIcon from "@mui/icons-material/Assessment";

const AppSideBarComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [openBorrower, setOpenBorrower] = useState(false);
  const myOrgList = useSelector((state) => state.myOrg);
  const myCurrentOrg = useSelector((state) => state.currentOrg);
  const [orgBusiness, setOrgBusiness] = useState({});
  const drawerWidth = 300;

  const openHandler = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    BusinessApi.get({
      id: myCurrentOrg?.organisation?.id,
    }).then((response) => {
      setOrgBusiness(response);
    });
  }, [myCurrentOrg]);

  const onClickHandler = (id) => {
    dispatch(gstinActions.resetGstinDetails());
    dispatch(bankDetailsActions.resetBankDetails());
    // eslint-disable-next-line array-callback-return
    myOrgList.map((item) => {
      if (item.organisation.id === id) {
        dispatch(currentOrgActions.setCurrentOrg(item));
      }
    });
    if (id === AppUtil.getPlatformOrgId()) {
      history.push(RoutePath.routeAppPlatformBusinessListing({ orgId: id }));
    } else {
      history.push(RoutePath.routeAppOrgDashboard({ orgId: id }));
    }

    // history.push(RoutePath.APP_ORG + "/" + id + "/dashboard");
  };

  const dashboardHandler = () => {
    dispatch(gstinActions.resetGstinDetails());
    dispatch(bankDetailsActions.resetBankDetails());
    history.push(
      RoutePath.APP_ORG + "/" + myCurrentOrg.organisation.id + "/dashboard"
    );
  };

  const borrowerOpenHandler = () => {
    setOpenBorrower(!openBorrower);
  };
  const borrowerHandler = () => {
    history.push(
      RoutePath.routeBorrowerListingPage({
        orgId: myCurrentOrg?.organisation?.id,
      })
    );
  };

  const previousUploadHandler = () => {
    history.push(
      RoutePath.routeBorrowerFileListingPage({
        orgId: myCurrentOrg?.organisation?.id,
      })
    );
  };

  const reportsClickHandler = (event) => {
    history.push(
      RoutePath.routeAppOrgGstReportsListingPage({
        orgId: myCurrentOrg?.organisation.id,
      })
    );
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          <ListItem button onClick={dashboardHandler} key="dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>
        <Divider />
        {orgBusiness?.isFinancialInstitute ? (
          <div>
            <List>
              <ListItem button onClick={borrowerOpenHandler} key="borower">
                <ListItemIcon>
                  <GroupAddIcon />
                </ListItemIcon>
                <ListItemText primary="Referred-Org" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openBorrower} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={borrowerHandler}
                    sx={{ pl: 4 }}
                    key={"add_borrower"}
                  >
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Overview"} />
                  </ListItem>
                  <ListItem
                    onClick={previousUploadHandler}
                    button
                    sx={{ pl: 4 }}
                    key={"previous_upload"}
                  >
                    <ListItemIcon>
                      <ArticleIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Previous Uploads"} />
                  </ListItem>
                </List>
              </Collapse>
            </List>
            <Divider />
            <List>
              <ListItem button onClick={reportsClickHandler} key="dashboard">
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Report" />
              </ListItem>
            </List>
            <Divider />
          </div>
        ) : null}

        <List>
          <ListItem button onClick={openHandler} key="switch-org">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Switch Organisation" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {myOrgList.length > 0 &&
                myOrgList.map((item) => {
                  return (
                    <ListItem
                      button
                      onClick={onClickHandler.bind(this, item.organisation?.id)}
                      sx={{ pl: 4 }}
                      key={item.organisation?.id}
                    >
                      <ListItemIcon>
                        {item.organisation?.id ===
                          AppUtil.getPlatformOrgId() && (
                          <img
                            src="/assets/images/GBS-Logo-Unit.png"
                            alt="mobile"
                            width="50"
                            height="50"
                          />
                        )}
                        {item.organisation?.id !==
                          AppUtil.getPlatformOrgId() && (
                          <Avatar>
                            {item.organisation?.name.charAt(0).toUpperCase()}
                          </Avatar>

                          // <BusinessIcon sx={{ fontSize: 200 }} />
                        )}
                      </ListItemIcon>
                      {/* <ListItemText primary={item.organisation.name} /> */}
                      {item.organisation?.id === AppUtil.getPlatformOrgId() && (
                        <ListItemText primary="PLATFORM" />
                      )}
                      {item.organisation?.id !== AppUtil.getPlatformOrgId() && (
                        <ListItemText primary={item.organisation.name} />
                      )}
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
        </List>
      </Box>
    </Drawer>
  );
};
export default AppSideBarComponent;
