import * as React from "react";
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useParams } from "react-router-dom";
import BusinessApi from "../../http/v1_apis/BusinessApi";
import OrgApi from "../../http/theodore/OrgApi";
import { useSelector } from "react-redux";

const BusinessDetailComponent = () => {
  const [loading, setLoading] = React.useState(false);
  const [business, setBusiness] = React.useState({isFinancialInstitute: false});
  const [organisation, setOrganisation] = React.useState({});

  const parmas = useParams();
  React.useEffect(() => {
    setLoading(true);
    BusinessApi.get({ id: parmas.businessId }).then((response) => {
      setBusiness(response);
      OrgApi.get({ id: response.id }).then((orgResponse) => {
        setOrganisation(orgResponse.data);
      });
    });
    setLoading(false);
  }, [parmas.businessId]);

  const industrySectorList = useSelector(
    (state) => state.industrySector.itemList
  );

  const getIndustrySector = ({ industrySectorId }) => {
    let industrySectorName = "";
    industrySectorList.forEach((industrySector) => {
      if (industrySector.id === industrySectorId) {
        industrySectorName = industrySector.name;
      }
    });
    return industrySectorName;
  };

  const isFIChangeHandler = (event) => {
    BusinessApi.updateFinancialInstitute({
      id: business.id,
      isFinancialInstitute: event.target.checked,
    }).then((respose) => {
      let newBusiness = business;
      newBusiness.isFinancialInstitute = event.target.checked;
      // setBusiness({});
      setBusiness(newBusiness);
    });
  };

  return (
    <Paper elevation={3}>
      {loading && <CircularProgress />}
      <Grid container>
        <Grid item>
          <Avatar
            variant={"square"}
            sx={{
              background: "",
              width: 220,
              height: 220,
              fontSize: 190,
              fontWeight: "bold",
            }}
          >
            N
          </Avatar>
        </Grid>
        <Grid item pl={3} pt={1}>
          <Typography variant="h4">{organisation.name}</Typography>
          <Typography variant="h6">PAN : {business.taxIdentifier}</Typography>
          <Typography variant="h6">
            Legal Name : {business.legalName}
          </Typography>
          <Typography variant="h6">
            Industry Sector :{" "}
            {getIndustrySector({ industrySectorId: business.industrySectorId })}
          </Typography>
          <FormGroup>
            <FormControlLabel
              disabled={true}
              control={
                <Switch
                  checked={business.isFinancialInstitute}
                  onChange={isFIChangeHandler}
                />
              }
              label="Financial Institute"
              // sx={{ textDecoration: "line-through" }}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BusinessDetailComponent;
