import { Card, Grid, Typography } from "@mui/material";
import React from "react";

const TitleBarComponent = (props) => {
  const title = props.title;
  const rightComponent = props.rightComponent;
  return (
    <Card>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        px={5}
        py={2}
      >
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item>{rightComponent && rightComponent}</Grid>
      </Grid>
    </Card>
  );
};
export default TitleBarComponent;
