import { Box, Typography } from "@mui/material";
import React from "react";

const ProfileCardDetailsComponent = ({ dataKey, dataValue }) => {
  let list = false;
  if (typeof dataValue !== String) {
    list = true;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      <Typography
        mr={2}
        padding={1}
        sx={{
          opacity: "50%",
          minWidth: "24%",
          textTransform: "capitalize",
        }}
      >
        {dataKey}
      </Typography>
      <Typography
        pr={list ? 4 : 0}
        sx={{
          alignSelf: "center",
          overflowX: "hidden",
          maxWidth: "80%",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {dataValue}
      </Typography>
    </Box>
  );
};

export default ProfileCardDetailsComponent;
