import { industrySectorActions } from "./IndustrySectorSlice";
import { appBarActions } from "./AppBarSlice";
import { bankNameActions } from "./BankNameSlice";
import { loaderActions } from "./LoaderSlice";
import { jwtTokenActions } from "./JwtTokenSlice";
import { currentOrgActions } from "./CurrentOrgSlice";
import { currentUserInfoActions } from "./CurrentUserInfoSlice";
import { myOrgActions } from "./MyOrgSlice";
import { SnackBarActions } from "./SnackBarSlice";
import { stepperActions } from "./StepperSlice";

export const cleanupReduxSlice = (dispatch) => {
  dispatch(industrySectorActions.addUpdateIndustrySector({data: []}));
  dispatch(bankNameActions.addUpdateBankName([]));
  dispatch(loaderActions.stopLoader());
  dispatch(jwtTokenActions.removeJwtToken());
  dispatch(currentOrgActions.removeCurrentOrg());
  dispatch(currentUserInfoActions.removeCurrentUserInfo());
  dispatch(myOrgActions.removeMyOrg());
  dispatch(appBarActions.removeAppBarTitle());
  dispatch(SnackBarActions.clearSnackBar());
  dispatch(stepperActions.resetSteps());
};
