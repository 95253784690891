import ShuriRESTUtil from "../../util/ShuriRESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";
import { CallType, GstReportType } from "../../util/Constant";

export default class ReportRequestApi {
  static path = "v2/report";

  static search = ({ searchMode, page, records, filter, select, sort, forOrgId }) => {
    let path = ReportRequestApi.path + "/search-reports-created-by-me";
    let command = {
      searchMode: searchMode,
      page: page,
      records: records,
    };
    if (filter && Object.keys(filter).length > 0) {
      command.filter = filter;
    }
    if (select && Object.keys(select).length > 0) {
      command.select = select;
    }
    if (sort && Object.keys(sort).length > 0) {
      command.sort = sort;
    }
    return ShuriRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
      callType: CallType.FOR_ANY_ORG,
      forOrgId: forOrgId
    });
  };

  static create = ({
    requesterOrgId,
    referrerTaxIdentifier,
    fromYYYYMM,
    toYYYYMM,
    reportType,
  }) => {
    // TODO: put correct urls
    let path =
      reportType === GstReportType.SALES_RELATIONSHIP
        ? ReportRequestApi.path + "/sales-relationship"
        : ReportRequestApi.path + "/purchase-relationship";
    const command = {
      reportOrgId: requesterOrgId,
      referrerTaxIdentifier,
      fromYyyymm: fromYYYYMM,
      toYyyymm: toYYYYMM,
    };
    return ShuriRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
      forOrgId: requesterOrgId,
    });
  };

  static validateReportRequest = ({
    requesterOrgId,
    referrerTaxIdentifier,
    fromYYYYMM,
    toYYYYMM,
    reportType
  }) => {
    let path = `v2/report/validate-report-request`;
    const command = {
      reportOrgId: requesterOrgId,
      referrerTaxIdentifier,
      fromYyyymm: fromYYYYMM,
      toYyyymm: toYYYYMM,
      reportType: reportType
    };
    return ShuriRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
      forOrgId: requesterOrgId,
    });
  };

  static downloadReport = ({ ReportAttachmentid, forOrgId }) => {
    let path = `v2/attachment/download/${ReportAttachmentid}`;
    return ShuriRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
      isDownloadRequest: true,
      forOrgId: forOrgId,
    });
  };
}
