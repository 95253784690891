import { Container, Typography } from "@mui/material";
import React from "react";

const DisableAccountInfoPage = () => {
  return (
    <React.Fragment>
      <Container>
        <br />
        <br />
        <br />
        <Typography variant="h6">
          In Vardhana application, if any user wants to delete his account, the
          user should send an email from his registered email id to
          gbs-support@vayana.com stating the reason for the deletion request.
        </Typography>
      </Container>
    </React.Fragment>
  );
};

export default DisableAccountInfoPage;
