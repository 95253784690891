import { Typography, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import AttachmentApi from "../../../http/v1_apis/AttachmentApi";
import { BusinessScoreRequestStatus, CallType } from "../../../util/Constant";
import BorrowerFiListingDialog from "../../business_score_request/ShareScoreWithfiDialogComponent";
import RefererReferredApi from "../../../http/v1_apis/RefererReferredOrgApi";
import SystemDefault from "../../../util/SystemDefault";
import { SnackBarActions } from "../../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../../util/Constant";

const GBSViewDetailHeaderComponent = (props) => {
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [shareScoreDialog, setShareScoreDialog] = useState(true);
  const [fiBorrowerData, setFiBorrowerData] = useState([]);
  const [orgMap, setOrgMap] = useState({});
  const [businessScoreRequestToShare, setBusinessScoreRequestToShare] =
    useState({});
  const [isShare, setIsShare] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    props.bsrDetails.status === BusinessScoreRequestStatus.REPORT_GENERATED
      ? setIsReportGenerated(true)
      : setIsReportGenerated(false);
  }, [props.bsrDetails.status]);

  const onClickDownloadHandler = () => {
    AttachmentApi.download({
      attachmentId: props.bsrDetails.borrowerReportAttachmentId,
      callType: CallType.SELF_ORG,
      forOrgId: "",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const shareScoreRequestHandler = () => {
    RefererReferredApi.search({
      searchMode: SystemDefault.SEARCH_MODE,
      filter: { referer: true, referred: false },
      select: { organisations: true },
      sort: { createdAtDesc: true },
    }).then((response) => {
      if (response.results.length <= 0) {
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Your organisation has not been linked with any Referer",
            messageType: SnackBarMessageType.ERROR,
          })
        );
      } else {
        setFiBorrowerData(response.results);
        setOrgMap(response.objects.organisations);
        setBusinessScoreRequestToShare(props.bsrDetails);
        setIsShare(true);
        setShareScoreDialog(true);
      }
    });
  };

  const ShareScoreDialogCloseHandler = () => {
    setShareScoreDialog(false);
  };

  const ShareScoreDialogOpenHandler = () => {
    setShareScoreDialog(true);
  };

  return (
    <React.Fragment>
      {isShare && (
        <BorrowerFiListingDialog
          openDialog={shareScoreDialog}
          fiData={fiBorrowerData}
          orgMap={orgMap}
          handleclose={ShareScoreDialogCloseHandler}
          handleopen={ShareScoreDialogOpenHandler}
          scoreRequest={businessScoreRequestToShare}
        />
      )}
      <Grid container sx={{ marginTop: 2 }}>
        <Grid item xs={6} md={6}>
          <Typography variant="h5">Business Score Request Details</Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          md={6}
          spacing={2}
          alignItems="center"
          justifyContent="end"
        >
          <Grid item>
            <Button
              disabled={!isReportGenerated}
              startIcon={<DownloadIcon />}
              onClick={onClickDownloadHandler}
            >
              Download Report
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!isReportGenerated}
              onClick={shareScoreRequestHandler}
              startIcon={<ShareIcon />}
            >
              Share
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GBSViewDetailHeaderComponent;
