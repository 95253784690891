import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";
import { BankAccountEntityType, BankAccountEntityTypeByValue } from "../../util/Constant";

export default class BusinessBankApi {
  static path = "v1/bank";

  static create = ({  bankId, accountType, accountNumber, sanctionLimit, monthlyDrawingPowerLimit, attachmentIds }) => {
    let path = BusinessBankApi.path + "/create";
    const command = {
      name: null,
      bankId: bankId,
      accountType: accountType,
      accountNumber: accountNumber,
      entityType: BankAccountEntityTypeByValue(BankAccountEntityType.INDIVIDUAL).entity,
      sanctionLimit: sanctionLimit,
      monthlyDrawingPowerLimit: monthlyDrawingPowerLimit,
      attachmentIds: attachmentIds  
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static getAllBusinessBankUnitsData = () => {
    let path = BusinessBankApi.path + "/units";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static getData = ({ referenceId }) => {
    let path = BusinessBankApi.path + "/statement/get-data";
    const command = {
        referenceId: referenceId
    }
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
      command: command,
    });
  };

  static deleteBankStatement = ({ attachmentId }) => {
    let path = BusinessBankApi.path + "/statement/"+ attachmentId;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.DELETE,
    });
  };

  static getAllBanksData = () => {
    let path = BusinessBankApi.path + "/names";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };
}
