import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const AboutGBSComponent = () => {
  return (
    <React.Fragment>
      <Typography variant="h6">
        <Box my={3}>
          The <b>Vayana - Good Business Score (GBS),</b> powered by CRIF is a
          measure of business health. Your GBS score will help your company by
          opening doors to new business opportunities.
        </Box>
      </Typography>
      <Typography variant="h6">
        <Box my={3}>
          The GBS is like an X-Ray or blood report for your business. It tells
          you the exact picture of your business health - where you are doing
          well ,and which areas need to be improved upon. Getting the score from
          time to time, and making the right improvements, helps your company
          stand out from competition and achieve new business growth.
        </Box>
      </Typography>
      <Typography variant="h6">
        <Box my={3}>
          The score is confidential and cannot be shared with anyone without
          your consent.
        </Box>
      </Typography>
      <Typography variant="h6">
        <Box my={3}>
          The Score is brought to you by Vayana Network - India’s largest trade
          finance platform, and CRIF India - One of India’s leading provider of
          Credit Information, Business Information, Analytics, Scoring, Credit
          Management and Decisions Solution.
        </Box>
      </Typography>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item></Grid>
        <Grid item>
          <img src="/assets/images/vay.png" alt="mobile" />
        </Grid>
        <Grid item>
          <img src="/assets/images/powered-by-crif.png" alt="mobile" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AboutGBSComponent;
