import AboutGBSComponent from "../component/gbs/AboutGBSComponent";
import HowGBSHelpComponent from "../component/gbs/HowGBSHelpComponent";
import BetterGBSComponent from "../component/gbs/BetterGBSComponent.";
import GetGBSComponentold from "../component/gbs/GetGBSComponent";
import WriteToUsComponent from "../component/gbs/WriteToUsComponent";
import CheckGBSComponent from "../component/gbs/CheckGBSComponent.";
import { Box, Container, Typography } from "@mui/material";
import React from "react";
import LandingAppBarComponent from "../component/LandingAppBarComponent.";

const LandingPage = () => {
  const data = [
    {
      comp: <CheckGBSComponent />,
      bgclr: null,
      title: "Check your Business Health Now",
      id: 1,
    },
    {
      comp: <AboutGBSComponent />,
      bgclr: "#eceff1",
      title: "About Good Business Score",
      id: 2,
    },
    {
      comp: <HowGBSHelpComponent />,
      bgclr: "#FDF3F3",
      title: "How your GBS score helps you",
      id: 3,
    },
    {
      comp: <BetterGBSComponent />,
      bgclr: null,
      title: "How do you get a better score",
      id: 4,
    },
    {
      comp: <GetGBSComponentold />,
      bgclr: "#FDF3F3",
      title: "Get your GBS Score in 3 steps",
      id: 5,
    },
  ];

  return (
    <React.Fragment>
      <LandingAppBarComponent />
      {data.map((itm) => (
        <Box bgcolor={itm.bgclr} p={20} key={itm.id}>
          <Container>
            <Typography variant="h3">
              <Box lineHeight={2}>{itm.title}</Box>
            </Typography>

            {itm.comp}
          </Container>
        </Box>
      ))}
      <Box>
        <WriteToUsComponent />
      </Box>
    </React.Fragment>
  );
};

export default LandingPage;
