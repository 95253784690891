import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TncTxtComponent from "../common/TncTxtComponent";
import { useDispatch, useSelector } from "react-redux";
import RoutePath from "../../util/RoutePath";
import StringUtil from "../../util/StringUtil";
import UserApi from "../../http/theodore/UserApi";
import { SnackBarActions } from "../../store/SnackBarSlice";
import Paper from "@mui/material/Paper";
import { SnackBarMessageType } from "../../util/Constant";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SystemDefault from "../../util/SystemDefault";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { loaderActions } from "../../store/LoaderSlice";
import DoneIcon from "@mui/icons-material/Done";

const SignUpComponent = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loader.value);
  const isDisabled = loading;

  const [firstName, setFirstName] = useState("");
  const [firstNameErrMsg, setFirstNameErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [firstNameTouch, setFirstNameTouch] = useState(false);
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);

  const [lastName, setLastName] = useState("");
  const [lastNameErrMsg, setLastNameErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [lastNameTouch, setLastNameTouch] = useState(false);
  const [lastNameIsValid, setLastNameIsValid] = useState(false);

  const [email, setEmail] = useState("");
  const [emailHelpText, setEmailHelpText] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailTouch, setEmailTouch] = useState(false);
  const [emailIsUpdated, setEmailIsUpdated] = useState(false);
  const [emailIsInprogress, setEmailIsInprogress] = useState(false);

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberErrMsg, setMobileNumberErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [mobileNumberTouch, setMobileNumberTouch] = useState(false);
  const [mobileNumberIsValid, setMobileNumberIsValid] = useState(true);

  // const [otp, setOtp] = useState("");
  // const [otpErrMsg, setOtpErrMsg] = useState(SystemDefault.DEFAULT_ERROR_MESSAGE);
  // const [otpTouch, setOtpTouch] = useState(false);
  // const [otpIsValid, setOtpIsValid] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordErrMsg, setPasswordErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [passwordTouch, setPasswordTouch] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const [confirmPasswordErrMsg, setConfirmPasswordErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [confirmPasswordTouch, setConfirmPasswordTouch] = useState(false);
  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);


  const inputFirstNameInvalid = !firstNameIsValid && firstNameTouch;
  const inputLastNameInvalid = !lastNameIsValid && lastNameTouch;
  const inputEmailInvalid = !emailIsValid && emailTouch;
  const inputMobileNumberInvalid = !mobileNumberIsValid && mobileNumberTouch;
  // const inputOtpInvalid = !otpIsValid && otpTouch;
  const inputPasswordInvalid = !passwordIsValid && passwordTouch;
  const inputConfirmPasswordInvalid =
    !confirmPasswordIsValid && confirmPasswordTouch;

  const emailInputChangeHandler = (event) => {
    // setEmailIsExist(true);
    setEmailIsUpdated(true);
    setEmail(event.target.value);
    setEmailTouch(true);
    if (event.target.value.trim() === "") {
      setIsEmailError(false);
      setEmailIsValid(true);
      setEmailHelpText("");
    } else {
      const result = StringUtil.isValidEmail(event.target.value);
      if (result.result === true) {
        setIsEmailError(false);
        setEmailIsValid(true);
        setEmailHelpText("");
      } else {
        setIsEmailError(true);
        setEmailIsValid(false);
        setEmailHelpText(result.errMsg);
      }
    }
  };

  const emailFocusOutEventHandler = () => {
    if (email !== "" && emailIsValid && emailTouch && emailIsUpdated) {
      setEmailIsInprogress(true);
      UserApi.checkUserExistenceByEmail({ email: email }).then((response) => {
        setEmailIsUpdated(false);
        // setEmailIsExist(response.data.userPresent);
        if (response.data.userPresent) {
          setIsEmailError(true);
          setEmailHelpText("Email already exists in system");
        }
        setEmailIsInprogress(false);
      });
    }
  };

  const emailVerificationComponent = () => {
    if (email !== "") {
      if (emailIsInprogress && !isEmailError) {
        return <CircularProgress sx={{ mx: 2 }} size={25} />;
      } else if (!isEmailError && !inputEmailInvalid) {
        return <DoneIcon color="success" />;
      }
    }
  };

  const firstNameInputChangeHandler = (event) => {
    setFirstName(event.target.value);
    setFirstNameTouch(true);
    if (event.target.value.trim() === "") {
      setFirstNameIsValid(false);
      setFirstNameErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else {
      const result = StringUtil.isValidFirstName(event.target.value);
      if (result.result === true) {
        setFirstNameIsValid(true);
        setFirstNameErrMsg("");
      } else {
        setFirstNameIsValid(false);
        setFirstNameErrMsg(result.errMsg);
      }
    }
  };

  const lastNameInputChangeHandler = (event) => {
    setLastName(event.target.value);
    setLastNameTouch(true);
    if (event.target.value.trim() === "") {
      setLastNameIsValid(false);
      setLastNameErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else {
      const result = StringUtil.isValidFirstName(event.target.value);
      if (result.result === true) {
        setLastNameIsValid(true);
        setLastNameErrMsg("");
      } else {
        setLastNameIsValid(false);
        setLastNameErrMsg(result.errMsg);
      }
    }
  };

  const mobileNumberInputChangeHandler = (event) => {
    setMobileNumber(event.target.value);
    setMobileNumberTouch(true);
    if (event.target.value && event.target.value.length === 0) {
      setMobileNumberIsValid(true);
      setMobileNumberErrMsg("");
    } else {
      const result = StringUtil.isValidMobile(event.target.value);
      if (result.result === true) {
        setMobileNumberIsValid(true);
        setMobileNumberErrMsg("");
      } else {
        setMobileNumberIsValid(false);
        setMobileNumberErrMsg(result.errMsg);
      }
    }
  };

  const mobileNumberFocusOutHandler = (event) => {
    setMobileNumberTouch(true);
    if (mobileNumberIsValid === true && mobileNumber.length >= 1) {
      UserApi.checkUserExistenceByMobile({ mobile: mobileNumber })
        .then((response) => {
          if (response.data.userPresent === true) {
            setMobileNumberIsValid(false);
            setMobileNumberErrMsg("mobile number already exist");
          } else {
            setMobileNumberIsValid(true);
            setMobileNumberErrMsg("");
          }
        })
        .catch((reject) => {
          setMobileNumberIsValid(false);
          setMobileNumberErrMsg("error to verify mobile number");
        });
    }
  };

  const passwordInputChangeHandler = (event) => {
    setPassword(event.target.value);
    setPasswordTouch(true);
    const result = StringUtil.isValidPassword(event.target.value);
    if (result.result === true) {
      setPasswordIsValid(true);
      setPasswordErrMsg("");
    } else {
      setPasswordIsValid(false);
      setPasswordErrMsg(result.errMsg);
    }
  };

  const confirmPasswordInputChangeHandler = (event) => {
    setConfirmPasswordTouch(true);
    const result = StringUtil.isValidConfirmPassword(
      password,
      event.target.value
    );

    if (result.result === true) {
      setConfirmPasswordIsValid(true);
      setConfirmPasswordErrMsg("");
    } else {
      setConfirmPasswordIsValid(false);
      setConfirmPasswordErrMsg(result.errMsg);
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (mobileNumber === "" && email === "") {
      dispatch(
        SnackBarActions.openSnackBar({
          message:
            "Email Address or Mobile Number at least one field is mandetory",
          messageType: SnackBarMessageType.ERROR,
        })
      );
      return;
    }
    setFirstNameTouch(true);
    setLastNameTouch(true);
    setEmailTouch(true);
    setMobileNumberTouch(true);
    setPasswordTouch(true);
    setConfirmPasswordTouch(true);
    if (
      !firstNameIsValid ||
      !lastNameIsValid ||
      !mobileNumberIsValid ||
      inputEmailInvalid ||
      isEmailError ||
      !passwordIsValid ||
      !confirmPasswordIsValid
    ) {
      return;
    }

    dispatch(loaderActions.startLoader());
    if (email !== "") {
      UserApi.generateEmailOtp({ email: email, purpose: "SignupOTP" })
        .then((response) => {
          props.setUserInfo(firstName, lastName, email, mobileNumber, password);
          dispatch(
            SnackBarActions.openSnackBar({
              message: "OTP has been sent to your email address successfully",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
          dispatch(loaderActions.stopLoader());
        })
        .catch((reject) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: reject.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
        });
    } else {
      UserApi.generateMobileOtp({ mobile: mobileNumber, purpose: "SignupOTP" })
        .then((response) => {
          props.setUserInfo(firstName, lastName, email, mobileNumber, password);
          dispatch(
            SnackBarActions.openSnackBar({
              message: "OTP has been sent to your email address successfully",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
          dispatch(loaderActions.stopLoader());
        })
        .catch((reject) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: reject.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
        });
    }
  };

  return (
    <Paper elevation={3}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          pb={1}
          pt={3}
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img height={40} src="/assets/images/vay.png" alt="mobile" />
          <br />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <form onSubmit={onSubmitHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  error={inputFirstNameInvalid}
                  helperText={inputFirstNameInvalid && firstNameErrMsg}
                  onChange={firstNameInputChangeHandler}
                  autoComplete="fname"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  error={inputLastNameInvalid}
                  helperText={inputLastNameInvalid && lastNameErrMsg}
                  onChange={lastNameInputChangeHandler}
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={isEmailError}
                  helperText={emailHelpText}
                  onChange={emailInputChangeHandler}
                  onBlur={emailFocusOutEventHandler}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  InputProps={{ endAdornment: emailVerificationComponent() }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={10}>
                    <TextField
                      variant="outlined"
                      error={inputMobileNumberInvalid}
                      helperText={
                        inputMobileNumberInvalid && mobileNumberErrMsg
                      }
                      onChange={mobileNumberInputChangeHandler}
                      onBlur={mobileNumberFocusOutHandler}
                      name="mobile number"
                      fullWidth
                      id="Mobile Number"
                      label="Mobile Number"
                    />
                  </Grid>
                  <Grid item>
                    {mobileNumber !== "" && mobileNumberIsValid && (
                      <DoneAllIcon color="success" />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      Mobile-number should contain Country-code as prefix,
                      '+91-' for India, e.g.: +91-0000000000
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={inputOtpInvalid}
                  helperText={inputOtpInvalid && otpErrMsg}
                  onChange={otpInputChangeHandler}
                  name="OTP"
                  required
                  fullWidth
                  id="OTP"
                  label="OTP"
                  autoFocus
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={inputPasswordInvalid}
                  helperText={inputPasswordInvalid && passwordErrMsg}
                  onChange={passwordInputChangeHandler}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  // InputProps={{ // <-- This is where the toggle button is added.
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton
                  //         aria-label="toggle password visibility"
                  //         onClick={handleClickShowPassword}
                  //       >
                  //         {showPassword ? <Visibility /> : <VisibilityOff />}
                  //       </IconButton>
                  //     </InputAdornment>
                  //   )
                  // }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={inputConfirmPasswordInvalid}
                  helperText={
                    inputConfirmPasswordInvalid && confirmPasswordErrMsg
                  }
                  onChange={confirmPasswordInputChangeHandler}
                  required
                  fullWidth
                  name="confirm password"
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  id="confirm_password"
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {/*<Checkbox*/}
                  {/*  checked={tnc}*/}
                  {/*  inputProps={{ "aria-label": "primary checkbox" }}*/}
                  {/*  onChange={tncChangeHandler}*/}
                  {/*/>*/}
                  <TncTxtComponent />
                </Box>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isDisabled}
            >
              Sign Up{" "}
              {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to={RoutePath.APP_LOGIN} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
        {/*<Copyright sx={{ mt: 5 }} />*/}
      </Container>
    </Paper>
  );
};

export default SignUpComponent;
