import { createSlice } from "@reduxjs/toolkit";

const initState = []; 
const MyOrgSlice = createSlice({
  name: "myOrg",
  initialState: initState,
  reducers: {
    setMyOrg(state, action) {
      state.length = 0;
      state.push(...action.payload.data.associatedOrgs);
    },
    removeMyOrg(state) {
      state = [];
    },
  },
});

export const myOrgActions = MyOrgSlice.actions;
export default MyOrgSlice.reducer;
