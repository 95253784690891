import * as React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const PlatformAdminDashboardFilterComponent = (props) => {
  const sanatizeString = (name) => {
    name = name.replaceAll("_", " ");
    return name.toUpperCase();
  };
  return (
    <Card variant="outlined">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        px={5}
        py={2}
      >
        <Grid item>
          <ButtonGroup aria-label="text button group">
            <Button
              variant={props.duration === "TOTAL" ? "contained" : "outlined"}
              onClick={() => props.updateDuration("TOTAL")}
            >
              Total
            </Button>
            <Button
              variant={
                props.duration === "CURRENT_MONTH" ? "contained" : "outlined"
              }
              onClick={() => props.updateDuration("CURRENT_MONTH")}
            >
              Current Month
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="api-client">Api client</InputLabel>
            <Select
              labelId="api-client"
              id="api-client-dropdown"
              color="primary"
              autoWidth
              label="Api Client"
              value={props.apiClientId != null ? props.apiClientId : "ALL"}
              onChange={(event) =>
                event.target.value !== "ALL"
                  ? props.updateApiClient(event.target.value)
                  : props.updateApiClient(null)
              }
            >
              <MenuItem id="ALL" value={"ALL"}>
                {sanatizeString("ALL")}
              </MenuItem>
              {props.apiClientList?.map((client) => {
                return (
                  <MenuItem id={client.id} value={client.id} key={client.id}>
                    <Typography
                      sx={
                        !client.active
                          ? {
                              textDecoration: "line-through",
                              color: "rgba(0,0,0,0.47)",
                            }
                          : {}
                      }
                    >
                      {sanatizeString(client.name)}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
};
export default PlatformAdminDashboardFilterComponent;
