import React from "react";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import RoutePath from "../util/RoutePath";

const NotFoundPage = () => {

  return (
    <Grid container direction="column" alignItems="center" my={10}>
      <Grid item>
        <img height={40} src="/assets/images/vay.png" alt="vayana logo" />
      </Grid>
      <Grid item my={10}>
        <Typography variant="h6" color="secondary" sx={{ fontWeight: "800" }}>
          Looking for something?
        </Typography>
        <Typography>
          We're sorry. The Web address you entered is not a functioning page on
          our site.
        </Typography>

        <Typography sx={{ fontWeight: "800" }} mt={3}>
          Go to gbs.vayana.com's <Link to={RoutePath.KEY_HOME}>Home</Link> Page
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
