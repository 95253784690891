import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useHistory } from "react-router-dom";
import SystemDefault from "../../util/SystemDefault";
import StringUtil from "../../util/StringUtil";
import RoutePath from "../../util/RoutePath";
import DoneIcon from "@mui/icons-material/Done";
import RefererReferredApi from "../../http/v1_apis/RefererReferredOrgApi";
import axios from "axios";
import AppUtil from "../../util/AppUtil";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";
import { loaderActions } from "../../store/LoaderSlice";

const FileUploadDialog = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const myCurrentOrg = useSelector((state) => state.currentOrg);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState(false);
  const [uploadSuccess, setUploadSuccess] = React.useState(false);

  const handleClickOpen = () => {
    setLoading(false);
    setSelectedFiles(false);
    setUploadSuccess(false);
    setOpen(true);
  };

  const handleClose = () => {
    setLoading(false);
    setSelectedFiles(false);
    setUploadSuccess(false);
    setOpen(false);
  };
  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };
  const submitHandler = (e) => {
    e.preventDefault(); //prevent the form from submitting
    let formData = new FormData();
    setLoading(true);
    formData.append("files", selectedFiles[0]);
    axios
      .post(AppUtil.getApiBaseUrl() + "v1/linked-org/file", formData, {
        headers: {
          Authorization: AppUtil.getJwtToken(),
          "org-id": AppUtil.getCurrentOrgId(),
          "Content-Type": "multipart/form-data",
          "source-token": AppUtil.getGbsAppSourceToken(),
        },
        onUploadProgress: () => {
          //Set the progress value to show the progress bar
          // setProgress(Math.round((100 * data.loaded) / data.total))
        },
      })
      .then((response) => {
        if (response.data["status"] === 1) {
          setUploadSuccess(true);
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Successfully Uploaded File",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
          history.push(
            RoutePath.routeBorrowerFileListingPage({
              orgId: myCurrentOrg?.organisation?.id,
            })
          );
        } else {
          // if(Object.values(response.data["error"].args).length === 0){
          //   dispatch(
          //     SnackBarActions.openSnackBar({
          //       message: response.data["error"].message,
          //       messageType: SnackBarMessageType.ERROR,
          //     })
          //   );
          // } else {
          //   dispatch(
          //     SnackBarActions.openSnackBar({
          //       message: response.data["error"].args.message,
          //       messageType: SnackBarMessageType.ERROR,
          //     })
          //   );
          // }

          let errorResponse = response.data["error"];
          while (true) {
            let errorData = errorResponse["args"];
            if (Object.keys(errorData).length === 0) {
              dispatch(
                SnackBarActions.openSnackBar({
                  message: errorResponse["message"],
                  messageType: SnackBarMessageType.ERROR,
                })
              );
              break;
            }
            errorResponse = errorData;
          }

          setSelectedFiles(false);
          setUploadSuccess(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.response.data.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setSelectedFiles(false);
        setUploadSuccess(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add Multiple Referred-Org
      </Button>
      <Dialog open={open} onClose={handleClose}>
        {loading && <LinearProgress />}
        <DialogTitle>Add Bulk Referred-Org</DialogTitle>
        {uploadSuccess && (
          <Alert severity="success">
            File Uploaded Successfully,its async task to create Referred-Org
            please check status on prvious Uploads
          </Alert>
        )}
        {!uploadSuccess && (
          <div>
            <DialogContent>
              <DialogContentText>
                Please upload excel sheet with size less than 200 kb and records
                should be less than equal to 500, Download sample template:{" "}
                <a
                  href={"/assets/template_files/borrower_template.xlsx"}
                  download={"Referred-Org.xlsx"}
                >
                  Referred-Org.xlsx
                </a>
              </DialogContentText>
              <Box px={10} py={5}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <label htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: "none" }}
                        type="file"
                        onChange={selectFile}
                      />
                      <Button
                        className="btn-choose"
                        variant="outlined"
                        component="span"
                      >
                        Choose File
                      </Button>
                    </label>
                    <div className="file-name">
                      {selectedFiles && selectedFiles.length > 0
                        ? selectedFiles[0].name
                        : null}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          </div>
        )}
        <DialogActions>
          <Button
            className="btn-upload"
            color="primary"
            variant="contained"
            component="span"
            disabled={!selectedFiles}
            onClick={submitHandler}
          >
            Upload
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const AddSingleBorrowerDialog = () => {
  const dispatch = useDispatch();
  const invite_btn_loading = useSelector((state) => state.loader.value);
  const isDisabled = invite_btn_loading;
  const [open, setOpen] = React.useState(false);
  const loading = false;
  const [name, setName] = useState("");
  const [nameTouch, setNameTouch] = useState(false);
  const [nameIsValid, setNameIsValid] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );

  const taxIdentifierType = "PAN";
  const country = "India";

  const [pan, setPan] = useState("");
  const [panTouch, setPanTouch] = useState(false);
  const [panIsValid, setPanIsValid] = useState(false);
  const panIsInprogress = false;
  const [panErrMsg, setPanErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );

  const [adminEmail, setAdminEmail] = useState("");
  const [adminEmailTouch, setAdminEmailTouch] = useState(false);
  const [adminEmailIsValid, setAdminEmailIsValid] = useState(false);
  const [adminEmailErrMsg, setAdminEmailErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );

  const inputNameInvalid = !nameIsValid && nameTouch;
  const inputPanInvalid = !panIsValid && panTouch;
  const inputAdminEmailInvalid = !adminEmailIsValid && adminEmailTouch;

  const nameInputChangeHandler = (event) => {
    setName(event.target.value);
    setNameTouch(true);
    if (event.target.value.trim() === "") {
      setNameIsValid(false);
      setNameErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else {
      setNameIsValid(true);
    }
  };
  const panInputChangeHandler = (event) => {
    const inputPan = event.target.value.toUpperCase().trim();
    setPan(inputPan);
    setPanTouch(true);
    const isValidPan = StringUtil.isValidPan(inputPan);
    if (!isValidPan.result) {
      setPanIsValid(false);
      setPanErrMsg(isValidPan.errMsg);
    } else {
      setPanIsValid(true);
      setPanErrMsg(isValidPan.errMsg);
    }
  };

  const adminEmailInputChangeHandler = (event) => {
    const inputAdminEmail = event.target.value.toLowerCase().trim();
    setAdminEmail(inputAdminEmail);
    setAdminEmailTouch(true);

    const isValidAdminEmail = StringUtil.isValidEmail(inputAdminEmail);
    if (!isValidAdminEmail.result) {
      setAdminEmailIsValid(false);
      setAdminEmailErrMsg(isValidAdminEmail.errMsg);
    } else {
      setAdminEmailIsValid(true);
    }
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();

    setNameTouch(true);
    setPanTouch(true);
    setAdminEmailTouch(true);

    if (!nameIsValid || !panIsValid || !adminEmailIsValid) {
      return;
    }
    dispatch(loaderActions.startLoader());
    RefererReferredApi.singleCreate({
      name,
      pan,
      adminEmail,
    })
      .then(() => {
        setOpen(false);
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Invited Successfully",
            messageType: SnackBarMessageType.SUCCESS,
          })
        );
        dispatch(loaderActions.stopLoader());
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        dispatch(loaderActions.stopLoader());
      });
  };

  const panVerificationComponent = () => {
    if (panIsInprogress) {
      return <CircularProgress />;
    } else if (!inputPanInvalid) {
      return <DoneIcon color="success" />;
    }
  };
  const handleClickOpen = () => {
    setNameTouch(false);
    setPanTouch(false);
    setAdminEmailTouch(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add Referred-Org
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <Box p={5}>
          {loading && <LinearProgress />}
          <DialogContent>
            <Container component="main" maxWidth="sm">
              <Typography variant="h5" mb={5} align="center">
                Invite/Connect Referred-Org
              </Typography>
              <form onSubmit={onSubmitHandler} noValidate>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  alignItems="stretch"
                  // alignContent="center"
                  spacing={2}
                >
                  <Grid item>
                    <TextField
                      error={inputNameInvalid}
                      variant="outlined"
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      helperText={inputNameInvalid && nameErrMsg}
                      onChange={nameInputChangeHandler}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      // error={inputNameInvalid}
                      variant="outlined"
                      required
                      fullWidth
                      id="country"
                      label="Country"
                      name="country"
                      autoFocus
                      defaultValue={country}
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      // error={inputNameInvalid}
                      variant="outlined"
                      required
                      fullWidth
                      id="taxIdentifierType"
                      label="Tax Identifier Type"
                      name="Tax Identifier Type"
                      autoFocus
                      defaultValue={taxIdentifierType}
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={11}>
                        <TextField
                          error={inputPanInvalid}
                          variant="outlined"
                          required
                          fullWidth
                          id="pan"
                          label="PAN"
                          name="pan"
                          autoComplete="pan"
                          autoFocus
                          helperText={inputPanInvalid && panErrMsg}
                          onChange={panInputChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        {panVerificationComponent()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <TextField
                      type="email"
                      error={inputAdminEmailInvalid}
                      required
                      id="adminEmail"
                      label="Admin Email"
                      fullWidth
                      helperText={inputAdminEmailInvalid && adminEmailErrMsg}
                      onChange={adminEmailInputChangeHandler}
                    />
                  </Grid>
                </Grid>
              </form>
            </Container>
          </DialogContent>
          <Box p={2} />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={onSubmitHandler}
              disabled={isDisabled}
            >
              Invite{" "}
              {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};
export default FileUploadDialog;
