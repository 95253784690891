import { Table, TableRow, TableContainer, TableBody } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiTableCell from "@mui/material/TableCell";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  borderBottom: "none",
}));

const GBSReviewGstinComponent = (props) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {Object.values(props.gstinDetails).map((item) => {
            return item.active ? (
              <TableRow key={item.gstin}>
                <TableCell>
                  GSTIN : <b>{item.gstin}</b>
                </TableCell>
                <TableCell>
                  Username : <b>{item.gstinUsername}</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : null;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GBSReviewGstinComponent;
