import React from "react";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import StatsBusinessScoreRequestApi from "../../http/v1_apis/StatsBusinessScoreRequestApi";
import { PieChart } from "@mui/x-charts";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { BSRSTATSSTATUS, SnackBarMessageType } from "../../util/Constant";
import { CircularProgress, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

const GbsScoreCategoryChartComponent = (props) => {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const [scoreCategory, setScoreCategory] = React.useState(null);

  const getColorFromScoreCategory = (scoreCategory) => {
    switch (scoreCategory) {
      case "CRITICAL":
        return "#FF5050";

      case "POOR":
        return "#FF863B";

      case "AVERAGE":
        return "#FFD324";

      case "GOOD":
        return "#0091FF";

      case "EXCELLENT":
        return "#3DDB93";

      default:
        return "#FFFFFF";
    }
  };

  React.useEffect(() => {
    // fetch data from xavier
    StatsBusinessScoreRequestApi.getStatsStatusCount({
      filter: {
        duration: props.duration,
        apiClientId: props.apiClientId,
        statsStatus : BSRSTATSSTATUS.SUCCESS
      },
      groupBy: { scoreCategory: true },
    })
      .then((res) => {
        if (res.length > 0)
          setScoreCategory(
            <PieChart
              series={[
                {
                  data: res.map((stat) => {
                    return {
                      id: stat.scoreCategory,
                      value: stat.count,
                      color: getColorFromScoreCategory(stat.scoreCategory),
                      label: `${stat.scoreCategory}(${stat.count})`,
                    };
                  }),
                },
              ]}
              width={550}
              height={300}
              margin={{ right: 200 }}
            />
          );
        else {
          setScoreCategory(<NoDataFoundComponent />);
        }
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setScoreCategory(<NoDataFoundComponent />);
      });

    setLoading(false);
  }, [props.duration, props.apiClientId, dispatch]);

  return (
    <div>
      <Typography variant="h4" pb={3}>
        Score Category Stats
      </Typography>

      {loading ? <CircularProgress size={24} /> : scoreCategory}
    </div>
  );
};

export default GbsScoreCategoryChartComponent;
