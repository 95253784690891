import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class BusinessScoreRequestApi {
  static path = "v1/business-score-request";

  static search = ({ searchMode, page, records, filter, select, sort }) => {
    let path = BusinessScoreRequestApi.path + "/search";
    let command = {
      searchMode: searchMode,
      page: page,
      records: records,
    };
    if (filter && Object.keys(filter).length > 0) {
      command.filter = filter;
      command.filter.generatedBy = "USER";
    } else {
      // adding by default to get user
      command.filter = { filter: { generatedBy: "USER" } };
    }

    if (select && Object.keys(select).length > 0) {
      command.select = select;
    }
    if (sort && Object.keys(sort).length > 0) {
      command.sort = sort;
    }
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static create = ({ units }) => {
    let path = BusinessScoreRequestApi.path;
    const command = {
      units: units,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static createNew = ({ gstinRefUnits, bankRefUnits }) => {
    let path = BusinessScoreRequestApi.path + "/new";
    const command = {
      gstUnits: gstinRefUnits,
      bankRefUnits: bankRefUnits,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static get = ({ requestId, apiToken }) => {
    let path = BusinessScoreRequestApi.path + "/" + requestId;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
      apiToken: apiToken,
    });
  };
}
