import * as React from "react";
import { Chip, CircularProgress, Typography } from "@mui/material";
import SystemDefault from "../../util/SystemDefault";
import RefererReferredApi from "../../http/v1_apis/RefererReferredOrgApi";
import PaginationStatusBarComponent from "../common/PaginationStatusBarComponent";
import {
  BorrowerFileUploadStatusByValue
} from "../../util/Constant";
import DateUtil from "../../util/DateUtil";
import ListingCardComponent from "../common/ListingCardComponent";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import Paper from "@mui/material/Paper";
import RoutePath from "../../util/RoutePath";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const BorrowerFileListingComponent = (props) => {
  const history = useHistory();

  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);
  const [records, setRecords] = React.useState(SystemDefault.PAGE_SIZE[0]);
  const [loading, setLoading] = React.useState();
  const [borrowerFileData, setBorrowerFileData] = React.useState([]);
  const currentOrg = useSelector((state) => state.currentOrg);

  React.useEffect(() => {
    setLoading(true);
    RefererReferredApi.search_file_data({
      searchMode: SystemDefault.SEARCH_MODE,
      page: page,
      records: records,
      filter: {},
      select: {},
      sort: { createdAtDesc: true },
    }).then((response) => {
      setBorrowerFileData(response.results);
      setPageCount(Math.ceil(response.total / records));
      setLoading(false);
    });
  }, [page, records]);

  const onPageChangeHandler = (value) => {
    setPage(value);
  };

  const onRecordsChangeHandler = (value) => {
    setRecords(value);
  };

  const onClickViewHandler = (item) => {
    history.push(
      RoutePath.routeBorrowerFileDetailListingPage({
        orgId: currentOrg?.organisation?.id,
        borrowerFileId: item.id,
      })
    );
  };

  const loadData = () => {
    return (
      <React.Fragment>
        {borrowerFileData?.map((item) => {
          return (
            <ListingCardComponent
              key={item.id}
              item={item}
              title={item.fileName}
              subTitle1={
                <Typography variant="caption" display="inline">
                  Created at : {DateUtil.convertToDateFormat(item.createdAt)}
                </Typography>
              }
              column1={
                <Chip
                  label={BorrowerFileUploadStatusByValue(item.status).status}
                  color={BorrowerFileUploadStatusByValue(item.status).color}
                />
              }
              action1={"View"}
              actionHandler1={onClickViewHandler}
            />
          );
        })}
        <PaginationStatusBarComponent
          pageCount={pageCount}
          page={page}
          onPageChangeHandler={onPageChangeHandler}
          records={records}
          onRecordsChangeHandler={onRecordsChangeHandler}
        />
      </React.Fragment>
    );
  };

  return (
    <Paper elevation={1}>
      {loading && <CircularProgress size={24} />}
      {!loading && borrowerFileData?.length <= 0 && <NoDataFoundComponent />}
      {!loading && loadData()}
    </Paper>
  );
};

export default BorrowerFileListingComponent;
