import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import BusinessApi from "../../http/v1_apis/BusinessApi";
import { useSelector } from "react-redux";
import OrgApi from "../../http/theodore/OrgApi";
import BorrowerScoreListingComponent from "../../component/borrower/BorrowerScoreListingComponent";
import Alert from "@mui/material/Alert";

const FiBorrowerDetailPage = () => {
  const [businessData, setBusinessData] = React.useState({});
  const [organisationData, setOrganisationData] = React.useState({});
  const [industrySector, setIndustrySector] = React.useState();

  const industrySectorList = useSelector(
    (state) => state.industrySector.itemList
  );

  const params = useParams();



  React.useEffect(() => {
    const updateMyIndustrySector = (industrySectorId) => {
      industrySectorList.forEach((item) => {
        if (item.id === industrySectorId) {
          setIndustrySector(item);
        }
      });
    };
    OrgApi.get({ id: params.borrowerId }).then((response) => {
      setOrganisationData(response.data);
      BusinessApi.get({ id: response.data?.id }).then((response) => {
        setBusinessData(response);
        updateMyIndustrySector(response.industrySectorId);
      });
    });
  }, [industrySectorList,params.borrowerId]);

  const tradeNameComponent = () => {
    if (
      businessData?.legalName !== undefined &&
      businessData.legalName !== null
    ) {
      return (
        <Typography>
          Legal Name : {businessData?.legalName} <br />
        </Typography>
      );
    }
  };

  return (
    <React.Fragment>
      <Paper>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box px={2} py={2}>
              {organisationData?.name && (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                >
                  {organisationData?.name} <br />
                </Typography>
              )}
              {businessData ? (
                <Typography>
                  PAN : {organisationData?.taxIdentifier} <br />
                </Typography>
              ) : (
                <Alert severity={"error"}>
                  Seems {organisationData?.name} Organisation not yet onboarded
                  on GBS Platform !!!
                </Alert>
              )}
              {tradeNameComponent()}
              {industrySector?.id && (
                <Typography>
                  Industry Sector : {industrySector?.name}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={1}
        sx={{ paddingTop: 1, marginTop: 3, paddingLeft: 1, paddingRight: 1 }}
      >
        <BorrowerScoreListingComponent
          borrowerId={params.borrowerId}
          fullWidth
        />
      </Paper>
    </React.Fragment>
  );
};

export default FiBorrowerDetailPage;
