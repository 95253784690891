import TheodoreApiRESTUtil from "../../util/TheodoreRESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class PasswordResetApi {
  static path = "pwdreset";

  static genrateOtp = ({ type, emailMobile }) => {
    let path = PasswordResetApi.path;
    const command = {
      handle: emailMobile,
      handleType: type,
    };
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static setWithEmailOtp = ({ email, otpString, newPassword }) => {
    let path = PasswordResetApi.path + "/email/" + email;
    const command = { otpString: otpString, newPassword: newPassword };
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static setWithMobileOtp = ({ mobile, otpString, newPassword }) => {
    let path = PasswordResetApi.path + "/mobile/" + mobile;
    const command = { otpString: otpString, newPassword: newPassword };
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };
}
