import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class V2BusinessScoreRequestApi {
  static path = "v2/business-score-request";

  static createBsr = () => {
    let path = V2BusinessScoreRequestApi.path;
    const command = {};
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static submitBsr = ({ bsrId }) => {
    let path = V2BusinessScoreRequestApi.path + "/submit";
    const command = {
        bsrId: bsrId,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static search = ({ searchMode, page, records, filter, select, sort }) => {
    let path = "business-score-request/search";
    let command = {
      searchMode: searchMode,
      page: page,
      records: records,
    };
    if (filter && Object.keys(filter).length > 0) {
      command.filter = filter;
    }
    if (select && Object.keys(select).length > 0) {
      command.select = select;
    }
    if (sort && Object.keys(sort).length > 0) {
      command.sort = sort;
    }
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static getBsrDetails = ({bsrId}) => {
    let path = V2BusinessScoreRequestApi.path +"/detail/" + bsrId;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };
}
