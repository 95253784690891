import {
  Button,
  CircularProgress,
  Container,
  Grid, List, Paper, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import GBSBankStatementItemComponent from "./GBSBankStatementItemComponent";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarActions } from "../../../store/SnackBarSlice";
import { BankAccountType, SnackBarMessageType } from "../../../util/Constant";
import { stepperActions } from "../../../store/StepperSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AddGstinBankDetailsComponent from "../../common/AddGstinBankDetailsComponent";
import V2BusinessScoreRequestBankApi from "../../../http/v2_apis/BusinessScoreRequestBankApi";
  
  const GBSBankStatementComponent = (props) => {
    const currentOrg = useSelector((state) => state.currentOrg);
    const [loading, setLoading] = useState(false);
    const [bankMap, setBankMap] = useState({});
    const steps = useSelector((state)=> state.stepper);
    const params = useParams();
    const dispatch = useDispatch();
    const [openModelForAddBankAccount, setOpenModelForAddBankAccount] = useState(false);
    const [bankMasterDataMap, setBankMasterData] = useState({});

    const bankNames = useSelector((state)=>state.bankName.itemList);
  
    useEffect(()=>{
      setBankMasterData(props.bankMasterDataMap);
      setBankMap(props.bankDetails.banks);
      if(steps.steps[0].skipped){
        dispatch(stepperActions.setStepSkippableOrNot({step: 1, skippable: false }));
      }
    },[dispatch,props.bankDetails.banks, props.bankMasterDataMap, steps.steps]);

    const updateBankMasterData = () => {
      let tempMasterData = {...bankMasterDataMap};
      Object.values(bankMap).forEach((item)=> {
        delete tempMasterData[item.businessBankId]
      })
      setBankMasterData(tempMasterData);
      props.setBankMasterData(tempMasterData);
    };
    
    const removeBankFromMasterData = (id) => {
      let bankMasterData = {...bankMasterDataMap};
      delete bankMasterData[id];
      setBankMasterData(bankMasterData);
    }
  
    const updateBankMap = (value) => {
      setBankMap({...value});
      props.addUpdateBankDetails(value);
    }

    const updateSingleBankDetails = (id, value) => {
      let bankItems = {...bankMap}
      bankItems[id] = {...value}
      updateBankMap(bankItems);
    }

    const updateBankActiveStatus = (id, value) => {
      let bankItems = {...bankMap};
      bankItems[id].active = value;
      updateBankMap(bankItems);
    }

    const updateBankEditModeStatus = (id, value) => {
      let bankItems = {...bankMap};
      bankItems[id].editMode = value;
      updateBankMap(bankItems);
    }

    const updateBankStatements = (id, value) => {
      let bankItems = {...bankMap};
      bankItems[id].bankStatements = {...value};
      updateBankMap(bankItems);
    }

    const addNewBankDetailsClickHandler = (event) => {
      let id = uuidv4();
      let newBankDetailsMap = {
            [id]: {
              id,
              createMode: true,
              editMode: true,
              active: false,
              businessBankId:"",
              bankName: "",
              bankId: "",
              accountNumber: "",
              bankAccountHolderName: "",
              accountType: "",
              bankNameIsValid: false,
              accountNumberIsValid: false,
              accountTypeIsValid: false,
              sanctionLimit: "",
              monthlyDrawingPowerLimit: {},
              bankStatements: {}
            },
            ...bankMap,
          }
          updateBankMap(newBankDetailsMap);
          props.updateBankDetailsVerifiedStatus(false);
      dispatch(stepperActions.changeAccordianId({value: id}));
      dispatch(stepperActions.setStepCompletedOrNot({step: steps.activeStep, completed: false}));
    };  

    const addBankAccountFromMasterDataHandler = (id, value) => {
      setLoading(true);
      if(value.type === BankAccountType.CC || value.type === BankAccountType.OD) {
        let newBankDetailsMap =  {
          [id]: {
                id,
                createMode: true,
                active: true,
                editMode: true,
                businessBankId:value.businessBankId,
                bankName: value.bankName,
                bankId: value.bankId,
                accountNumber: value.number,
                bankAccountHolderName: value.name,
                accountType: value.type,
                bankNameIsValid: true,
                accountNumberIsValid: true,
                accountTypeIsValid: true,
                sanctionLimit: "",
                monthlyDrawingPowerLimit: {},
                bankStatements: {}
          },
          ...bankMap,
        };
        updateBankMap(newBankDetailsMap);
        setLoading(false);
      } else {
        V2BusinessScoreRequestBankApi.addBank({
          bsrId: params.bsrId,
          bankId: value.bankId,
          accountType: value.type,
          accountNumber: value.number,
          bankAccName: value.name,
          sanctionLimit: "",
          monthlyDrawingPowerLimit: []
        }).then((response)=> {
          let newBankDetailsMap =  {
            [id]: {
                  id,
                  createMode: false,
                  active: true,
                  editMode: false,
                  businessBankId:value.businessBankId,
                  bankName: value.bankName,
                  bankId: value.bankId,
                  accountNumber: value.number,
                  bankAccountHolderName: value.name,
                  accountType: value.type,
                  bankNameIsValid: true,
                  accountNumberIsValid: true,
                  accountTypeIsValid: true,
                  sanctionLimit: "",
                  monthlyDrawingPowerLimit: {},
                  bankStatements: {}
            },
            ...bankMap,
          };
          updateBankMap(newBankDetailsMap);
          removeBankFromMasterData(id);
          dispatch(stepperActions.changeAccordianId({value: id}));
          props.updateBankDetailsVerifiedStatus(false);
          dispatch(stepperActions.setStepCompletedOrNot({step: steps.activeStep, completed: false}));
          setLoading(false);
        }).catch((error)=> {
          dispatch(
            SnackBarActions.openSnackBar({
              message: error.errorMessage,
              messageType: SnackBarMessageType.ERROR
            })
          )
          setLoading(false);
        })
      }
  };

    const removeBankHandler = (id) => {
      let bankItems = {...bankMap};
      delete bankItems[id];
      updateBankMap(bankItems);
    }
  
    const onSubmitHandler = (event) => {
      event.preventDefault();
      let isFormValid = true;
      let isBankDetailsTextFieldValid = true;
      let isAllBankDetailsDisabled = true;
      let activeFilesCount = 0;
        dispatch(stepperActions.setStepCompletedOrNot({step: steps.activeStep, completed: false}))
      if(Object.values(bankMap).length === 0){
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Atleast one bank must be added",
            messageType: SnackBarMessageType.ERROR,
          })
        );
        isFormValid = false;
        return;
      }
      Object.values(bankMap).forEach((item)=> {
        if(item.active){
          isAllBankDetailsDisabled = false;
          if(!item.bankNameIsValid || !item.accountNumberIsValid || !item.accountNumberIsValid){
            dispatch(
              SnackBarActions.openSnackBar({
                message: "Bank details are required for all banks",
                messageType: SnackBarMessageType.ERROR
              })
            )
            dispatch(stepperActions.changeAccordianId({value: item.id}));
            isFormValid = false;
            isBankDetailsTextFieldValid = false;
            return;
          }
        }
      })
      if(isAllBankDetailsDisabled){
        dispatch(
          SnackBarActions.openSnackBar({
            message: "Atleast one bank must be enabled",
            messageType: SnackBarMessageType.ERROR
          })
        )
        isFormValid = false;
        isBankDetailsTextFieldValid = false;
        return;
      }
      if(isBankDetailsTextFieldValid){
        Object.values(bankMap).forEach((item)=> {
          if(item.active){
            if(Object.values(item.bankStatements).length === 0) {
              dispatch(
                SnackBarActions.openSnackBar({
                  message: "Atleast 1 file required for every bank details",
                  messageType: SnackBarMessageType.ERROR,
                })
              );
              isFormValid = false;
              return;
            }
            Object.values(item.bankStatements).forEach((bankStatement)=> {
              if(bankStatement.active){
                activeFilesCount += 1;
                return;
              }
            });
            if(activeFilesCount === 0){
                dispatch(
                  SnackBarActions.openSnackBar({
                    message: "Atleast 1 file must be active for every bank details",
                    messageType: SnackBarMessageType.ERROR
                  })
                )
                isFormValid = false;
                return;
            }
            Object.values(item.bankStatements).forEach((bankStatement)=> {
              if(bankStatement.active && bankStatement.isFileVerified === false){
                dispatch(
                  SnackBarActions.openSnackBar({
                    message: "Please Verify all the files",
                    messageType: SnackBarMessageType.ERROR,
                  })
                );
                isFormValid = false;
                return;
              }
            });
          }
          activeFilesCount = 0;
        });
      }
        
      if(isFormValid && isBankDetailsTextFieldValid){
        Object.values(bankMap).forEach((item)=>{
          if(item.active){
            let monthlyDrawingPowerLimitList = [];
            let attachments = [];
            Object.values(item.monthlyDrawingPowerLimit).forEach((powerLimit)=>{
              if(powerLimit.from !== "" || powerLimit.limit !== ""){
                monthlyDrawingPowerLimitList.push({
                  from: powerLimit.from,
                  limit: powerLimit.limit
                });
              }
            })
            Object.values(item.bankStatements).forEach((bankStatement)=>{
              attachments.push(bankStatement.fileId);
            })
            V2BusinessScoreRequestBankApi.updateBank({
              bsrId: params.bsrId,
              bankId: item.bankId,
              accountType: item.accountType,
              accountNumber: item.accountNumber,
              sanctionLimit: item.sanctionLimit,
              monthlyDrawingPowerLimit: monthlyDrawingPowerLimitList,
              bankAccName: item.bankAccountHolderName
            }).then((response)=>{
              let bankItems = {...bankMap};
              bankItems[item.id].businessBankId = response.businessBankId;
              bankItems[item.id].bankNameIsValid = true;
              bankItems[item.id].accountNumberIsValid = true;
              bankItems[item.id].accountTypeIsValid = true;
              updateBankMap(bankItems);
            }).catch((reject)=>{
              dispatch(
                SnackBarActions.openSnackBar({
                  message: reject.errorMessage,
                  messageType: SnackBarMessageType.ERROR,
                })
              )
            })
          }
        })
        props.updateBankDetailsVerifiedStatus(true);
        dispatch(stepperActions.setStepCompletedOrNot({step: steps.activeStep, completed: true}))
        dispatch(stepperActions.setStepSkippedOrNot({step: steps.activeStep, skipped: false}));
        dispatch(stepperActions.setActiveStep({value: steps.activeStep + 1}));
      }
    };
    props.bankDetailsRef.current = onSubmitHandler

  
    return (
      <Paper elevation={3}>
        <Container>
          {openModelForAddBankAccount && <AddGstinBankDetailsComponent
            open={openModelForAddBankAccount}
            addNewUnit={addNewBankDetailsClickHandler}
            handleClose={setOpenModelForAddBankAccount}
            addUnitFromMasterData={addBankAccountFromMasterDataHandler}
            updateMasterData={updateBankMasterData}
            masterData={bankMasterDataMap}
            type="bank"
          />}
          <Grid container padding={2} pt={5} alignItems="center">
            <Grid item xs={6}>
            {currentOrg?.organisation?.id !== undefined && (
            <Typography variant="h6">
              Bank Accounts <br />
            </Typography>
          )}
            </Grid>
            <Grid item xs={6} textAlign="end">
              <Button
              color="primary" 
                variant="outlined"
                size="small"
                onClick={()=> setOpenModelForAddBankAccount(true)}
              >
                Add Bank
              </Button>
            </Grid>
          </Grid>
          
  
          <form noValidate onSubmit={onSubmitHandler}>
          {!loading && <List key="banks" sx={{paddingTop: 6, padding: 2, paddingBottom: 6}}>
              {Object.values(bankMap).map((item, index)=> {
                return (<GBSBankStatementItemComponent
                  item={item}
                  key={item.id}
                  index={index}
                  bsrId={params.bsrId}
                  bankNames={bankNames}
                  updateBankEditModeStatus={updateBankEditModeStatus}
                  updateBankMap={updateBankMap}
                  updateBankActiveStatus={updateBankActiveStatus}
                  updateBankStatements={updateBankStatements}
                  removeBankHandler={removeBankHandler}
                  updateSingleBankDetails={updateSingleBankDetails}
                />
                );
              })}
              
              {/* <ListItem key="add_new_item" sx={{margin: 3}}>
              <ListItemSecondaryAction>
                <Tooltip title="Add New" arrow>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={addNewBankDetailsClickHandler}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem> */}
            </List>}                  
          </form>
          {loading && <CircularProgress mx={2} size={25} />}
        </Container>
      </Paper>
    );
  };
  
  export default GBSBankStatementComponent;
  