import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class ApiClientsApi {
  static path = "v1/api-client";

  static search = ({ searchMode, page, records, filter, select, sort }) => {
    let path = ApiClientsApi.path + "/search";
    let command = {
      searchMode: searchMode,
      page: page,
      records: records,
    };
    if (filter && Object.keys(filter).length > 0) {
      command.filter = filter;
    }
    if (select && Object.keys(select).length > 0) {
      command.select = select;
    }
    if (sort && Object.keys(sort).length > 0) {
      command.sort = sort;
    }
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static create = ({ name }) => {
    let path = ApiClientsApi.path;
    const command = {
      name: name,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static get = ({ requestId }) => {
    let path = ApiClientsApi.path + "/" + requestId;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static toogleActive = ({ apiClientId, active }) => {
    let path = ApiClientsApi.path;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PATCH,
      command: { apiClientId: apiClientId, active: active },
    });
  };
}
