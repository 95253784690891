import {
  Button,
  Grid, TextField, CircularProgress, DialogTitle, DialogActions, Dialog, DialogContent
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SnackBarActions } from "../../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../../util/Constant";
import SystemDefault from "../../../util/SystemDefault";
import StringUtil from "../../../util/StringUtil";

import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { blockInvalidNumber } from "../../../util/Constant";
import V2BusinessScoreRequestGstinApi from "../../../http/v2_apis/BusinessScoreRequestGstinApi";
import { stepperActions } from "../../../store/StepperSlice";

const GBSGstinEditModelComponent = (props) => {

  const [tempGstin, setTempGstin] = useState("");
  const [tempGstinIsTouch, setTempGstinIsTouch] = useState(false);
  const [tempGstinIsValid, setTempGstinIsValid] = useState(false);
  const tempGstinInvalid = !tempGstinIsValid && tempGstinIsTouch;
  const [tempGstinErrMsg, setTempGstinErrMsg] = useState("");

  const [tempUsername, setTempUsername] = useState();
  const [tempUsernameIsTouch, setTempUsernameIsTouch] = useState(false);
  const [tempUsernameIsValid, setTempUsernameIsValid] = useState(false);
  const tempUsernameInvalid = !tempUsernameIsValid && tempUsernameIsTouch;
  const [tempUsernameErrMsg, setTempUsernameErrMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setCreateMode(props.item.createMode);
    setEditMode(props.item.editMode);
    if (props.item.gstin !== "") {
      setTempGstin(props.item.gstin);
      setTempGstinIsTouch(true);
      setTempGstinIsValid(true);
    }
    if (props.item.gstinUsername !== "") {
      setTempUsername(props.item.gstinUsername);
      setTempUsernameIsTouch(true);
      setTempUsernameIsValid(true);
    }
  }, [props.item])


  const useStyles = makeStyles({
    inputFieldClass: {
      // minWidth: 230
      width: 250,
      // maxWidth: 270
    }
  });

  const classes = useStyles()

  const gstinInputChangeHandler = (event) => {
    setTempGstinIsTouch(true);
    setTempGstin(event.target.value);

    const gstinValidationResult = StringUtil.isValidGstinFormatWithPan(
      event.target.value.trim(),
      props.pan
    );
    if (gstinValidationResult.result) {
      setTempGstinIsValid(true);
      setTempGstinErrMsg("");
    } else {
      setTempGstinIsValid(false);
      setTempGstinErrMsg(gstinValidationResult.errMsg);
    }
  }

  const usernameInputChangeHandler = (event) => {
    setTempUsernameIsTouch(true);
    setTempUsername(event.target.value);

    if (event.target.value.length === 0) {
      setTempUsernameIsValid(false);
      setTempUsernameErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else {
      setTempUsernameIsValid(true);
      setTempUsernameErrMsg("")
    }
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let isFormValid = true;
    setTempGstinIsTouch(true);
    setTempUsernameIsTouch(true);
    if (!tempGstinIsValid || !tempUsernameIsValid) {
      isFormValid = false;
      return;
    }
    
    if (isFormValid) {
      setLoading(true);
        V2BusinessScoreRequestGstinApi.addGstin({
          bsrId: props.bsrId,
          gstin: tempGstin,
          username: tempUsername
        }).then((response)=> {
          props.item.createMode = false;
          props.item.editMode = false;
          props.item.gstin = tempGstin;
          props.item.gstinIsValid = true;
          props.item.gstinUsername = tempUsername;
          props.item.gstinUsernameIsValid = true;
          props.item.otpVerified = false;
          props.item.otpStatus = response.otpStatus;
  
          props.updateSingleGstinDetails(props.item.id, props.item);
          handleClose(false);
          dispatch(stepperActions.changeAccordianId({value: props.item.id}));
          props.resetResendOtpTime();
          setLoading(false);
        }).catch((error)=> {
          dispatch(
            SnackBarActions.openSnackBar({
              message: error.errorMessage,
              messageType: SnackBarMessageType.ERROR
            })
          )
          setLoading(false);
        })
    }
  }

  const handleClose = (createModeValue) => {
    if (createModeValue) {
      props.gstinRemoveHandler(props.item.id);
      setEditMode(false);
    } else {
      props.changeEditModeHandler(props.item.id, false);
    }
  }


  return (
    <Dialog
      open={editMode}
      onClose={() => handleClose(createMode)}
      maxWidth="md"
    // aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="edit-bank-details-title" onClose={handleClose} >Enter GSTIN Details</DialogTitle>
      <DialogContent style={{ padding: 30, alignItems: "center" }}>

        <Grid container spacing={4}>
          <Grid item container spacing={3}>
            <Grid item>
              <TextField
                id="gstin"
                required
                label="GSTIN"
                onKeyDown={blockInvalidNumber}
                inputProps={{ min: 0 }}
                value={tempGstin}
                disabled={!createMode}
                error={tempGstinInvalid}
                helperText={tempGstinErrMsg}
                className={classes.inputFieldClass}
                onChange={gstinInputChangeHandler}
              />
            </Grid>
            <Grid item>
              <TextField
                id="gstinUsername"
                required
                label="Username By GSTN"
                value={tempUsername}
                error={tempUsernameInvalid}
                helperText={tempUsernameErrMsg}
                className={classes.inputFieldClass}
                onChange={usernameInputChangeHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(createMode)} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmitHandler}>
          {loading && <CircularProgress sx={{ mx: 2 }} size={25} />}
          {!loading && "Save & Generate OTP"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GBSGstinEditModelComponent;
