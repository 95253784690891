import axios from "axios";
import StatusCodes from "http-status-codes";
import AppUtil, { refreshJwtToken } from "./AppUtil";
import { HttpMethodType } from "./HttpMethodType";
import { logoutUtil } from "./LogoutUtil";
import { CallType } from "./Constant";

export default class RESTUtil {
  static axiosInstance = axios.create({ baseURL: AppUtil.getApiBaseUrl() });
  // static axiosInstance = axios.create({ baseURL: "https://4832c5be-6ed1-475f-b652-89acd82454d2.mock.pstmn.io/v2/" });
  // static axiosInstance = axios.create({ baseURL: "https://537b5ebe-efc9-404a-878f-d71449d3b245.mock.pstmn.io/v2/" });
  // static axiosInstance = axios.create({ baseURL: "https://3ff4ac7d-d493-4b98-94b7-7de15c06201d.mock.pstmn.io/v2/" });
  // static axiosInstance = axios.create({ baseURL: "https://6cd2d1a9-642c-4a40-b72f-31242dffb038.mock.pstmn.io/v2/" });

  static callApi = ({
    path,
    params = {},
    httpMethodType,
    command,
    callType = CallType.SELF_ORG,
    forOrgId = null,
    isDownloadRequest = false,
  }) => {
    this.axiosInstance.interceptors.request.use(refreshJwtToken);
    return RESTUtil.callScopedAPI({
      path,
      params,
      httpMethodType,
      command,
      isDownloadRequest,
      callType,
      forOrgId,
    });
  };
  static callScopedAPI = ({
    path,
    params = {},
    httpMethodType,
    command,
    callType,
    forOrgId,
    isDownloadRequest = false,
  }) =>
    new Promise((resolve, reject) => {
      let request = {};
      // attach command
      if (command) {
        request = command;
      }
      const requestHeaders = {};
      let orgId = AppUtil.getCurrentOrgId();
      let jwtToken = AppUtil.getJwtToken();
      if (orgId !== undefined && orgId !== null) {
        requestHeaders["org-id"] = orgId;
      }
      if (jwtToken !== undefined && jwtToken !== null) {
        requestHeaders["Authorization"] = jwtToken;
      }
      requestHeaders["source-token"] = AppUtil.getGbsAppSourceToken();
      requestHeaders["call-type"] = callType;
      if (forOrgId != null && forOrgId !== undefined)
        requestHeaders["for-org-id"] = forOrgId;
      // create post url
      const url = `/${path}`;
      // make request
      RESTUtil.requestExecutor({
        url,
        request,
        headers: requestHeaders,
        httpMethodType,
        params,
        isDownloadRequest,
      }).then((response) => {
        const { status, data } = response;

        switch (status) {
          case StatusCodes.OK:
            if (isDownloadRequest === true) {
              resolve(data);
            } else {
              if (data["status"] === 1) {
                resolve(data["data"]);
              } else {
                let errorResponse = response.data["error"];
                while (true) {
                  let errorData = errorResponse["args"];
                  if (
                    Object.keys(errorData).length === 0 ||
                    !("message" in errorData)
                  ) {
                    reject({
                      errorMessage: errorResponse["message"],
                      errorData: errorResponse,
                    });
                    break;
                  }
                  errorResponse = errorData;
                }
              }
            }

            break;

          case StatusCodes.UNAUTHORIZED:
            logoutUtil();
            if (data) {
              reject({ error: "UNAUTHORIZED" });
            }
            break;

          case StatusCodes.SERVICE_UNAVAILABLE:
            if (data) {
              reject({ error: "SERVICE_UNAVAILABLE" });

              //  todo: handle additional error here
            }
            // TODO: 404 page
            break;

          case StatusCodes.INTERNAL_SERVER_ERROR:
            // if (Object.values(response.data["error"].args).length === 0) {
            //   reject({
            //     errorMessage: response.data["error"].message,
            //     errorData: response.data["error"].args,
            //   });
            // } else {
            //   reject({
            //     errorMessage: response.data["error"].args.message,
            //     errorData: response.data["error"].args,
            //   });
            // }
            if (data) {
              let errorResponse = response.data["error"];
              while (true) {
                let errorData = errorResponse["args"];
                if (Object.keys(errorData).length === 0) {
                  reject({
                    errorMessage: errorResponse["message"],
                    errorData: errorResponse,
                  });
                  break;
                }
                errorResponse = errorData;
              }
            }

            break;

          case StatusCodes.FORBIDDEN:
            // if (Object.values(response.data["error"].args).length === 0) {
            //   reject({
            //     errorMessage: response.data["error"].message,
            //     errorData: response.data["error"].args,
            //   });
            // } else {
            //   reject({
            //     errorMessage: response.data["error"].args.message,
            //     errorData: response.data["error"].args,
            //   });
            // }
            if (data) {
              let errorResponse = response.data["error"];
              while (true) {
                let errorData = errorResponse["args"];
                if (Object.keys(errorData).length === 0) {
                  reject({
                    errorMessage: errorResponse["message"],
                    errorData: errorResponse,
                  });
                  break;
                }
                errorResponse = errorData;
              }
            }
            break;
          case StatusCodes.BAD_REQUEST:
            if (Object.values(response.data["error"].args).length === 0) {
              reject({
                errorMessage: response.data["error"].message,
                errorData: response.data["error"].args,
              });
            } else {
              reject({
                errorMessage: response.data["error"].args.message,
                errorData: response.data["error"].args,
              });
            }
            break;
          default:
            reject("errorCode", "apiError.errorMessage", "apiError.errorData");
            break;
        }
      });
    });

  static requestExecutor = ({
    url,
    request,
    headers,
    httpMethodType,
    params,
    isDownloadRequest,
  }) => {
    switch (httpMethodType) {
      case HttpMethodType.GET:
        if (isDownloadRequest) {
          return RESTUtil.axiosInstance.get(url, {
            headers,
            validateStatus: () => true,
            params,
            responseType: "blob",
          });
        } else {
          return RESTUtil.axiosInstance.get(url, {
            headers,
            validateStatus: () => true,
            params,
          });
        }

      case HttpMethodType.POST:
        return RESTUtil.axiosInstance.post(url, request, {
          headers,
          validateStatus: () => true,
        });
      case HttpMethodType.PUT:
        return RESTUtil.axiosInstance.put(url, request, {
          headers,
          validateStatus: () => true,
        });
      case HttpMethodType.DELETE:
        return RESTUtil.axiosInstance.delete(url, {
          headers,
          validateStatus: () => true,
        });
      case HttpMethodType.PATCH:
        return RESTUtil.axiosInstance.patch(url, request, {
          headers,
          validateStatus: () => true,
        });
      default:
        break;
    }
    return false;
  };
}
