import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import RoutePath from "../../util/RoutePath";
import { useHistory } from "react-router-dom";

const GetGBSComponent = () => {
  const history = useHistory();
  const routeChange = () => {
    history.push(RoutePath.APP_LOGIN);
  };

  return (
    <Box display="flex" flexWrap="nowrap">
      <Box bgcolor="white" m={6} width={1 / 2}>
        <img
          src="/assets/images/Group27.png"
          alt="mobile"
          width="100%"
          height="100%"
        />
      </Box>
      <Box m={6} width={1 / 2}>
        <Stepper orientation="vertical">
          <Step key="1">
            <StepLabel>
              <Box fontSize={18}> login with GBS application</Box>
            </StepLabel>
          </Step>

          <Step key="2">
            <StepLabel>
              <Box fontSize={18}>Select Your Organisation</Box>
            </StepLabel>
          </Step>
          <Step key="3">
            <StepLabel>
              <Box fontSize={18}>Provide GST Details</Box>
            </StepLabel>
          </Step>
        </Stepper>
        <Box my={4}>
          <Typography variant="h6">
            <Box fontWeight="fontWeightBold">
              And get your GBS score instantly!
            </Box>
          </Typography>
        </Box>

        <Box mt={10} mx={0}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={routeChange}
          >
            <h2>Login in GBS</h2>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GetGBSComponent;
