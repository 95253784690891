import * as React from "react";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Paper,
  Switch,
} from "@mui/material";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import SystemDefault from "../../util/SystemDefault";
import PaginationStatusBarComponent from "../common/PaginationStatusBarComponent";
import ListingCardComponent from "../common/ListingCardComponent";
import DateUtil from "../../util/DateUtil";
import { useDispatch } from "react-redux";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";
import ApiClientsApi from "../../http/v1_apis/ApiClients";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const ApiClientListingComponent = () => {
  const dispatch = useDispatch();
  const [clients, setClients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [records, setRecords] = React.useState(SystemDefault.PAGE_SIZE[0]);
  const [pageCount, setPageCount] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);
    setClients([]);
    ApiClientsApi.search({
      searchMode: SystemDefault.SEARCH_MODE,
      page: page,
      records: records,
      filter: {},
      select: {},
      sort: { createdAtDesc: true },
    })
      .then((response) => {
        setClients(response.results);
        setPageCount(Math.ceil(response.total / records));
        setLoading(false);
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        setLoading(false);
      });
  }, [page, records, dispatch]);

  const onPageChangeHandler = (value) => {
    setPage(value);
  };
  const onRecordsChangeHandler = (value) => {
    setRecords(value);
  };

  const copyTokenBtnClickHandler = (value) => {
    navigator.clipboard.writeText(value);
    dispatch(
      SnackBarActions.openSnackBar({
        message: "Source Token Copied",
        messageType: SnackBarMessageType.INFO,
      })
    );
  };

  const toogleApiClientActiveState = (apiClient) => {
    ApiClientsApi.toogleActive({
      apiClientId: apiClient.id,
      active: !apiClient.active,
    })
      .then(() => {
        setClients(
          clients.map((client) => {
            if (client.id === apiClient.id) {
              client.active = !client.active;
            }
            return client;
          })
        );
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });
  };

  const toogleSwitch = (active, apiClient) => (
    <>
      <FormControl component="fieldset" variant="standard">
        <Switch
          checked={active}
          onChange={() => toogleApiClientActiveState(apiClient)}
        />
        <FormHelperText>{active ? "Deactivate" : "Activate"}</FormHelperText>
      </FormControl>
    </>
  );
  const loadData = () => {
    return (
      <React.Fragment>
        {clients?.map((client) => {
          return (
            <ListingCardComponent
              key={client.id}
              item={client}
              title={client.name}
              subTitleHeader1="Created at"
              subTitleValue1={DateUtil.convertToDateFormat(client.createdAt)}
              subTitleHeader2="Source Token"
              subTitleValue2={
                <b>
                  {client.sourceToken}
                  <ContentCopyRoundedIcon
                    onClick={() => {
                      copyTokenBtnClickHandler(client.token);
                    }}
                    fontSize="small"
                  />
                </b>
              }
              column1={toogleSwitch(client.active, client)}
            />
          );
        })}
        <PaginationStatusBarComponent
          pageCount={pageCount}
          page={page}
          onPageChangeHandler={onPageChangeHandler}
          records={records}
          onRecordsChangeHandler={onRecordsChangeHandler}
        />
      </React.Fragment>
    );
  };

  return (
    <Paper elevation={3}>
      {loading && <CircularProgress size={24} />}
      {!loading && clients?.length <= 0 && <NoDataFoundComponent />}
      {loadData()}
    </Paper>
  );
};

export default ApiClientListingComponent;
