import * as React from "react";
import { Chip, CircularProgress, Typography } from "@mui/material";
import SystemDefault from "../../util/SystemDefault";
import RefererReferredApi from "../../http/v1_apis/RefererReferredOrgApi";
import PaginationStatusBarComponent from "../common/PaginationStatusBarComponent";
import { BorrowerFileDetailUploadStatus, BorrowerFileDetailUploadStatusByValue } from "../../util/Constant";
import ListingCardComponent from "../common/ListingCardComponent";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import BasicPopover from "../common/PopoverComponent";


const BorrowerFileDetailListingComponent = (props) => {
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(0);
    const [records, setRecords] = React.useState(SystemDefault.PAGE_SIZE[0]);
    const [loading, setLoading] = React.useState();
    const [borrowerFileDetailData, setBorrowerFileDetailData] = React.useState([]);

    const params = useParams();

    React.useEffect(() => {
        setLoading(true);
        RefererReferredApi.searchReferredDetail({
            searchMode: SystemDefault.SEARCH_MODE,
            page: page,
            records: records,
            filter: {refererReferredOrgFileId:params.borrowerFileId},
            select: {},
            sort: { createdAtDesc: true },
        }).then((response) => {
            setBorrowerFileDetailData(response.results);
            setPageCount(Math.ceil(response.total / records));
            setLoading(false);
        });
    }, [page, records, params.borrowerFileId]);

    const onPageChangeHandler = (value) => {
        setPage(value);
    };

    const onRecordsChangeHandler = (value) => {
        setRecords(value);
    };

    const loadData = () => {
        return (
            <React.Fragment>
                {borrowerFileDetailData?.map((item) => {
                    return (
                        <ListingCardComponent
                            key={item.id}
                            item={item}
                            title={item.referredName}
                            subTitle1={
                                <Typography variant="caption" display="inline">
                                    PAN : {item.pan}
                                </Typography>}
                            subTitle2={
                                <Typography variant="caption" display="inline">
                                    Email : {item.adminEmail}
                                </Typography>}
                            column2={
                                <Chip
                                    label={BorrowerFileDetailUploadStatusByValue(item.status).status}
                                    color={BorrowerFileDetailUploadStatusByValue(item.status).color}
                                />
                            }
                            column1={
                                (BorrowerFileDetailUploadStatus.PROCESSED_WITH_ERROR===item.status || BorrowerFileDetailUploadStatus.ERROR===item.status) &&
                                <BasicPopover content={item.message}/>
                            }
                        />
                    );
                })}
                <PaginationStatusBarComponent
                    pageCount={pageCount}
                    page={page}
                    onPageChangeHandler={onPageChangeHandler}
                    records={records}
                    onRecordsChangeHandler={onRecordsChangeHandler}
                />
            </React.Fragment>
        );
    };


    return (
        <Paper elevation={1}>
            {loading && <CircularProgress size={24} />}
            {!loading && borrowerFileDetailData?.length <= 0 && <NoDataFoundComponent />}
            {!loading && loadData()}
        </Paper>
    );
};

export default BorrowerFileDetailListingComponent;
