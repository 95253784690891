import { configureStore } from "@reduxjs/toolkit";
import industrySectorReducer from "./IndustrySectorSlice";
import bankNameReducer from "./BankNameSlice";
import loaderReducer from "./LoaderSlice";
import jwtTokenReducer from "./JwtTokenSlice";
import currentOrgReducer from "./CurrentOrgSlice";
import currentUserInfoReducer from "./CurrentUserInfoSlice";
import myOrgReducer from "./MyOrgSlice";
import appBarReducer from "./AppBarSlice";
import snackBarReducer from "./SnackBarSlice";
import stepperReducer from "./StepperSlice";

const store = configureStore({
  reducer: {
    industrySector: industrySectorReducer,
    bankName: bankNameReducer,
    loader: loaderReducer,
    jwtToken: jwtTokenReducer,
    currentOrg: currentOrgReducer,
    currentUserInfo: currentUserInfoReducer,
    myOrg: myOrgReducer,
    appBar: appBarReducer,
    snackBar: snackBarReducer,
    // gstinDetails: gstinDetailsReducer,
    // bankDetails: bankDetailsReducer,
    stepper: stepperReducer,
  },
  devTools: true
});

export default store;
