export default class RoutePath {
  static APP_LOGIN = "/login";
  static APP_SIGNUP = "/signup";
  static APP_SIGNUP_MOBILE_APP = "/signup-mobile-app";
  static APP_CLOSE_TAB_MOBILE_APP = "/close-tab-mobile-app";
  static APP_FORGOT_PASS = "/forgot-password";
  static GBS_LANDING = "/gbs";
  static GBS_ROUTE = "/gbs/route";
  static APP_BASE = "/";
  static DISABLE_ACCOUNT = "/disable-account";
  static KEY_HOME = "/home";
  static KEY_ORG = "/org";
  static KEY_BUSINESS = "/business";
  static KEY_CLIENT = "/api-client";
  static KEY_PLATFORM = "/platform";
  static KEY_GBS = "/gbs";
  static KEY_REPORT = "/report";
  static KEY_CREATE = "/create";
  static KEY_EDIT = "/edit";
  static KEY_DETAILS = "/details";
  static KEY_DASHBOARD = "/dashboard";
  static KEY_BORROWER = "/borrower";
  static KEY_BORROWER_UPLOAD = "/upload";
  static KEY_BORROWER_UPLOAD_STATUS = "/upload-status";
  static KEY_SINGLE_BORROWER_UPLOAD = "/single-upload";
  static KEY_PURCHASE_REPORT = "/purchase-report";
  static KEY_SALES_REPORT = "/sales-report";
  static KEY_PROFILE_DETAILS = "/profile-details";
  static KEY_PLATFORM_ADMIN = "/platform-admin";

  //ORG Route
  static APP_ORG = RoutePath.KEY_ORG;
  static APP_ORG_CREATE = RoutePath.APP_ORG + RoutePath.KEY_CREATE;
  static APP_ORG_DETAIL = RoutePath.APP_ORG + "/:orgId";
  static APP_ORG_DASHBOARD = RoutePath.APP_ORG_DETAIL + RoutePath.KEY_DASHBOARD;
  static APP_PLATFORM_DETAIL = RoutePath.KEY_PLATFORM + "/:orgId";
  static APP_PLATFORM_BUSINESS_LIST =
    RoutePath.APP_PLATFORM_DETAIL + RoutePath.KEY_BUSINESS;
  static APP_PLATFORM_BUSINESS_DETAIL =
    RoutePath.APP_PLATFORM_BUSINESS_LIST + "/:businessId";
  static APP_PLATFORM_CLIENT_LIST =
    RoutePath.APP_PLATFORM_DETAIL + RoutePath.KEY_CLIENT;

  static APP_PLATFORM_ADMIN_DASHBOARD =
    RoutePath.APP_PLATFORM_DETAIL +
    RoutePath.KEY_PLATFORM_ADMIN +
    RoutePath.KEY_DASHBOARD;

  static APP_ORG_BORROWER = RoutePath.APP_ORG_DETAIL + RoutePath.KEY_BORROWER;

  static APP_ORG_BORROWER_DETAIL =
    RoutePath.APP_ORG_DETAIL + RoutePath.KEY_BORROWER + "/:borrowerId";

  static APP_ORG_BORROWER_FILE_LIST =
    RoutePath.APP_ORG_DETAIL + RoutePath.KEY_BORROWER_UPLOAD_STATUS;

  static APP_ORG_BORROWER_FILE_DETAIL_LIST =
    RoutePath.APP_ORG_DETAIL +
    RoutePath.KEY_BORROWER_UPLOAD_STATUS +
    "/:borrowerFileId";

  //GBS Route
  static APP_GBS = RoutePath.APP_ORG_DETAIL + RoutePath.KEY_GBS;
  static APP_GBS_CREATE = RoutePath.APP_GBS + "/:bsrId" + RoutePath.KEY_EDIT;
  static APP_GBS_DETAILS =
    RoutePath.APP_GBS + "/:bsrId" + RoutePath.KEY_DETAILS;

  //Report Route
  static APP_SHURI_REPORT_LIST =
    RoutePath.APP_ORG_DETAIL + RoutePath.KEY_REPORT;
  static APP_SHURI_REPORT_CREATE =
    RoutePath.APP_SHURI_REPORT_LIST + RoutePath.KEY_CREATE + "-:reportType";
  // static APP_SHURI_SALES_REPORT_CREATE = RoutePath.APP_ORG_DETAIL + "/:reportType"+ "-report" + RoutePath.KEY_CREATE;

  static routeAppOrgDetail({ orgId }) {
    return RoutePath.APP_ORG + "/" + orgId;
  }

  static routeAppOrgDashboard({ orgId }) {
    return (
      RoutePath.routeAppOrgDetail({ orgId: orgId }) + RoutePath.KEY_DASHBOARD
    );
  }

  static routeAppPlatformDetail({ orgId }) {
    return RoutePath.KEY_PLATFORM + "/" + orgId;
  }

  static routeAppPlatformBusinessListing({ orgId }) {
    return (
      RoutePath.routeAppPlatformDetail({ orgId: orgId }) +
      RoutePath.KEY_BUSINESS
    );
  }

  static routeAppPlatformBusinessDetail({ platformId, businessId }) {
    return (
      RoutePath.routeAppPlatformBusinessListing({ orgId: platformId }) +
      "/" +
      businessId
    );
  }

  static routeAppPlatformClientsListing({ orgId }) {
    return (
      RoutePath.routeAppPlatformDetail({ orgId: orgId }) + RoutePath.KEY_CLIENT
    );
  }
  static routeAppPlatformUserDashboard({ orgId }) {
    return (
      RoutePath.routeAppPlatformDetail({ orgId: orgId }) +
      RoutePath.KEY_PLATFORM_ADMIN +
      RoutePath.KEY_DASHBOARD
    );
  }

  static routeFiBorrowerDetail({ orgId, borrowerId }) {
    return (
      RoutePath.APP_ORG +
      "/" +
      orgId +
      RoutePath.KEY_BORROWER +
      "/" +
      borrowerId
    );
  }

  static routeGstReportGeneration({ reportType }) {
    return "/" + reportType + "-report";
  }

  static routeBorrowerListingPage({ orgId }) {
    return RoutePath.APP_ORG + "/" + orgId + RoutePath.KEY_BORROWER;
  }

  static routeBorrowerFileListingPage({ orgId }) {
    return (
      RoutePath.APP_ORG + "/" + orgId + RoutePath.KEY_BORROWER_UPLOAD_STATUS
    );
  }

  static routeBorrowerFileDetailListingPage({ orgId, borrowerFileId }) {
    return (
      RoutePath.APP_ORG +
      "/" +
      orgId +
      RoutePath.KEY_BORROWER_UPLOAD_STATUS +
      "/" +
      borrowerFileId
    );
  }

  static routeAppOrgGbsEdit({ bsrId, orgId }) {
    return (
      RoutePath.routeAppOrgDetail({ bsrId: bsrId, orgId: orgId }) +
      RoutePath.KEY_GBS +
      "/" +
      bsrId +
      RoutePath.KEY_EDIT
    );
  }

  static routeAppOrgGbsViewDetails({ bsrId, orgId }) {
    return (
      RoutePath.routeAppOrgDetail({ bsrId: bsrId, orgId: orgId }) +
      RoutePath.KEY_GBS +
      "/" +
      bsrId +
      RoutePath.KEY_DETAILS
    );
  }

  static routeAppOrgGstReportsListingPage({ orgId }) {
    return (
      RoutePath.routeAppOrgDetail({
        orgId: orgId,
      }) + RoutePath.KEY_REPORT
    );
  }

  static routeAppOrgGstReportCreatePage({ orgId, reportType }) {
    return (
      RoutePath.routeAppOrgGstReportsListingPage({
        orgId: orgId,
      }) +
      RoutePath.KEY_CREATE +
      `-${reportType}`
    );
  }
}
