import TheodoreApiRESTUtil from "../../util/TheodoreRESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class UserApi {
  static path = "users";

  static checkUserExistenceByEmail = ({ email }) => {
    let path = "userexistence/email/" + email;
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static checkUserExistenceByMobile = ({ mobile }) => {
    let path = "userexistence/mobile/" + mobile;
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };

  static generateEmailOtp = ({ email, purpose }) => {
    let path = "users/email/otps";
    let command = { email: email, otpPurposeType: purpose };
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static generateMobileOtp = ({ mobile, purpose }) => {
    let path = "users/mobile/otps";
    let command = { mobile: mobile, otpPurposeType: purpose };
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static createUser = ({
    email,
    firstName,
    lastName,
    mobileNumber,
    emailOtp,
    mobileOtp,
    password,
  }) => {
    let path = UserApi.path;
    const command = {
      email: email,
      givenName: firstName,
      lastName: lastName,
      emailOtp: emailOtp,
      mobileOtp: mobileOtp,
      password: password,
      timezone: null,
      locale: null,
      waoptin: false,
    };
    if (mobileNumber !== null && mobileNumber.length >= 1) {
      command.mobile = mobileNumber;
    }
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };



  static getSelfOrgs = () => {
    let path = "authtokens/me";
    return TheodoreApiRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    });
  };
}
