import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserApi from "../../http/theodore/UserApi";
import { SnackBarActions } from "../../store/SnackBarSlice";
import Paper from "@mui/material/Paper";
import { SnackBarMessageType } from "../../util/Constant";
import { loaderActions } from "../../store/LoaderSlice";
import SystemDefault from "../../util/SystemDefault";

const VerifySignupOtpComponent = (props) => {
  const dispatch = useDispatch();

  // const signup_email = "mahesh+ma1@vayana.com";

  const emailOrMobile = props.email === "" ? "mobile" : "email";

  const [emailOtp, setEmailOtp] = useState("");
  const [emailOtpErrMsg, setEmailOtpErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [emailOtpTouch, setEmailOtpTouch] = useState(false);
  const [emailOtpIsValid, setEmailOtpIsValid] = useState(false);

  const [mobileOtp, setMobileOtp] = useState("");
  const [mobileOtpErrMsg, setMobileOtpErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );
  const [mobileOtpTouch, setMobileOtpTouch] = useState(false);
  const [mobileOtpIsValid, setMobileOtpIsValid] = useState(false);

  const loading = useSelector((state) => state.loader.value);
  const isDisabled = loading;

  const inputEmailOtpInvalid = !emailOtpIsValid && emailOtpTouch;
  const inputMobileOtpInvalid = !mobileOtpIsValid && mobileOtpTouch;

  const [counter, setCounter] = React.useState(90);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const emailOtpInputChangeHandler = (event) => {
    if (emailOrMobile === "email") {
      setEmailOtp(event.target.value);
      setEmailOtpTouch(true);
      if (event.target.value.trim() === "") {
        setEmailOtpIsValid(false);
        setEmailOtpErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
      } else {
        setEmailOtpIsValid(true);
        setEmailOtpErrMsg("");
      }
    } else {
      setMobileOtp(event.target.value);
      setMobileOtpTouch(true);
      if (event.target.value.trim() === "") {
        setMobileOtpIsValid(false);
        setMobileOtpErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
      } else {
        setMobileOtpIsValid(true);
        setMobileOtpErrMsg("");
      }
    }
  };

  const requestEmailOtpHandler = (event) => {
    loaderActions.startLoader();
    if (emailOrMobile === "email") {
      UserApi.generateEmailOtp({ email: props.email, purpose: "SignupOTP" })
        .then((response) => {
          loaderActions.stopLoader();
          setCounter(90);
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Otp sent on your email successfully",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
        })
        .catch((reject) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: reject.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
        });
    } else {
      UserApi.generateMobileOtp({
        mobile: props.mobileNumber,
        purpose: "SignupOTP",
      })
        .then((response) => {
          loaderActions.stopLoader();
          setCounter(90);
          dispatch(
            SnackBarActions.openSnackBar({
              message: "Otp sent on your email successfully",
              messageType: SnackBarMessageType.SUCCESS,
            })
          );
        })
        .catch((reject) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: reject.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
          dispatch(loaderActions.stopLoader());
        });
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setEmailOtpTouch(true);

    if (!(emailOtpIsValid || mobileOtpIsValid)) {
      return;
    }
    dispatch(loaderActions.startLoader());
    UserApi.createUser({
      email: props.email === "" ? null : props.email,
      firstName: props.firstName,
      lastName: props.lastName,
      mobileNumber: props.mobileNumber === "" ? null : props.mobileNumber,
      emailOtp: emailOtp === "" ? null : emailOtp,
      mobileOtp: mobileOtp === "" ? null : mobileOtp,
      password: props.password,
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader());
        props.onSuccessUserCreation();
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
        dispatch(loaderActions.stopLoader());
      });
  };

  return (
    <Paper elevation={3}>
      <Container component="main" maxWidth="xs">
        <Box
          pb={4}
          pt={5}
          sx={{
            marginTop: 18,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img height={40} src="/assets/images/vay.png" alt="mobile" />
          <br />
          <Typography component="h1" variant="h5" marginBottom={4}>
            Sign Up
          </Typography>
          <form onSubmit={onSubmitHandler}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label={
                    emailOrMobile === "email" ? "Email Address" : "Mobile Number"
                  }
                  name="emailOrMobile"
                  defaultValue={
                    emailOrMobile === "email" ? props.email : props.mobileNumber
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item> </Grid>
                  <Grid item>
                    {counter === 0 ? (
                      <Link
                        to={"#"}
                        onClick={requestEmailOtpHandler}
                        variant="body2"
                      >
                        Resend OTP
                      </Link>
                    ) : (
                      counter
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  error={
                    emailOrMobile === "email"
                      ? inputEmailOtpInvalid
                      : inputMobileOtpInvalid
                  }
                  helperText={
                    emailOrMobile === "email"
                      ? inputEmailOtpInvalid && emailOtpErrMsg
                      : inputMobileOtpInvalid && mobileOtpErrMsg
                  }
                  onChange={emailOtpInputChangeHandler}
                  name="OTP"
                  required
                  fullWidth
                  id="OTP"
                  label="OTP"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  // sx={{ mt: 3, mb: 2 }}
                  disabled={isDisabled}
                >
                  Verify Otp
                  {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Paper>
  );
};

export default VerifySignupOtpComponent;
