import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import BorrowerFileDetailListingComponent from "../../component/borrower/BorrowerFileUploadDetailStatusComponent";
import TitleBarComponent from "../../component/common/TitleBarComponent";
import RefererReferredApi from "../../http/v1_apis/RefererReferredOrgApi";
import { BorrowerFileUploadStatusByValue } from "../../util/Constant";
import DateUtil from "../../util/DateUtil";

const OrgBorrowerFileDetailPage = () => {
  const [fileData, setFileData] = React.useState({});

  const params = useParams();

  React.useEffect(() => {
      RefererReferredApi.getReferredFile({ fileId: params.borrowerFileId }).then(
      (response) => {
        setFileData(response);
      }
    );
  }, [params.borrowerFileId]);

  return (
    <React.Fragment>
      <TitleBarComponent title={"Referred-Org File Upload Details"} />
      <br />
      <Paper>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box px={2} py={2}>
              {fileData?.fileName && (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                >
                  {fileData?.fileName} <br />
                </Typography>
              )}
              {fileData?.createdAt && (
                <Typography>
                  Created at :{" "}
                  {DateUtil.convertToDateFormat(fileData?.createdAt)} <br />
                </Typography>
              )}
              {fileData?.status && (
                <Typography>
                  Status :{" "}
                  {BorrowerFileUploadStatusByValue(fileData.status).status}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={1} sx={{ paddingTop: 2, marginTop: 5 }}>
        <BorrowerFileDetailListingComponent />
      </Paper>
    </React.Fragment>
  );
};

export default OrgBorrowerFileDetailPage;
