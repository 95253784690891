import { createSlice } from "@reduxjs/toolkit";

const jwtTokenSlice = createSlice({
  name: "jwtToken",
  initialState: { value: "" },
  reducers: {
    setJwtToken(state, action) {
      state.value = action.payload;
    },
    removeJwtToken(state) {
      state.value = null;
    },
  },
});

export const jwtTokenActions = jwtTokenSlice.actions;
export default jwtTokenSlice.reducer;
