import {
  Grid,
  TextField,
  Button,
  ListItem,
  Checkbox,
  InputAdornment,
  IconButton,
  Switch,
  List,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GBSBankStatementFileListItemComponent from "./GBSBankStatementFileListItemComponent";
import { SnackBarActions } from "../../../store/SnackBarSlice";
import {
  BankAccountType,
  BankAccountTypeByValue,
  PatchRequestActionStatus,
  SnackBarMessageType,
} from "../../../util/Constant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextDetailsComponent from "../../common/TextDetailsComponent";
import GBSBankDetailsEditModelComponent from "./GBSBankDetailsEditModelComponent";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import V2BusinessScoreRequestBankApi from "../../../http/v2_apis/BusinessScoreRequestBankApi";
import { stepperActions } from "../../../store/StepperSlice";
import DateUtil from "../../../util/DateUtil";

const GBSBankStatementItemComponent = (props) => {
  const [isSanctionLimitDisabled, setIsSanctionLimitDisabled] =
    useState("none");

  const accordianId = useSelector(
    (state) => state.stepper.steps[1].accordianId
  );

  const [bankStatements, setBankStatements] = useState({});
  const [monthlyDrawingPowerLimit, setMonthlyDrawingPowerLimit] = useState({});

  const [isCommonPasswordForAllFiles, setIsCommonPasswordForAllFiles] =
    useState(false);
  const [commonPassword, setCommonPassword] = useState("");
  const [
    commonPasswordCheckboxVisibility,
    setCommonPasswordCheckboxVisibility,
  ] = useState("none");
  const isCommonPasswordValid = false;
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isCommonPasswordTouch, setIsCommonPasswordTouch] = useState(false);
  const commonPasswordErrMsg = "";
  const [commonPasswordTextboxVisibility, setCommonPasswordTextboxVisibility] =
    useState("none");

  const commonPasswordInvalid =
    !isCommonPasswordValid && isCommonPasswordTouch && isPasswordError;

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setBankStatements(props.item.bankStatements);
    setMonthlyDrawingPowerLimit(props.item.monthlyDrawingPowerLimit);
    props.item.accountType === BankAccountType.CC ||
    props.item.accountType === BankAccountType.OD
      ? setIsSanctionLimitDisabled("block")
      : setIsSanctionLimitDisabled("none");
  }, [props.item.bankStatements, props.item.monthlyDrawingPowerLimit, props.item]);

  useEffect(() => {
    if (Object.values(bankStatements).length > 1) {
      setCommonPasswordCheckboxVisibility("block");
    } else {
      setCommonPasswordCheckboxVisibility("none");
    }
  },[bankStatements]);

  const useStyles = makeStyles({
    sanctionLimitClass: {
      minWidth: 200,
      marginTop: 10,
      marginLeft: 5,
      backgroundColor: "#FFFFFF",
    },
    inputFieldClass: {
      width: 250,
    },
    accordianClass: {
      border: "1px solid #f7f7f7",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
    },
    accordianSummaryClass: {
      backgroundColor: "rgba(0,0,0,0.02)",
      borderBottom: "1px solid #cdcdcd",
      color: "rgba(0,0,0,0.5)",
    },
  });

  const classes = useStyles();

  const changeBankStatementVerifiedStatus = (id, fileId, value) => {
    let bankStatementItems = { ...bankStatements };
    bankStatementItems[id].fileId = fileId;
    bankStatementItems[id].isFileVerified = value;
    props.updateBankStatements(props.item.id, bankStatementItems);
  };

  const bankActiveStatusChange = (event) => {
    V2BusinessScoreRequestBankApi.changeBankStatus({
      bsrId: params.bsrId,
      bankId: props.item.bankId,
      accountNumber: props.item.accountNumber,
      accountType: props.item.accountType,
      action: PatchRequestActionStatus.ACTIVE,
      value: event.target.checked ? true : false,
    })
      .then((response) => {
        props.updateBankActiveStatus(props.item.id, response.active);
      })
      .catch((error) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });
  };

  const bankStatementActiveStatusChange = (id, fileId, value) => {
    if (bankStatements[id].isFileVerified) {
      V2BusinessScoreRequestBankApi.changeBankStatementActiveStatus({
        bsrId: params.bsrId,
        attachmentId: fileId,
        action: PatchRequestActionStatus.ACTIVE,
        value: value,
      })
        .then((response) => {
          let bankStatementItems = { ...bankStatements };
          bankStatementItems[id].active = response.active;
          props.updateBankStatements(props.item.id, bankStatementItems);
        })
        .catch((error) => {
          dispatch(
            SnackBarActions.openSnackBar({
              message: error.errorMessage,
              messageType: SnackBarMessageType.ERROR,
            })
          );
        });
    } else {
      let bankStatementItems = { ...bankStatements };
      delete bankStatementItems[id];
      props.updateBankStatements(props.item.id, bankStatementItems);
    }
  };

  const bankStatementUploadChange = (event) => {
    const file = event.target.files[0];
    let activeBankStatementsCount = 0;
    let uploadChangeIsValid = true;

    Object.values(bankStatements).forEach((bankStatement) => {
      if (bankStatement.active) {
        activeBankStatementsCount += 1;
      }
    });
    if (file.type !== "application/pdf") {
      dispatch(
        SnackBarActions.openSnackBar({
          message: "Only PDFs are allowed",
          messageType: SnackBarMessageType.ERROR,
        })
      );
      uploadChangeIsValid = false;
      return;
    }
    if (uploadChangeIsValid && activeBankStatementsCount > 11) {
      dispatch(
        SnackBarActions.openSnackBar({
          message: "Maximum 12 pdfs are allowed",
          messageType: SnackBarMessageType.ERROR,
        })
      );
      uploadChangeIsValid = false;
      return;
    }

    if (uploadChangeIsValid) {
      let bankStatementFiles = {};
      let id = uuidv4();
      bankStatementFiles = {
        ...bankStatements,
        [id]: {
          id,
          active: true,
          file: file,
          fileName: file.name,
          fileId: "",
          password: "",
          isFileVerified: false,
        },
      };
      props.updateBankStatements(props.item.id, bankStatementFiles);
      setBankStatements(bankStatementFiles);
    }
  };

  const bankDetailsRemoveHandler = (id) => {
    props.removeBankHandler(id);
  };



  const accordianViewChange = () => {
    accordianId === props.item.id
      ? dispatch(stepperActions.changeAccordianId(""))
      : dispatch(stepperActions.changeAccordianId({ value: props.item.id }));
  };

  const passwordInputChangeHandler = (event) => {
    setIsCommonPasswordTouch(true);
    setCommonPassword(event.target.value);
    if (event.target.value.trim() === "") {
      setIsPasswordError(true);
    } else {
      setIsPasswordError(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const commonPasswordCheckboxChange = (event) => {
    if (event.target.checked) {
      setCommonPasswordTextboxVisibility("block");
      setIsCommonPasswordForAllFiles(true);
    } else {
      setCommonPasswordTextboxVisibility("none");
      setIsCommonPasswordForAllFiles(false);
    }
  };



  return (
    <React.Fragment>
      {props.item.editMode && (
        <GBSBankDetailsEditModelComponent
          changeEditModeHandler={props.updateBankEditModeStatus}
          bsrId={params.bsrId}
          item={props.item}
          bankNames={props.bankNames}
          setIsSanctionLimitDisabled={setIsSanctionLimitDisabled}
          bankDetailsRemoveHandler={bankDetailsRemoveHandler}
          updateSingleBankDetails={props.updateSingleBankDetails}
          setMonthlyDrawingPowerLimit={setMonthlyDrawingPowerLimit}
        />
      )}
      {!props.item.createMode && (
        <Accordion
          className={classes.accordianClass}
          expanded={accordianId === props.item.id}
        >
          <AccordionSummary
            className={classes.accordianSummaryClass}
            expandIcon={
              <IconButton onClick={accordianViewChange}>
                <ExpandMoreIcon />
              </IconButton>
            }
          >
            {accordianId === props.item.id ? (
              <Grid container>
                <Grid item xs={6} md={8}>
                  <Typography sx={{ paddingLeft: 2 }}>
                    Bank Account Details
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4} textAlign="end" paddingRight={2}>
                  <Typography>
                    Active{" "}
                    <Switch
                      checked={props.item.active}
                      onChange={bankActiveStatusChange}
                      size="small"
                    />
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <Tooltip title={<div>{props.item.bankName}</div>} arrow>
                    <Typography sx={{ maxWidth: 300 }} noWrap>
                      <b>Bank : </b> {props.item.bankName}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={<div>{props.item.accountNumber}</div>} arrow>
                    <Typography sx={{ maxWidth: 200 }} noWrap>
                      <b>A/C : </b> {props.item.accountNumber}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Type : </b>{" "}
                    {BankAccountTypeByValue(props.item.accountType).account}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Active{" "}
                    <Switch
                      checked={props.item.active}
                      onChange={bankActiveStatusChange}
                      size="small"
                    />
                  </Typography>
                </Grid>
              </Grid>
            )}
          </AccordionSummary>
          <AccordionDetails sx={{ margin: 2, marginTop: 4 }}>
            <ListItem
              sx={{
                border: "1px solid rgba(0,0,0,0.06)",
                borderRadius: 2,
                padding: 4,
                maxWidth: "53vw",
                backgroundColor: "rgba(0,0,0,0.01)",
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {/* <Grid item container spacing={2}> */}
                  <Grid item xs={2} sm={4} md={4}>
                    <TextDetailsComponent
                      caption="Bank Name"
                      value={props.item.bankName}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextDetailsComponent
                      caption="Account Number"
                      value={props.item.accountNumber}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextDetailsComponent
                      caption="Account Type"
                      value={
                        BankAccountTypeByValue(props.item.accountType).account
                      }
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <TextDetailsComponent
                      caption="Account Holder Name"
                      value={props.item.bankAccountHolderName}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ display: isSanctionLimitDisabled }}
                    xs={2}
                    sm={4}
                    md={4}
                  >
                    <TextDetailsComponent
                      caption="Sanction Limit"
                      value={props.item.sanctionLimit}
                    />
                  </Grid>
                  {Object.values(monthlyDrawingPowerLimit).length > 0 && (
                    <Grid
                      item
                      sx={{ display: isSanctionLimitDisabled, marginLeft: -1 }}
                      xs={2}
                      sm={4}
                      md={4}
                    >
                      <Typography variant="caption">
                        Monthly Drawing Power Limit
                      </Typography>
                      <List key="monthlyLimit">
                        {Object.values(monthlyDrawingPowerLimit).map((item) => {
                          
                          return (
                            <ListItem key={item.id}>
                              <Grid container spacing={2}>
                                <Grid item>
                                  <Typography variant="subtitle2">
                                    From : <b>{item.from}</b>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="subtitle2">
                                    Limit : <b>{item.limit}</b>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  )}
                </Grid>
                

                <Grid item container spacing={2} justifyContent="end">
                  <Grid item>
                    <label htmlFor={props.item.id}>
                      <input
                        id={props.item.id}
                        name="btn-upload-bank-statements"
                        style={{ display: "none" }}
                        type="file"
                        disabled={!props.item.active}
                        onChange={bankStatementUploadChange}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                      <Button
                        disabled={!props.item.active}
                        startIcon={<FileUploadOutlinedIcon />}
                        className="btn-choose"
                        component="span"
                      >
                        Upload Statements
                      </Button>
                    </label>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={!props.item.active}
                      onClick={() =>
                        props.updateBankEditModeStatus(props.item.id, true)
                      }
                      startIcon={<EditOutlinedIcon />}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          
            <ListItem
              sx={{ marginTop: 1.5, display: commonPasswordCheckboxVisibility }}
            >
              <Grid container spacing={5} alignItems="center">
                <Grid item>
                  <Checkbox onChange={commonPasswordCheckboxChange} />
                  Same password for all the files
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    error={commonPasswordInvalid}
                    helperText={commonPasswordErrMsg}
                    onChange={passwordInputChangeHandler}
                    name="password"
                    label="Password"
                    size="small"
                    style={{
                      maxWidth: 250,
                      display: commonPasswordTextboxVisibility,
                    }}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {/* <Grid item sx={{ display:commonPasswordTextboxVisibility }}>
          {!isCommonPasswordValid && (
            <Button
              onClick={commonFilePasswordVerification}>
              Verify
            </Button>
          )}

        </Grid> */}
              </Grid>
            </ListItem>

            <ListItem>
              <List key="files" sx={{ marginLeft: -2 }}>
                {Object.values(bankStatements).map((bankStatement) => {
                  return (
                    <GBSBankStatementFileListItemComponent
                      item={bankStatement}
                      bsrId={props.bsrId}
                      bankDetailItem={props.item}
                      key={bankStatement.id}
                      bankStatementActiveStatusChange={
                        bankStatementActiveStatusChange
                      }
                      changeBankStatementVerifiedStatus={
                        changeBankStatementVerifiedStatus
                      }
                      isCommonPassword={isCommonPasswordForAllFiles}
                      commonPassword={commonPassword}
                    />
                  );
                })}
                <Typography variant="caption" display="block" gutterBottom>
                  Note: Please upload statements form {
                    DateUtil.getLastYearQuarter(DateUtil.getPreviousQuarter())
                      .from
                  } to {DateUtil.getPreviousQuarter().to}
                </Typography>
              </List>
            </ListItem>
          </AccordionDetails>
        </Accordion>
      )}
    </React.Fragment>
  );
};

export default GBSBankStatementItemComponent;
