import { Container, Link, Typography } from "@mui/material";
import React from "react";

const WriteToUsComponent = () => {
  return (
    <React.Fragment>
      <Container>
        <Typography variant="h6">
          For any queries related to GBS product, please
          <Link href="mailto:gbs-support@vayana.com"> Write to us </Link>
        </Typography>
      </Container>
    </React.Fragment>
  );
};

export default WriteToUsComponent;
