import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BusinessScoreRequestListingComponent from "../../component/business_score_request/BusinessScoreRequestListingComponent";
import * as React from "react";
import { useEffect, useState } from "react";
import { appBarActions } from "../../store/AppBarSlice";
import RoutePath from "../../util/RoutePath";
import { useHistory } from "react-router-dom";
import BusinessApi from "../../http/v1_apis/BusinessApi";
import SystemDefault from "../../util/SystemDefault";
import { loaderActions } from "../../store/LoaderSlice";
import V2BusinessScoreRequestApi from "../../http/v2_apis/BusinessScoreRequestApi";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";

const OrgDashboardPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrg = useSelector((state) => state.currentOrg);
  const [business, setBusiness] = React.useState({});
  const [industrySector, setIndustrySector] = useState({});
  const [industrySectorId, setIndustrySectorId] = useState("");
  const [industrySectorIdIsValid, setIndustrySectorIdIsValid] = useState(false);
  const [industrySectorIdTouch, setIndustrySectorIdTouch] = useState(false);
  const [updateIndustrySector, setUpdateIndustrySector] = React.useState(false);
  const industrySectorList = useSelector(
    (state) => state.industrySector.itemList
  );

  const loading = useSelector((state) => state.loader.value);
  const isDisabled = loading;

  const getBusiness = React.useCallback(() => {
    const updateMyIndustrySector = (industrySectorId) => {
      industrySectorList.forEach((item) => {
        if (item.id === industrySectorId) {
          setIndustrySector(item);
        }
      });
    };
    BusinessApi.get({
      id: currentOrg?.organisation?.id,
    })
      .then((businessResponse) => {
        setBusiness(businessResponse);
        if (businessResponse.industrySectorId === null) {
          setUpdateIndustrySector(true);
        } else {
          updateMyIndustrySector(businessResponse.industrySectorId);
        }
      })
      .catch((reject) => {
        setUpdateIndustrySector(true);
      });
  }, [currentOrg, industrySectorList]);

  useEffect(() => {
    dispatch(appBarActions.setAppBarTitle(currentOrg?.organisation?.name));
    if (currentOrg?.organisation?.id !== undefined) {
      BusinessApi.isTaxIdentifierExist({
        pan: currentOrg.organisation?.taxIdentifier,
      }).then((response) => {
        if (response.result === true) {
          getBusiness();
        } else {
          setUpdateIndustrySector(true);
        }
      });
    }
  }, [getBusiness, currentOrg, dispatch]);

  const addNewOrgClickHandler = () => {
    history.push(RoutePath.APP_ORG_CREATE);
  };

  const generateGbsrClickHandler = () => {
    V2BusinessScoreRequestApi.createBsr()
      .then((response) => {
        history.push(
          RoutePath.routeAppOrgGbsEdit({
            bsrId: response.id,
            orgId: response.organisationId,
          })
        );
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR,
          })
        );
      });
  };

  const handleClose = () => {
    // history.push(RoutePath.routeAppGbsCreate());
  };

  const industrySectorChangeHandler = (event) => {
    setIndustrySectorId(event.target.value);
    setIndustrySectorIdTouch(true);
    setIndustrySectorIdIsValid(true);
  };

  const inputIndustrySectorIdInvalid =
    !industrySectorIdIsValid && industrySectorIdTouch;

  const submitIndustrySectorHandler = () => {
    setIndustrySectorIdTouch(true);
    if (inputIndustrySectorIdInvalid) {
      return;
    }
    dispatch(loaderActions.startLoader());
    BusinessApi.updateIndustrySector({
      industrySectorId: industrySectorId,
    })
      .then(() => {
        dispatch(loaderActions.stopLoader());
        handleClose();
        setUpdateIndustrySector(false);
      })
      .catch((reject) => {
        dispatch(loaderActions.stopLoader());
      });
    // setUpdateIndustrySector(false);
  };
  const tradeNameComponent = () => {
    if (business?.legalName !== undefined && business.legalName !== null) {
      return (
        <Typography>
          Legal Name : {business?.legalName} <br />
        </Typography>
      );
    }
  };

  return (
    <React.Fragment>
      {updateIndustrySector === true && (
        <Dialog
          open={updateIndustrySector}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Select Industry Sector"}
          </DialogTitle>
          <DialogContent>
            {/*<FormControl>*/}
            <InputLabel id="industrySectorLabel">
              Select industry sector to continue
            </InputLabel>
            <Select
              error={inputIndustrySectorIdInvalid}
              labelId="demo-simple-select-label"
              id="industrySector"
              label="industrySector"
              value={industrySectorId}
              // helperText={inputIndustrySectorIdInvalid && "Incorrect entry."}
              onChange={industrySectorChangeHandler}
            >
              {industrySectorList.map((item) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText error={true}>
              {inputIndustrySectorIdInvalid &&
                SystemDefault.DEFAULT_ERROR_MESSAGE}
            </FormHelperText>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              onClick={submitIndustrySectorHandler}
              autoFocus
              disabled={isDisabled}
            >
              Submit{" "}
              {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Paper>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box px={2} py={2}>
              {currentOrg?.organisation?.name && (
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", marginBottom: 1 }}
                >
                  {currentOrg?.organisation?.name} <br />
                </Typography>
              )}
              {currentOrg?.organisation?.id !== undefined && (
                <Typography>
                  PAN : {currentOrg?.organisation?.taxIdentifier} <br />
                </Typography>
              )}
              {tradeNameComponent()}
              {industrySector?.id && (
                <Typography>
                  Industry Sector : {industrySector?.name}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2} px={2} py={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={addNewOrgClickHandler}
              >
                Create Organisation
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={generateGbsrClickHandler}
              >
                Generate GBS
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={1} sx={{ marginTop: 3 }}>
        <BusinessScoreRequestListingComponent />
      </Paper>
    </React.Fragment>
  );
};

export default OrgDashboardPage;
