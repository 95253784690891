import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class V2BusinessScoreRequestGstinApi {
  static path = "v2/business-score-request/gstin";

  static addGstin = ({ bsrId, gstin, username = null }) => {
    let path = V2BusinessScoreRequestGstinApi.path;
    const command = {
        bsrId: bsrId,
        // businessGstinId: businessGstinId,
        gstin: gstin,
        username: username,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };

  static updateGstin = ({ bsrId, gstin, username = null }) => {
    let path = V2BusinessScoreRequestGstinApi.path;
    const command = {
        bsrId: bsrId,
        // businessGstinId: businessGstinId,
        gstin: gstin,
        username: username,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PUT,
      command: command,
    });
  };

  static verifyGstin = ({ bsrId, gstin, otp }) => {
    let path = V2BusinessScoreRequestGstinApi.path + "/verify";
    const command = {
        bsrId: bsrId,
        gstin: gstin,
        otp: otp,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
    });
  };
  
  static changeGstinActiveStatus = ({ bsrId, gstin, action, value }) => {
    let path = V2BusinessScoreRequestGstinApi.path;
    const actionValue = {
      "active": value
    };
    const command = {
        bsrId: bsrId,
        gstin: gstin,
        action: action,
        value: actionValue
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.PATCH,
      command: command,
    });
  };

  static getGstinMasterData = () => {
    let path = V2BusinessScoreRequestGstinApi.path + "/master";
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
    })
  };
}
