import {
  Button,

  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import OrgCardComponent from "../component/org/OrgCardComponent";
import RoutePath from "../util/RoutePath";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentOrgActions } from "../store/CurrentOrgSlice";
import { myOrgActions } from "../store/MyOrgSlice";
import UserApi from "../http/theodore/UserApi";
import AppUtil from "../util/AppUtil";
import TitleBarComponent from "../component/common/TitleBarComponent";

const UserHomePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const myOrgList = useSelector((state) => state.myOrg);
  const loading = useSelector((state) => state.loader.value);

  const onCardClickHandler = (id) => {
    myOrgList.forEach((item) => {
      if (item.organisation.id === id) {
        dispatch(currentOrgActions.setCurrentOrg(item));
      }
    });
    if (id === AppUtil.getPlatformOrgId()) {
      history.push(RoutePath.routeAppPlatformBusinessListing({ orgId: id }));
    } else {
      history.push(RoutePath.routeAppOrgDashboard({ orgId: id }));
    }
  };

  const addNewOrgClickHandler = () => {
    history.push(RoutePath.APP_ORG_CREATE);
  };
  useEffect(() => {
    UserApi.getSelfOrgs().then((response) => {
      dispatch(myOrgActions.setMyOrg(response));
    });
  }, [dispatch]);

  return (
    <React.Fragment>
      {loading && <CircularProgress size={24} />}
      {!loading && (
        <Paper p={5}>
          <TitleBarComponent
            title="My Organisations"
            rightComponent={
              <Button variant="outlined" onClick={addNewOrgClickHandler}>
                Add Organisation
              </Button>
            }
          />

          <Grid
            container
            spacing={5}
            alignItems="flex-start"
            justifyItems="flex-start"
            py={5}
            px={5}
          >
            {myOrgList.length > 0 &&
              myOrgList.map((item) => {
                return (
                  <OrgCardComponent
                    key={item.organisation?.id}
                    org={item.organisation}
                    onCardClickHandler={onCardClickHandler}
                  />
                );
              })}
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  );
};

export default UserHomePage;
