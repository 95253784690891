import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class SupportTicketApi{
    static path = "v1/support-ticket"

    static createTicket = ({title,query, url}) => {
        let path = SupportTicketApi.path;
        const command = { title:title, query: query, url:url };
        return RESTUtil.callApi({ 
            path: path,
            httpMethodType: HttpMethodType.POST,
            command: command
        });
    };
}