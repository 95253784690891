import ShuriRESTUtil from "../../util/ShuriRESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";
import { CallType } from "../../util/Constant";
import AppUtil from "../../util/AppUtil";

export default class StatsReportApi {
  static path = "v1/stats-report";

  static getStatsStatusCount = ({ duration, apiClientId }) => {
    let path = StatsReportApi.path + "/get";

    let command = {
      filter: {
        apiClientId: apiClientId,
        duration: { type: duration },
      },
      groupBy: { statsStatus: true },
    };

    return ShuriRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
      callType: CallType.FOR_ANY_ORG,
      forOrgId: AppUtil.getCurrentOrgId(),
    });
  };

  static getMonthlyStatsData = ({ duration, apiClientId }) => {
    let path = StatsReportApi.path + "/get-monthly";

    let command = {
      filter: {
        apiClientId: apiClientId,
        duration: { type: duration },
      },
      groupBy: { statsStatus: true, year: true, month: true },
    };

    return ShuriRESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
      callType: CallType.FOR_ANY_ORG,
      forOrgId: AppUtil.getCurrentOrgId(),
    });
  };
}
