import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class BusinessScoreShareRequestApi {
    static path = "v1/share-score";

    static search = ({ searchMode, page, records, filter, select, sort }) => {
        let path = BusinessScoreShareRequestApi.path + "/search";
        let command = {
            searchMode: searchMode,
            page: page,
            records: records,
        };
        if (filter && Object.keys(filter).length > 0) {
            command.filter = filter;
        }
        if (select && Object.keys(select).length > 0) {
            command.select = select;
        }
        if (sort && Object.keys(sort).length > 0) {
            command.sort = sort;
        }
        return RESTUtil.callApi({
            path: path,
            httpMethodType: HttpMethodType.POST,
            command: command
        });
    };

    static create = ({ scoreRequestId, fiOrganisationID }) => {
        let path = BusinessScoreShareRequestApi.path;
        const command = {
            scoreRequestId: scoreRequestId,
            fiOrganisationId: fiOrganisationID
        };
        return RESTUtil.callApi({
            path: path,
            httpMethodType: HttpMethodType.POST,
            command: command,
        });
    };

    static get = ({ requestId, apiToken }) => {
        let path = BusinessScoreShareRequestApi.path + "/" + requestId;
        return RESTUtil.callApi({
            path: path,
            httpMethodType: HttpMethodType.GET,
            apiToken: apiToken,
        });
    };

    static generateReport=({scoreShareId})=>{
        let path=BusinessScoreShareRequestApi.path+"/generate-report/"+scoreShareId
        return RESTUtil.callApi({
            path: path,
            httpMethodType: HttpMethodType.GET
        });
    }
}
