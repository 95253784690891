import {
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { blockInvalidNumber } from "../../../util/Constant";
  
  const GBSBankStatementMonthlyDrawingItemComponent = (props) => {
  

    const monthlyPowerLimitRemoveHandler = () => {
        props.monthlyPowerLimitRemoveHandler(props.item.id);
      };
    
    const monthlyDrawingLimitFromInputChangeHandler = (event) => {
      props.monthlyDrawingPowerLimitFromInputHandler(props.item.id, event.target.value);
    }

    const monthlyDrawingLimitValueInputChangeHandler = (event) => {
      props.monthlyDrawingPowerLimitValueInputHandler(props.item.id, event.target.value);
    }
  
    return (
      <ListItem key={props.item.id}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TextField
              label="YYYYMM"
              type="number"
              value={props.item.from}
              onKeyDown={blockInvalidNumber}
              inputProps={{min:0}}
              style={{maxWidth:150, backgroundColor: "#FFFFFF" }}
              helperText={props.item.fromErrMsg}
              onChange={monthlyDrawingLimitFromInputChangeHandler}
              error={props.item.fromIsTouch && !props.item.fromIsValid}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Limit"
              type="number"
              value={props.item.limit}
              onKeyDown={blockInvalidNumber}
              inputProps={{min:0}}
              style={{maxWidth:150, backgroundColor: "#FFFFFF" }}
              helperText={props.item.limitErrMsg}
              onChange={monthlyDrawingLimitValueInputChangeHandler}
              error={props.item.limitIsTouch && !props.item.limitIsValid}
            />
          </Grid>
          {/* <Grid item>
          <Tooltip title="Remove" arrow>
            <IconButton edge="end" aria-label="comments" onclick={monthlyPowerLimitRemoveHandler}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          </Grid> */}
        </Grid>
        <ListItemSecondaryAction onClick={monthlyPowerLimitRemoveHandler}>
          <Tooltip title="Remove" arrow>
            <IconButton edge="end" aria-label="comments">
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };
  
  export default GBSBankStatementMonthlyDrawingItemComponent;
  