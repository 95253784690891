import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class GstinApi {
  static path = "v1/taxpayer/gstin";

  static generateOtp = ({ gstin, username, apiToken = null }) => {
    let path = GstinApi.path + "/otp";
    const command = {
      gstin: gstin,
      username: username,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
      apiToken: apiToken,
    });
  };

  static shareOtp = ({ otpRequestId, otp, apiToken }) => {
    let path = GstinApi.path + "/authenticate";
    const command = {
      otpRequestId: otpRequestId,
      otp: otp,
    };
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.POST,
      command: command,
      apiToken: apiToken,
    });
  };
}
