import { List, ListItem, ListItemIcon } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";

const BetterGBSComponent = () => {
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <CheckIcon color="primary" fontSize="large" />
        </ListItemIcon>
        By steadily increasing sales volumes and revenues
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CheckIcon color="primary" fontSize="large" />
        </ListItemIcon>
        By adding new customers
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CheckIcon color="primary" fontSize="large" />
        </ListItemIcon>
        By timely filing of GST Returns
      </ListItem>
    </List>
  );
};

export default BetterGBSComponent;
