import { Grid, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoDataFoundComponent from "../../component/common/NoDataFoundComponent";
import ProfileCardDetailsComponent from "../../component/common/ProfileCardDetailsComponent";
import TitleBarComponent from "../../component/common/TitleBarComponent";
import { appBarActions } from "../../store/AppBarSlice";
import UserHomePage from "../UserHomePage";

const ProfilePage = () => {
  const dispatch = useDispatch();

  const userDetails = useSelector(
    (state) => state?.currentUserInfo?.userDetails
  );
  const countryNameByLocale = new Intl.DisplayNames(["en"], { type: "region" });
  const countryName = userDetails?.locale
    ? countryNameByLocale.of(userDetails.locale?.split("-")[1])
    : "";

  useEffect(() => {
    dispatch(appBarActions.setAppBarTitle("Profile Details"));
  }, [dispatch]);

  return userDetails ? (
    <div>
      <Paper elevation={3}>
        <TitleBarComponent title="Personal Details" />

        {userDetails && (
          <Grid container p={3} margin={"0px auto"}>
            <Grid item xs={5}>
              <ProfileCardDetailsComponent
                dataKey="Name"
                dataValue={userDetails.givenName}
              />
              <ProfileCardDetailsComponent
                dataKey="Email"
                dataValue={userDetails.email}
              />
              <ProfileCardDetailsComponent
                dataKey="Mobile"
                dataValue={userDetails.mobile}
              />
            </Grid>
            <hr />
            <Grid item xs={5}>
              <ProfileCardDetailsComponent
                dataKey="Timezone"
                dataValue={userDetails.timezone}
              />
              <ProfileCardDetailsComponent
                dataKey="Country"
                dataValue={countryName}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
      <br />
      <UserHomePage />
    </div>
  ) : (
    <Paper
      sx={{
        marginTop: 3,
        padding: 3,
      }}
    >
      <NoDataFoundComponent />
    </Paper>
  );
};

export default ProfilePage;
