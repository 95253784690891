import SystemDefault from "./SystemDefault";

export default class StringUtil {
  static isValidEmail(email) {
    let regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (!regex.test(email)) {
      return { result: false, errMsg: "Invalid format of Email" };
    }
    return { result: true };
  }

  static isValidMobile(mobile) {
    let regex = /^\+\d+-([6-9][0-9]{9})$/;
    if (mobile && mobile.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (!regex.test(mobile)) {
      return { result: false, errMsg: "Invalid format of Mobile" };
    }
    return { result: true };
  }

  static isValidEmailMobile(emailMobile) {
    var emailResponse = this.isValidEmail(emailMobile);
    if (emailResponse.result) {
      return {
        type: "email",
        result: true,
      };
    } else {
      var mobileResponse = this.isValidMobile(emailMobile);
      if (mobileResponse.result) {
        return {
          type: "mobile",
          result: true,
        };
      } else {
        return {
          result: false,
          errMsg:
            "Invalid format of Email or Mobile. Mobile-number should contain Country-code as prefix, '+91-' for India, e.g.: +91-0000000000",
        };
      }
    }
  }

  static isValidFirstName(firstName) {
    let regex = /^[A-Za-z]+$/;

    if (firstName.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (!regex.test(firstName)) {
      return {
        result: false,
        errMsg: "Special charater & numbers are not allow in First Name",
      };
    }
    return { result: true };
  }
  static isValidLastName(lastName) {
    let regex = /^[A-Za-z]+$/;

    if (lastName.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (!regex.test(lastName)) {
      return {
        result: false,
        errMsg: "Special charater & numbers are not allow in Last Name",
      };
    }
    return { result: true };
  }
  static isValidPassword(password) {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (password.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (!strongRegex.test(password)) {
      return {
        result: false,
        errMsg:
          "Invalid format of Password should be more than 8 characters containing atleast one each of a number, uppercase letter, lowercase letter and special character.",
      };
    }
    return { result: true };
  }

  static isValidConfirmPassword(password, confirmPassword) {
    if (confirmPassword.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (password !== confirmPassword) {
      return {
        result: false,
        errMsg: "Password & Confirm password didnt Match, try again.",
      };
    }
    return { result: true };
  }

  static isValidPan(pan) {
    let regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (pan.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (pan.length !== 10) {
      return { result: false, errMsg: "Invalid length of PAN" };
    } else if (!regex.test(pan)) {
      return { result: false, errMsg: "Invalid format of PAN" };
    }
    return { result: true, errMsg: "" };
  }

  static isValidGstinFormatWithPan(gstin, pan) {
    let regex = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstin.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (gstin.length !== 15) {
      return { result: false, errMsg: "Invalid length of GSTIN" };
    } else if (!regex.test(gstin)) {
      return { result: false, errMsg: "Invalid format of GSTIN" };
    } else if (gstin.slice(2, 12) !== pan) {
      return { result: false, errMsg: "GSTIN is not linked with PAN" };
    }

    return { result: true, errMsg: "" };
  }

  static isValidText(value) {
    let regex = /^[A-Za-z0-9, .?'@]+$/;
    if (value.length === 0) {
      return { result: false, errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE };
    } else if (!regex.test(value)) {
      return { result: false, errMsg: "Special characters are not allowed" };
    }
    return { result: true, errMsg: "" };
  }
}
