import React, { useState, useEffect } from "react";
import { SnackBarActions } from "../../store/SnackBarSlice";
import { SnackBarMessageType } from "../../util/Constant";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import V2BusinessScoreRequestApi from "../../http/v2_apis/BusinessScoreRequestApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import GBSViewDetailHeaderComponent from "../../component/gbs/view_detail/GBSViewDetailHeaderComponent";
import GBSViewDetailBasicBSRInfoComponent from "../../component/gbs/view_detail/GBSViewDetailBasicBSRInfoComponent";
import GBSViewDetailMainComponent from "../../component/gbs/view_detail/GBSViewDetailMainComponent";

const GbsViewDetailsPage = () => {
  const dispatch = useDispatch();
  const [bsrDetails, setBsrDetails] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [gstinDetails, setGstinDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const bankNames = useSelector((state) => state.bankName.itemList);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    V2BusinessScoreRequestApi.getBsrDetails({
      bsrId: params.bsrId
    }).then((response) => {
      if (response.property.gstins) {
        let gstinsMap = {}
        response.property.gstins.forEach((gstin) => {
          let id = `${gstin.bsrId}_${gstin.businessGstin.id}`
          if(gstin.active){
            gstinsMap = {
              ...gstinsMap,
              [id]: {
                id: id,
                active: gstin.active,
                businessGstinId: gstin.businessGstin.id,
                gstin: gstin.businessGstin.gstin,
                gstinUsername: gstin.businessGstin.gstnUsername,
                otpStatus: gstin.otpStatus,
                createdAt: gstin.createdAt,
                updatedAt: gstin.updatedAt,
                // errMsg: SystemDefault.DEFAULT_ERROR_MESSAGE,
              }
            }
          }
        })
        setGstinDetails(gstinsMap);
      }
      if (response.property.banks) {
        let banksMap = {}
        response.property.banks.forEach((bank) => {
          let id = `${bank.bsrId}_${bank.bank.id}`
          if(bank.active){
            banksMap = {
              ...banksMap,
              [id]: {
                id: id,
                active: bank.active,
                businessBankId: bank.bank.id,
                monthlyDrawingPowerLimit: bank.drawingPowerLimits["limits"],
                sanctionLimit: bank.sanctionLimit,
                bankId: bank.bank.bankId,
                accountNumber: bank.bank.number,
                accountType: bank.bank.type,
                bankAccountHolderName: bank.bank.name,
                bankStatements: bank.property.bankStatement
              }
            }
            bankNames.forEach((item) => {
              if (item.id === banksMap[id].bankId) {
                banksMap[id].bankName = item.label;
              }
            })
          }  
        })
        setBankDetails(banksMap);
      }
      setBsrDetails({...response});
      setLoading(false);
    }).catch((error) => {
      dispatch(
        SnackBarActions.openSnackBar({
          message: error.errorMessage,
          messageType: SnackBarMessageType.ERROR
        })
      )
      setLoading(false);
    })
  }, [bankNames, dispatch, params.bsrId]);
  const indicatorSize = 80;

  return (
    <React.Fragment>
      {loading && (
        <CircularProgress
          size={indicatorSize}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: `${-indicatorSize / 2}px`,
            marginLeft: `${-indicatorSize / 2}px`,
          }}
        />
      )}
      {!loading && (
        <div sx={{ margin: 2 }}>
          <GBSViewDetailHeaderComponent bsrDetails={bsrDetails} />
          <GBSViewDetailBasicBSRInfoComponent bsrDetails={bsrDetails} />
          <GBSViewDetailMainComponent bsrDetails={bsrDetails} bankDetails={bankDetails} gstinDetails={gstinDetails} />
        </div>
      )}
    </React.Fragment>
  );
};

export default GbsViewDetailsPage;
