export const PatchRequestActionStatus = {
  ACTIVE: 0,
};

export const GstinOtpRequestStatus = {
  NONE: -10,
  TRIGGERED: "Triggered",
  ERROR_IN_TRIGGER: "ErrorInTriggering",
  VALIDATED: "Validated",
  ERROR_IN_VALIDATION: "ErrorInValidation",
};

export const GstinOtpRequestStatusByValue = (value) => {
  switch (value) {
    case GstinOtpRequestStatus.TRIGGERED:
      return { status: "OTP Triggered", color: "success" };
    case GstinOtpRequestStatus.ERROR_IN_TRIGGER:
      return { status: "Error in OTP Triggered", color: "error" };
    case GstinOtpRequestStatus.VALIDATED:
      return { status: "OTP verified", color: "success" };
    case GstinOtpRequestStatus.ERROR_IN_VALIDATION:
      return { status: "Error in OTP Verification", color: "error" };
    default:
      return { status: "None", color: "info" };
  }
};

export const BusinessScoreRequestStatus = {
  DRAFT: 0,
  INIT: 10,
  TASK_PROCESSING: 20,
  MODEL_PROCESSING: 40,
  PROCESSED: 50,
  PROCESSED_WITH_ERROR: 60,
  REPORT_GENERATED: 70,
  EXPIRED: 80,
};

export const BusinessScoreRequestType = {
  NONE: 0,
  GST: 10,
  BANK: 30,
  BANK_GST: 20,
};

export const BusinessScoreRequestTypeByValue = (value) => {
  // return Object.keys(BusinessScoreRequestStatus).find(
  //   (key) => BusinessScoreRequestStatus[key] === value
  // );
  switch (value) {
    case BusinessScoreRequestType.GST:
      return { type: "GST" };
    case BusinessScoreRequestType.BANK:
      return { type: "Bank Statements" };
    case BusinessScoreRequestType.BANK_GST:
      return { type: "GST & Bank Statements" };
    default:
      return { type: "None" };
  }
};

export const GstReportRequestStatus = {
  NONE: 0,
  INIT: 10,
  SNAPSHOT_UPLOADED_S3: 20,
  PROCESSING: 30,
  COMPLETED: 40,
  ERROR: 50,
  ERROR_SNAPSHOT_UPLOADED: 60,
  ERROR_IN_UPLOAD: 70,
  DATA_NOT_FOUND: 80
};
export const GstReportType = {
  PURCHASE_RELATIONSHIP: "PURCHASE_RELATIONSHIP",
  SALES_RELATIONSHIP: "SALES_RELATIONSHIP",
};

export const GstReportTypeByValue = (value) => {
  switch (value) {
    case GstReportType.PURCHASE_RELATIONSHIP:
      return { key: "PURCHASE_RELATIONSHIP" };
    case GstReportType.SALES_RELATIONSHIP:
      return { key: "SALES_RELATIONSHIP" };
    default:
      return { key: "Invalid report type" };
  }
};

export const CallType = {
  SELF_ORG: "self-org",
  ON_BEHALF_OF_REFERRED_ORG: "on-be-half-of-referred-org",
  FOR_ANY_ORG: "for-any-org",
};

export const BusinessScoreRequestStatusByValue = (value) => {
  // return Object.keys(BusinessScoreRequestStatus).find(
  //   (key) => BusinessScoreRequestStatus[key] === value
  // );
  switch (value) {
    case BusinessScoreRequestStatus.DRAFT:
      return { status: "Draft" };
    case BusinessScoreRequestStatus.INIT:
      return { status: "In-Progress", color: "warning" };
    case BusinessScoreRequestStatus.TASK_PROCESSING:
      return { status: "Data-Syncing", color: "warning" };
    case BusinessScoreRequestStatus.MODEL_PROCESSING:
      return { status: "Data-Evaluation", color: "warning" };
    case BusinessScoreRequestStatus.PROCESSED:
      return { status: "Report-Generation", color: "warning" };
    case BusinessScoreRequestStatus.PROCESSED_WITH_ERROR:
      return { status: "Error", color: "error" };
    case BusinessScoreRequestStatus.REPORT_GENERATED:
      return { status: "Completed", color: "success" };
    case BusinessScoreRequestStatus.EXPIRED:
      return { status: "Expired" };
    default:
      return { status: "System-Error", color: "error" };
  }
};

export const BankAccountType = {
  SAVINGS: "SAVINGS",
  CURRENT: "CURRENT",
  OD: "OD",
  CC: "CC",
};

export const BankAccountTypeByValue = (value) => {
  switch (value) {
    case BankAccountType.SAVINGS:
      return { account: "Savings" };
    case BankAccountType.CURRENT:
      return { account: "Current" };
    case BankAccountType.CC:
      return { account: "Cash Credit" };
    case BankAccountType.OD:
      return { account: "Over Draft" };
    default:
      return { account: "Undefined" };
  }
};

export const BankAccountEntityType = {
  COMPANY: 0,
  PARTNERSHIP: 10,
  PROPRIETORSHIP: 20,
  INDIVIDUAL: 30,
  TRUST: 40,
};

export const BankAccountEntityTypeByValue = (value) => {
  switch (value) {
    case BankAccountEntityType.COMPANY:
      return { entity: "COMPANY" };
    case BankAccountEntityType.PARTNERSHIP:
      return { entity: "PARTNERSHIP" };
    case BankAccountEntityType.PROPRIETORSHIP:
      return { entity: "PROPRIETORSHIP" };
    case BankAccountEntityType.INDIVIDUAL:
      return { entity: "INDIVIDUAL" };
    case BankAccountEntityType.TRUST:
      return { entity: "TRUST" };
    default:
      return { entity: "Undefined" };
  }
};

export const BusinessBankAccountTaskStatus = {
  NONE: 0,
  INIT: 10,
  PROCESSED: 20,
  PROCESSING: 30,
  ERROR: 40,
};

export const BusinessDetailsType = {
  GST: 0,
  BANK: 10,
};
export const GstReportRequestStatusByValue = (value) => {
  // return Object.keys(ReportRequestStatus).find(
  //   (key) => ReportRequestStatus[key] === value
  // );
  switch (value) {
    case GstReportRequestStatus.INIT:
      return { status: "In-Progress", color: "warning" };
    case GstReportRequestStatus.SNAPSHOT_UPLOADED_S3:
      return { status: "In-Progress", color: "warning" };
    case GstReportRequestStatus.PROCESSING:
      return { status: "In-Progress", color: "warning" };
    case GstReportRequestStatus.COMPLETED:
      return { status: "Completed", color: "success" };
      case GstReportRequestStatus.DATA_NOT_FOUND:
        return { status: "Completed", color: "success" };
    case GstReportRequestStatus.ERROR:
      return { status: "Error", color: "error" };
    case GstReportRequestStatus.ERROR_SNAPSHOT_UPLOADED ||
      GstReportRequestStatus.ERROR_IN_UPLOAD:
      return { status: "Error", color: "error" };
    default:
      return { status: "System-Error", color: "error" };
  }
};
//-------------------------------------------------------------

export const BorrowerFileUploadStatus = {
  INIT: 10,
  PROCESSING: 20,
  PROCESSED: 30,
};

export const BorrowerFileUploadStatusByValue = (value) => {
  // return Object.keys(BusinessScoreRequestStatus).find(
  //   (key) => BusinessScoreRequestStatus[key] === value
  // );
  switch (value) {
    case BorrowerFileUploadStatus.INIT:
      return { status: "In-Progress", color: "warning" };
    case BorrowerFileUploadStatus.PROCESSING:
      return { status: "In-Progress", color: "warning" };
    case BorrowerFileUploadStatus.PROCESSED:
      return { status: "Completed", color: "success" };
    default:
      return { status: "Undefined", color: "error" };
  }
};

export const BorrowerFileDetailUploadStatus = {
  INIT: 10,
  PROCESSING: 20,
  PROCESSED: 30,
  PROCESSED_WITH_ERROR: 40,
  ERROR: 50,
};

export const BorrowerFileDetailUploadStatusByValue = (value) => {
  // return Object.keys(BusinessScoreRequestStatus).find(
  //   (key) => BusinessScoreRequestStatus[key] === value
  // );
  switch (value) {
    case BorrowerFileDetailUploadStatus.INIT:
      return { status: "In-Progress", color: "warning" };
    case BorrowerFileDetailUploadStatus.PROCESSING:
      return { status: "In-Progress", color: "warning" };
    case BorrowerFileDetailUploadStatus.PROCESSED:
      return { status: "Completed", color: "success" };
    case BorrowerFileDetailUploadStatus.PROCESSED_WITH_ERROR:
      return { status: "Warning", color: "warning" };
    case BorrowerFileDetailUploadStatus.ERROR:
      return { status: "error", color: "error" };
    default:
      return { status: "Undefined", color: "error" };
  }
};

//--------------------------------------------------------

export const SnackBarMessageType = {
  WARNING: "warning",
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
};

export const passwordRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

export const blockInvalidNumber = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
// export const passwordRegex = new RegExp(
//   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
// );

export const ApiClients = {
  ALL: "ALL",
  GBS_UI: "GBS_UI",
  VICCAS: "VICCAS",
  MOBILE_APP: "MOBILE_APP",
  LOS: "LOS",
};
export const GENERATEDBY = {
  USER: 0,
  SYSTEM: 1,
};

export const BSRSTATSSTATUS = {
  DRAFT: "DRAFT",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
