import { Typography } from "@mui/material";
import React from "react";

const TncTxtComponent = () => {
  return (
    <Typography variant="caption">
      By clicking the button below, you accept our{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://vayana.com/good-business-score/vayana-gbs-terms-conditions/"
      >
        {" "}
        T&C{" "}
      </a>{" "}
      and
      <a
        target="_blank"
        rel="noreferrer"
        href="https://vayana.com/privacy-policy/"
      >
        {" "}
        Privacy Policy
      </a>
      . Vayana reserves the right to store your data and score and share with
      FIs and corporates whom you have consented to.
    </Typography>
  );
};
export default TncTxtComponent;
