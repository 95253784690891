import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import CreateOrgComponent from "../../component/org/CreateOrgComponent";
import { appBarActions } from "../../store/AppBarSlice";
import { useDispatch } from "react-redux";

const OrgCreatePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appBarActions.setAppBarTitle("Create Organisation"));
  });

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <CreateOrgComponent />
      </Grid>
    </Grid>
  );
};

export default OrgCreatePage;
