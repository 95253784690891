import {Paper} from "@mui/material";
import * as React from "react";
import BorrowerFileListingComponent from "../../component/borrower/BorrowerFileListingComponent";
import TitleBarComponent from "../../component/common/TitleBarComponent";

const OrgBorrowerFileListPage = () => {
  return (
      <React.Fragment>
        <TitleBarComponent title={"Referred-Org File Uploads"}/>
        <Paper elevation={1} sx={{ paddingTop: 2, marginTop: 1}}>
          <BorrowerFileListingComponent />
        </Paper>
      </React.Fragment>
  );
};

export default OrgBorrowerFileListPage;
