import * as React from "react";
import { Card, Grid, Typography } from "@mui/material";

const StatsCardComponent = (props) => {
  return (
    <Card variant="outlined" sx={{ minWidth: 220 }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        mx={1}
        my={1}
      >
        <Grid item>{props.title}</Grid>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {props.value}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
export default StatsCardComponent;
