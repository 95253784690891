import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import StatsCardComponent from "../dashboard/StatsCardComponent";
import ShuriMonthlyChartComponent from "../dashboard/ShuriMonthlyChartComponent";
import StatsReportApi from "../../http/shuri/StatsReportApi";

const PlatformAdminDashboardShuriComponent = (props) => {
  const [total, setTotal] = React.useState(0);
  const [success, setSuccess] = React.useState(0);
  const [error, setError] = React.useState(0);

  useEffect(() => {
    setSuccess(0);
    setError(0);
    setTotal(0);

    StatsReportApi.getStatsStatusCount({
      duration: props.duration,
      apiClientId: props.apiClientId,
    }).then((response) => {
      var ttl = 0;
      response.forEach((res) => {
        if (res["statsStatus"] === "ERROR") {
          setError(res["count"]);
          ttl = ttl + res["count"];
        } else if (res["statsStatus"] === "SUCCESS") {
          setSuccess(res["count"]);
          ttl = ttl + res["count"];
        } 
      });
      setTotal(ttl);
    });
  }, [props.duration, props.apiClientId]);

  return (
    <Container sx={{ m: 2, p: 5 }}>
      <Typography variant="h2">Shuri Stats</Typography>
      <Box sx={{ p: 3 }} />
      <Grid container spacing={5}>
        <Grid item>
          <StatsCardComponent title="Total" value={total} />
        </Grid>
        <Grid item>
          <StatsCardComponent title="Success" value={success} />
        </Grid>
        <Grid item>
          <StatsCardComponent title="Error" value={error} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Box sx={{ p: 3 }} />

          <ShuriMonthlyChartComponent
            duration={props.duration}
            apiClientId={props.apiClientId}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
export default PlatformAdminDashboardShuriComponent;
