import {
  Typography, Box, Card, CardContent, Tab, Tabs
} from "@mui/material";
import React from "react";
import PropTypes from 'prop-types';
import GBSViewDetailGSTINComponent from "./GBSViewDetailGSTINComponent";
import GBSViewDetailBankAccountComponent from "./GBSViewDetailBankAccountComponent";
import NoDataFoundComponent from "../../common/NoDataFoundComponent";

const GBSViewDetailMainComponent = (props) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  return (
    <React.Fragment>
      <Card sx={{ marginTop: 2 }}>
        <CardContent sx={{ marginLeft: 2, marginRight: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab sx={{ textTransform: "none" }} label="GSTINs" {...a11yProps(0)} />
              <Tab sx={{ textTransform: "none" }} label="Bank A/Cs" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            {Object.values(props.gstinDetails).length > 0 ? (
            <GBSViewDetailGSTINComponent gstins={props.gstinDetails} />
            ): <NoDataFoundComponent />}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {Object.values(props.bankDetails).length > 0 ? (
            <GBSViewDetailBankAccountComponent banks={props.bankDetails} />
            ): <NoDataFoundComponent />}
          </CustomTabPanel>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default GBSViewDetailMainComponent;
