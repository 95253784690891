import RESTUtil from "../../util/RESTUtil";
import { HttpMethodType } from "../../util/HttpMethodType";

export default class AttachmentApi {
  static path = "v1/attachments";

  static download = ({ attachmentId }) => {
    let path = AttachmentApi.path + "/download/" + attachmentId;
    return RESTUtil.callApi({
      path: path,
      httpMethodType: HttpMethodType.GET,
      isDownloadRequest: true
    });
  };
}
