import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import StatsCardComponent from "../dashboard/StatsCardComponent";
import StatsBusinessApi from "../../http/v1_apis/StatsBusinessApi";
import StatsBusinessDetailsApi from "../../http/v1_apis/StatsBusinessDeatilsApi";

const PlatformAdminDashboardCommonComponent = (props) => {
  const [business, setBusiness] = React.useState(0);
  const [gstin, setGstin] = React.useState(0);
  const [bankAccount, setBankAccount] = React.useState(0);

  useEffect(() => {
    setBusiness(0);
    setGstin(0);
    setBankAccount(0);

    StatsBusinessApi.getStatsStatusCount({
      duration: props.duration,
      apiClientId: props.apiClientId,
    }).then((response) => {
      response.forEach((res) => {
        setBusiness(res["count"]);
      });
    });

    StatsBusinessDetailsApi.getStatsStatusCount({
      duration: props.duration,
      apiClientId: props.apiClientId,
    }).then((response) => {
      response.forEach((res) => {
        if (res["type"] === "BANK") {
          setBankAccount(res["count"]);
        } else if (res["type"] === "GST") {
          setGstin(res["count"]);
        }
      });
    });
  }, [props.duration, props.apiClientId]);

  return (
    <Container sx={{ m: 2, p: 5 }}>
      <Grid container spacing={5}>
        <Grid item>
          <StatsCardComponent title="Organisation" value={business} />
        </Grid>
        <Grid item>
          <StatsCardComponent title="GSTIN" value={gstin} />
        </Grid>
        <Grid item>
          <StatsCardComponent title="Bank Account" value={bankAccount} />
        </Grid>
      </Grid>
    </Container>
  );
};
export default PlatformAdminDashboardCommonComponent;
