import {
    Typography, Grid,
    Card, CardContent, Chip
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { BusinessScoreRequestStatusByValue, BusinessScoreRequestType } from "../../../util/Constant";
import DateUtil from "../../../util/DateUtil";


const GBSViewDetailBasicBSRInfoComponent = (props) => {
    const currentOrg = useSelector((state) => state.currentOrg);
    const pan = currentOrg?.organisation?.taxIdentifier;

    return (
        <React.Fragment>
            <Grid container spacing={3} sx={{ marginTop: 2 }}>
                <Grid item xs={6} md={4}>
                    {/* <Card sx={{minHeight: 250, justifyContent: "center", alignItems: "center", display: "flex"}}> */}
                    <Card sx={{ minHeight: 250, paddingLeft: 3, paddingTop: 1 }}>
                        <CardContent>
                            <Typography color="text.secondary">GBS Score</Typography>
                            <Grid container sx={{ height: '100%' }} mt={4} spacing={1.5} justifyContent="center">
                                <Grid item>
                                    <Typography variant="h2" color="#14780b" gutterBottom>
                                        <b>{props.bsrDetails.score == null ? "--" : props.bsrDetails.score}</b>
                                    </Typography>
                                </Grid>
                                <Grid item alignSelf="end" sx={{ marginBottom: 3 }} alignItems="center">
                                    <Typography variant="h5" color="#cdcdcd" gutterBottom>
                                        {props.bsrDetails.scoreRequestType === BusinessScoreRequestType.BANK_GST ? "/ 1000" : "/ 500"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={8}>
                    <Card sx={{ minHeight: 250, maxHeight: 500, paddingLeft: 3, paddingTop: 1 }}>
                        <CardContent>
                            <Typography color="text.secondary">Business Score Details</Typography>
                            <Grid container direction="column" spacing={3} mt={1}>
                                <Grid item container spacing={3}>
                                    <Grid item sx={{ width: 150 }} color="text.secondary">
                                        PAN :
                                    </Grid>
                                    <Grid item>
                                        {pan}
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={3} alignItems="center">
                                    <Grid item sx={{ width: 150 }} color="text.secondary">
                                        Status :
                                    </Grid>
                                    <Grid item>
                                        {/* {BusinessScoreRequestStatusByValue(props.bsrDetails.status).status} */}
                                        <Chip
                                            label={
                                                BusinessScoreRequestStatusByValue(
                                                    props.bsrDetails.status
                                                ).status
                                            }
                                            color={
                                                BusinessScoreRequestStatusByValue(
                                                    props.bsrDetails.status
                                                ).color
                                            }
                                            />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={3}>
                                    <Grid item sx={{ width: 150 }} color="text.secondary">
                                        Created At :
                                    </Grid>
                                    <Grid item>
                                        {DateUtil.convertToDateFormat(props.bsrDetails.createdAt)}
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={3}>
                                    <Grid item sx={{ width: 150 }} color="text.secondary">
                                        Updated at :
                                    </Grid>
                                    <Grid item>
                                        {DateUtil.convertToDateFormat(props.bsrDetails.updatedAt)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default GBSViewDetailBasicBSRInfoComponent;
