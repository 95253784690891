import React, { useState } from "react";
import SignUpComponent from "../../component/authorization/SignUpComponent";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import VerifySignupOtpComponent from "../../component/authorization/VerifySignupOtpComponent";
import { useHistory } from "react-router-dom";
import RoutePath from "../../util/RoutePath";

const SignupPageMobileApp = () => {
  const history = useHistory();
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");

  const setUserInfo = (firstName, lastName, email, mobileNumber, password) => {
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setMobileNumber(mobileNumber);
    setPassword(password);
    setOtpSuccess(true);
  };

  const onSuccessUserCreation = () => {
    history.push(RoutePath.APP_CLOSE_TAB_MOBILE_APP);
  };

  return (
    <Paper
      elevation={0}
      sx={{ background: "linear-gradient(to right, #D03C37, #284281)" }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={4}>
          <div>
            {!otpSuccess && <SignUpComponent setUserInfo={setUserInfo} />}
            {otpSuccess && (
              <VerifySignupOtpComponent
                firstName={firstName}
                lastName={lastName}
                email={email}
                mobileNumber={mobileNumber}
                password={password}
                onSuccessUserCreation={onSuccessUserCreation}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SignupPageMobileApp;
