import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  name: "loader",
  initialState: { value: false },
  reducers: {
    startLoader(state) {
      state.value = true;
    },
    stopLoader(state) {
      state.value = false;
    },
  },
});

export const loaderActions = loaderSlice.actions;
export default loaderSlice.reducer;
