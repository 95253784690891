import { Route, Switch, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentOrgActions } from "../store/CurrentOrgSlice";
import RoutePath from "../util/RoutePath";
import OrgDashboardPage from "./org/OrgDashboardPage";
import GbsCreatePage from "./gbs/GbsCreatePage";
import OrgBorrowerListPage from "./borrower/OrgBorrowerListPage";
import OrgBorrowerFileListPage from "./borrower/BorrowerFileListingPage";
import FiBorrowerDetailPage from "./borrower/BorrowerDetailPage";
import OrgBorrowerFileDetailPage from "./borrower/BorrowerFileDetailPage";
import ReportListingPage from "./report/ReportListingPage";
import ReportCreatePage from "./report/ReportCreatePage";
import GbsViewDetailsPage from "./gbs/GbsViewDetailsPage";
import { appBarActions } from "../store/AppBarSlice";
import V2BusinessScoreRequestBankApi from "../http/v2_apis/BusinessScoreRequestBankApi";
import { bankNameActions } from "../store/BankNameSlice";
import { industrySectorActions } from "../store/IndustrySectorSlice";
import NotFoundPage from "./NotFoundPage";
import MeekoIndustrySectorApi from "../http/meeko/MeekoIndustrySectorApi";

const OrgRoute = () => {
  const parmas = useParams();
  const dispatch = useDispatch();
  const myOrgList = useSelector((state) => state.myOrg);
  const industrySectorList = useSelector(
    (state) => state.industrySector.itemList
  );

  useEffect(() => {
    V2BusinessScoreRequestBankApi.getAllBanksData()
      .then((response) => {
        const bankNames = response.map((bank) => {
          return {
            label: bank.name,
            id: bank.id,
          };
        });
        dispatch(bankNameActions.addUpdateBankName(bankNames));
      })
      .catch((reject) => {});

    if (industrySectorList.length == 0) {
      MeekoIndustrySectorApi.getIndustrySector()
        .then((response) => {
          dispatch(industrySectorActions.addUpdateIndustrySector(response));
          industrySectorList = response;
        })
        .catch((reject) => {});
    }
  }, [dispatch, industrySectorList.length]);

  useEffect(() => {
    if (myOrgList.length > 0) {
      myOrgList?.map((item) => {
        if (item.organisation.id === parmas.orgId) {
          dispatch(currentOrgActions.setCurrentOrg(item));
          dispatch(appBarActions.setAppBarTitle(item.organisation.name));
        }
      });
    }
  }, [parmas.orgId,myOrgList, dispatch]);

  return (
    <React.Fragment>
      <Switch>
        <Route path={RoutePath.APP_ORG_DASHBOARD} exact>
          <OrgDashboardPage />
        </Route>
        <Route path={RoutePath.APP_GBS_CREATE} exact>
          <GbsCreatePage />
        </Route>
        <Route path={RoutePath.APP_GBS_DETAILS} exact>
          <GbsViewDetailsPage />
        </Route>
        <Route path={RoutePath.APP_ORG_BORROWER} exact>
          <OrgBorrowerListPage />
        </Route>
        <Route path={RoutePath.APP_ORG_BORROWER_FILE_LIST} exact>
          <OrgBorrowerFileListPage />
        </Route>
        <Route path={RoutePath.APP_ORG_BORROWER_FILE_DETAIL_LIST} exact>
          <OrgBorrowerFileDetailPage />
        </Route>
        <Route path={RoutePath.APP_ORG_BORROWER_DETAIL} exact>
          <FiBorrowerDetailPage />
        </Route>
        <Route path={RoutePath.APP_SHURI_REPORT_LIST} exact>
          <ReportListingPage />
        </Route>
        <Route path={RoutePath.APP_SHURI_REPORT_CREATE} exact>
          <ReportCreatePage />
        </Route>
        {/* <Route path={RoutePath.APP_SHURI_SALES_REPORT_CREATE} exact>
          <ShuriReportCreatePage />
        </Route> */}
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </React.Fragment>
  );
};
export default OrgRoute;
