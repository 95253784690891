import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Grid, Paper, Typography, Menu, MenuItem } from "@mui/material";
import RoutePath from "../../util/RoutePath";
import { useSelector } from "react-redux";
import { GstReportType } from "../../util/Constant";

export const ListingPageHeaderComponent = (props) => {
  const history = useHistory();
  const borrowerDetails = props.borrowerDetails;

  const myCurrentOrg = useSelector((state) => state.currentOrg);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openReportGenerationDropdown = Boolean(anchorEl);
  const handleGenerateReportBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGenerateReportBtnClose = () => {
    setAnchorEl(null);
  };
  const generateReportClickHandler = (event) => {
    const { reportType } = event.currentTarget.dataset;
    history.push({
      pathname: RoutePath.routeAppOrgGstReportCreatePage({
        orgId: myCurrentOrg.organisation.id,
        reportType:
          reportType === GstReportType.PURCHASE_RELATIONSHIP
            ? "purchase"
            : "sales",
      }),
      state: borrowerDetails,
    });
  };

  return (
    <Paper>
      <Grid container justifyContent="space-between">
        <Grid item px={2} py={2}>
          <Typography component="h1" variant="h5">
            Report
          </Typography>
        </Grid>
        <Grid item px={2} py={2}>
          <Button
            id="generate-report-button"
            aria-controls={
              openReportGenerationDropdown ? "generate-report-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={openReportGenerationDropdown ? "true" : undefined}
            variant="contained"
            color="primary"
            onClick={handleGenerateReportBtnClick}
          >
            Generate Report
          </Button>
          <Menu
            id="generate-report-menu"
            anchorEl={anchorEl}
            open={openReportGenerationDropdown}
            fullWidth
            onClose={handleGenerateReportBtnClose}
            MenuListProps={{
              "aria-labelledby": "generate-report-button",
            }}
          >
            <MenuItem
              data-report-type={GstReportType.PURCHASE_RELATIONSHIP}
              onClick={generateReportClickHandler}
              key="purchase"
            >
              Purchase Report
            </MenuItem>
            <MenuItem
              data-report-type={GstReportType.SALES_RELATIONSHIP}
              onClick={generateReportClickHandler}
              key="sales"
            >
              Sales Report
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Paper>
  );
};
