import React, { useEffect } from "react";
import SignInComponent from "../../component/authorization/SigninComponent";
import { useHistory } from "react-router-dom";
import RoutePath from "../../util/RoutePath";
import AppUtil from "../../util/AppUtil";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";

const SignInPage = () => {
  const history = useHistory();

  useEffect(() => {
    if (AppUtil.getJwtToken()) {
      // TODO : Check if user id is present in localstorage else if not self org call marke user id update
      history.push(RoutePath.KEY_HOME);
    }
  }, [history]);

  return (
    <Paper
      elevation={0}
      sx={{ background: "linear-gradient(to right, #D03C37, #284281)" }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={4}>
          <SignInComponent />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SignInPage;
