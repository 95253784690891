import { List, TableBody, TableContainer, Table, TableRow, Typography } from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiTableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { BankAccountType, BankAccountTypeByValue } from "../../../util/Constant";

const GBSReviewBankStatementComponent = (prop) => {
    let i = 0;
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    const TableCell = styled(MuiTableCell)(({ theme }) => ({
        borderBottom: "none"
    }));

    return (
        <List key="reviewBankStatements">
            {Object.values(prop.bankDetails).forEach((item) => {
                i += 1;
                if (item.active) {
                    return (
                        <Accordion item >
                            <AccordionSummary
                                id={item.id}
                            >
                                {/* <Grid container justifyContent="space-around" spacing={6}>
                                    <Grid item>
                                        <Typography>Bank : {item.bankName}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>Account Number : {item.accountNumber}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>Account Type : {item.accountType}</Typography>
                                    </Grid>
                                </Grid> */}
                                <Typography>Bank {i}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer>
                                    <Table>
                                        <TableBody alignItems="center">
                                            <TableRow>
                                                <TableCell>Bank :&nbsp; <b>{item.bankName}</b></TableCell>
                                                <TableCell>Account Number :&nbsp; <b>{item.accountNumber}</b></TableCell>
                                                <TableCell>Account Type :&nbsp; <b>{BankAccountTypeByValue(item.accountType).account}</b></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                {(item.bankAccountHolderName !== "") && (<TableCell>Account Holder name :&nbsp; <b>{item.bankAccountHolderName}</b></TableCell>)}
                                                {(item.accountType === BankAccountType.CC || item.accountType === BankAccountType.OD) && (<TableCell>Sanction Limit :&nbsp; <b>{item.sanctionLimit}</b></TableCell>)}
                                            </TableRow>
                                            {(Object.values(item.monthlyDrawingPowerLimit).length > 0) && (

                                                <TableRow alignItems="start" sx={{ marginTop: "-100px" }}>
                                                    <TableCell>Monthly Drawing Power Limit </TableCell>
                                                    <TableCell>
                                                        {/* <List key="monthTet">
                                                    {Object.values(item.monthlyDrawingLimits).map((monthlyLimit) => {
                                                        return (<ListItem sx={{paddingBottom: 0.2}}>
                                                            <Grid container spacing={5}>
                                                                <Grid item>
                                                                    From : &nbsp; <b>{monthlyLimit.from}</b>
                                                                </Grid>
                                                                <Grid item>
                                                                    Limit :&nbsp; <b>{monthlyLimit.limit}</b>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>);
                                                     })}
                                                    </List>   */}
                                                        {Object.values(item.monthlyDrawingPowerLimit).map((monthlyLimit) => {
                                                            return (
                                                                <TableRow key={monthlyLimit.from}>
                                                                    <TableCell>From : <b>{monthlyLimit.from}</b></TableCell>
                                                                    <TableCell>Limit : <b>{monthlyLimit.limit}</b></TableCell>
                                                                </TableRow>);
                                                        })}
                                                    </TableCell>


                                                </TableRow>)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <ListItem>
                                    <Grid container spacing={5}>
                                        <Grid item>
                                            <Typography>Bank :&nbsp; <b>{item.bankName}</b></Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>Account Number :&nbsp; <b>{item.accountNumber}</b></Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>Account Type :&nbsp; <b>{item.accountType}</b></Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem> */}
                                {/* {(item.accountType == "CASH_CREDIT" || item.accountType == "OVER_DRAFT") && (
                                <ListItem>
                                <Grid container spacing={5}>
                                            <Grid item>
                                                    <Typography>Sanction Limit :&nbsp; <b>{item.sanctionLimit}</b></Typography>
                                            </Grid>
                                            <Grid item>
                                                        <Typography>Monthly Drawing Power Limit</Typography>
                                                        <List key="monthTet">
                                                    {Object.values(item.monthlyDrawingLimits).map((monthlyLimit) => {
                                                        return (<ListItem sx={{paddingTop: 1, paddingLeft: 3}}>
                                                                    <Grid container spacing={5}>
                                                                        <Grid item>
                                                                            <Typography>From : &nbsp; <b>{monthlyLimit.from}</b></Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography>Limit :&nbsp; <b>{monthlyLimit.limit}</b></Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </ListItem>);
                                                            })}
                                                        </List>
                                            </Grid>
                                </Grid>
                                    </ListItem>
                                )} */}

                            </AccordionDetails>
                        </Accordion>
                    );

                } else {
                    i -= 1;
                }
            })}
        </List>
    );
}

export default GBSReviewBankStatementComponent;