import * as React from "react";
import { Chip, CircularProgress, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import SystemDefault from "../../util/SystemDefault";
import PaginationStatusBarComponent from "../common/PaginationStatusBarComponent";
import ListingCardComponent from "../common/ListingCardComponent";
import DateUtil from "../../util/DateUtil";
import {
  BusinessScoreRequestStatus,
  BusinessScoreRequestStatusByValue,
  BusinessScoreRequestTypeByValue, SnackBarMessageType
} from "../../util/Constant";
import Paper from "@mui/material/Paper";
import NoDataFoundComponent from "../common/NoDataFoundComponent";
import BusinessScoreShareRequestApi from "../../http/v1_apis/ShareScoreApi";
import { useDispatch, useSelector } from "react-redux";
import AttachmentApi from "../../http/v1_apis/AttachmentApi";
import ShareScoreApi from "../../http/v1_apis/ShareScoreApi";
import { SnackBarActions } from "../../store/SnackBarSlice";

const BorrowerScoreListingComponent = (props) => {
  const currentOrg = useSelector((state) => state.currentOrg);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);
  const [records, setRecords] = React.useState(SystemDefault.PAGE_SIZE[0]);
  const [loading, setLoading] = React.useState(false);
  const [borrowerScores, setBorrowerScores] = React.useState({});
  const [borrowerScoreResults, setBorrowerScoreResults] = React.useState([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    if (currentOrg?.organisation?.id !== undefined) {
      BusinessScoreShareRequestApi.search({
        searchMode: SystemDefault.SEARCH_MODE,
        page: page,
        records: records,
        filter: {
          borrowerId: props.borrowerId,
        },
        select: { scoreRequest: true, businessUnit: true },
        sort: { createdAtDesc: true },
      }).then((response) => {
        if(response.results != null){
          setBorrowerScoreResults(response.results);
        }
        if(response.objects != null){
          setBorrowerScores(response.objects.scoreRequests);
        }
        setPageCount(Math.ceil(response.total / records));
        setLoading(false);
      }).catch((error)=> {
        dispatch(
          SnackBarActions.openSnackBar({
            message: error.errorMessage,
            messageType: SnackBarMessageType.ERROR
          })
        )
      });
    }
  }, [page, records, currentOrg, dispatch, props.borrowerId]);

  const onPageChangeHandler = (value) => {
    setPage(value);
  };
  const onRecordsChangeHandler = (value) => {
    setRecords(value);
  };

  const renderAvatar = (businessScoreRequest) => {
    return (
      <React.Fragment>
        <Avatar
          variant={"square"}
          sx={{
            background: "",
            width: "90%",
            height: "90%",
            fontSize: 50,
            fontWeight: "bold",
          }}
        >
          Q{businessScoreRequest.quarter}
        </Avatar>
        <Avatar
          variant={"square"}
          sx={{
            background: "",
            width: "90%",
            height: "10%",
            fontSize: 25,
            fontWeight: "bold",
          }}
        >
          {businessScoreRequest.year}
        </Avatar>
      </React.Fragment>
    );
  };

  // const renderGSTIN = (businessScoreRequest) => {
  //   const chipGstin = [];
  //   const allGstin = [];
  //   for (let i = 0; i < businessScoreRequest.businessUnitId.length; i++) {
  //     let businessUnitItemId = businessScoreRequest.businessUnitId[i];
  //     allGstin.push(
  //       <div key={businessUnits[businessUnitItemId].gstin}>
  //         {businessUnits[businessUnitItemId].gstin}
  //       </div>
  //     );
  //     if (i < 2) {
  //       chipGstin.push(
  //         <Chip
  //           label={businessUnits[businessUnitItemId].gstin}
  //           key={businessUnits[businessUnitItemId].gstin}
  //         />
  //       );
  //     }
  //     if (i === 2) {
  //       chipGstin.push(<Chip label="..." />);
  //     }
  //   }

  //   return (
  //     <Tooltip title={<div>{allGstin}</div>} arrow>
  //       <Typography variant="caption" display="inline">
  //         GSTIN : {chipGstin}
  //       </Typography>
  //     </Tooltip>
  //   );
  // };


  const renderScoreRequestType = (businessScoreRequest) => {
    return (
        <Typography variant="caption" display="inline">
          Score Request Type : 
          <Chip
            label={BusinessScoreRequestTypeByValue(businessScoreRequest.scoreRequestType).type}
          />
        </Typography>
    );
  };


  const download = (reportId) => {
    AttachmentApi.download({
      attachmentId: reportId
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((error)=> {
      dispatch(
        SnackBarActions.openSnackBar({
          message: error.errorMessage,
          messageType: SnackBarMessageType.ERROR
        })
      )
    });
  };
  const onClickDownloadHandler = (item) => {
    download(item.detailReportId);
  };

  const onClickGenrateReportHandler = (item) => {
    ShareScoreApi.generateReport({ scoreShareId: item.id })
      .then((response) => {
        download(response.detailReportId);
      })
      .catch((reject) => {
        dispatch(
          SnackBarActions.openSnackBar({
            message: reject.errorMessage,
            messageType: SnackBarMessageType.ERROR
          })
        )
      });
  };
  const loadData = () => {
    return (
      <React.Fragment>
        {borrowerScoreResults.map((item) => {
          return (
            <ListingCardComponent
              key={borrowerScores[item.scoreRequestId]?.id}
              item={item}
              avatar={renderAvatar(borrowerScores[item.scoreRequestId])}
              title={DateUtil.convertToDateFormat(
                borrowerScores[item.scoreRequestId]?.createdAt
              )}
              subTitle1={renderScoreRequestType(borrowerScores[item.scoreRequestId])}

              column1={
                <Typography variant="h6">
                  {borrowerScores[item.scoreRequestId]?.score}
                </Typography>
              }
              column2={
                <Chip
                  label={
                    BusinessScoreRequestStatusByValue(
                      borrowerScores[item.scoreRequestId]?.status
                    ).status
                  }
                  color={
                    BusinessScoreRequestStatusByValue(
                      borrowerScores[item.scoreRequestId]?.status
                    ).color
                  }
                />
              }
              action1={
                borrowerScores[item.scoreRequestId]?.status ===
                  BusinessScoreRequestStatus.REPORT_GENERATED && item.detailReportId
                  ? "Download"
                  : "Generate Report"
              }
              actionHandler1={
                borrowerScores[item.scoreRequestId]?.status ===
                  BusinessScoreRequestStatus.REPORT_GENERATED && item.detailReportId
                  ? onClickDownloadHandler
                  : onClickGenrateReportHandler
              }
            />
          );
        })}
        <PaginationStatusBarComponent
          pageCount={pageCount}
          page={page}
          onPageChangeHandler={onPageChangeHandler}
          records={records}
          onRecordsChangeHandler={onRecordsChangeHandler}
        />
      </React.Fragment>
    );
  };

  return (
    <Paper elevation={1}>
      {loading && <CircularProgress size={24} />}
      {!loading && Object.values(borrowerScores).length === 0 && <NoDataFoundComponent />}
      {!loading && loadData()}
    </Paper>
  );
};

export default BorrowerScoreListingComponent;
