import { createSlice } from "@reduxjs/toolkit";

const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState: {bankDetails: {}, bankDetailsVerified: false },
  reducers: {
    resetBankDetails(state, action){
      state.bankDetails = {};
      state.bankDetailsVerified = false;
    },
    changeCreateMode(state, action) {
      state.bankDetails[action.payload.id].createMode = action.payload.value;
    },
    changeActiveStatus(state, action) {
      state.bankDetails[action.payload.id].active = action.payload.value;
    },
    updateBankDetailsVerified(state, action){
      state.bankDetailsVerified = action.payload.value;
    },
    addUpdateBankDetails(state, action) {
      state.bankDetails = action.payload;
    },
    bankNameChange(state, action){
      state.bankDetails[action.payload.id].bankName = action.payload.bankName;
      state.bankDetails[action.payload.id].bankId = action.payload.bankId;
    },
    removeBankName(state, action){
      state.bankDetails[action.payload.id].bankName = "";
      state.bankDetails[action.payload.id].bankId = "";
    },
    accountNumberChange(state, action){
      state.bankDetails[action.payload.id].accountNumber = action.payload.accountNumber;
    },
    bankAccountHolderNameChange(state, action){
      state.bankDetails[action.payload.id].bankAccountHolderName = action.payload.bankAccountHolderName;
    },
    accountTypeChange(state, action){
      state.bankDetails[action.payload.id].accountType = action.payload.accountType;
    },
    sanctionLimitChange(state, action){
      state.bankDetails[action.payload.id].sanctionLimit = action.payload.sanctionLimit;
    },
    fileUploadChange(state, action){
      state.bankDetails[action.payload.id].files = action.payload.files;
    },
    addMonthlyDrawingLimit(state, action){
      state.bankDetails[action.payload.id].monthlyDrawingLimits = action.payload.monthlyDrawingLimits;
    },
    addBankStatementFile(state, action){
      state.bankDetails[action.payload.id].files = action.payload.file;
    },
    removeBankStatementFile(state, action){
      delete state.bankDetails[action.payload.id].files[action.payload.bankStatementId];
    },
    updateBankStatementFileId(state, action){
      state.bankDetails[action.payload.id].files[action.payload.bankStatementId].fileId = action.payload.fileId;
    },
    updateFilePassword(state, action) {
      state.bankDetails[action.payload.id].files[action.payload.bankStatementId].password = action.payload.password;
    },
    updateFileVerification(state, action) {
      state.bankDetails[action.payload.id].files[action.payload.bankStatementId].isFileVerified = action.payload.fileVerificationStatus;
    },
    updateBankReferenceId(state, action) {
      state.bankDetails[action.payload.id].bankRefId = action.payload.bankRefId;
    },
    updateBankNameIsValid(state, action) {
      state.bankDetails[action.payload.id].bankNameIsValid = action.payload.status;
    },
    updateAccountNumberIsValid(state, action) {
      state.bankDetails[action.payload.id].accountNumberIsValid = action.payload.status;
    },
    updateAccountTypeIsValid(state, action) {
      state.bankDetails[action.payload.id].accountTypeIsValid = action.payload.status;
    },
    removeBankDetail(state, action) {
      delete state.bankDetails[action.payload.id];
    }
  },
});

export const bankDetailsActions = bankDetailsSlice.actions;
export default bankDetailsSlice.reducer;
