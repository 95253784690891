import LocalStorageUtil from "./LocalStorageUtil";
import axios from "axios";
import { jwtTokenActions } from "../store/JwtTokenSlice";
import store from "../store/Index";

export default class AppUtil {
  //  static isDebug() {
  //    return process.env.REACT_APP_PROJECT_ENVIRONMENT_DEBUG === "true";
  //  }

  static isProduction() {
    return process.env.REACT_APP_PROJECT_ENVIRONMENT_GROUP === "PROD";
  }

  static getApiBaseUrl() {
    return process.env.REACT_APP_XAVIER_SERVICE_API_BASE_URL;
  }

  static getGbsAppSourceToken() {
    return process.env.REACT_APP_GBS_SOURCE_TOKEN;
  }

  static getTheodoreApiBaseUrl() {
    return process.env.REACT_APP_THEODORE_API_SERVICE_BASE_URL;
  }
  static getMeekoApiBaseUrl() {
    return process.env.REACT_APP_MEEKO_API_SERVICE_BASE_URL;
  }
  static getShuriApiBaseUrl() {
    return process.env.REACT_APP_SHURI_SERVICE_API_BASE_URL;
  }

  static getPlatformOrgId() {
    return process.env.REACT_APP_ORGANISATION_PLATFORM_ID;
  }

  static getUserPlatformAdministratorId() {
    return process.env.REACT_APP_USER_PLATFORM_ADMINISTRATOR_ID;
  }

  static getVayanaFIOrgId() {
    return process.env.REACT_APP_VAYANA_FI_ORGANISATION_ID;
  }

  static navigate(routePath, newWindow = false, state = {}) {
    if (routePath.charAt(0) === "/") {
      AppUtil.getBrowserHistory().push(routePath, state);
    } else {
      window.open(routePath, newWindow ? "_blank" : "_self");
    }
  }

  static setJwtToken(authToken) {
    authToken = "Bearer " + authToken;
    LocalStorageUtil.set("jwt-token", authToken);
  }

  static getJwtToken() {
    return LocalStorageUtil.get("jwt-token");
  }

  static removeJwtToken() {
    LocalStorageUtil.remove("jwt-token");
  }

  static setCurrentOrgId(orgId) {
    LocalStorageUtil.set("org-id", orgId);
  }

  static getCurrentOrgId() {
    return LocalStorageUtil.get("org-id");
  }

  static removeCurrentOrgId() {
    return LocalStorageUtil.remove("org-id");
  }

  static setUserId(orgId) {
    LocalStorageUtil.set("user-id", orgId);
  }

  static getUserId() {
    return LocalStorageUtil.get("user-id");
  }

  static removeUserId() {
    return LocalStorageUtil.remove("user-id");
  }

  static setExpireTime(expireTime) {
    LocalStorageUtil.set("expireTime", expireTime);
  }

  static getExpireTime() {
    return LocalStorageUtil.get("expireTime");
  }
  static removeExpireTime() {
    return LocalStorageUtil.remove("expireTime");
  }
}

export async function refreshJwtToken(config) {
  let expireTime = AppUtil.getExpireTime();
  let authToken = AppUtil.getJwtToken();
  if (
    expireTime &&
    authToken &&
    new Date(Number(expireTime)) - new Date() <= 4 * 60 * 1000
  ) {
    await axios
      .put(`${AppUtil.getTheodoreApiBaseUrl()}/authtokens`, null, {
        headers: { Authorization: authToken },
      })
      .then((response) => {
        AppUtil.setJwtToken(response.data.data.token);
        AppUtil.setExpireTime(response.data.data.expiry);
        store.dispatch(jwtTokenActions.setJwtToken(response.data.data.token));
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
  return config;
}
