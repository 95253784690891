import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import RoutePath from "../../util/RoutePath";
import { useDispatch, useSelector } from "react-redux";
import OrgApi from "../../http/theodore/OrgApi";
import SystemDefault from "../../util/SystemDefault";
import StringUtil from "../../util/StringUtil";
import TncTxtComponent from "../common/TncTxtComponent";
import BusinessApi from "../../http/v1_apis/BusinessApi";
import { loaderActions } from "../../store/LoaderSlice";
import AuthApi from "../../http/theodore/AuthApi";
import AppUtil from "../../util/AppUtil";

const CreateOrgComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loader.value);
  const isDisabled = loading;
  const [name, setName] = useState("");
  const [nameTouch, setNameTouch] = useState(false);
  const [nameIsValid, setNameIsValid] = useState(false);
  const [nameErrMsg, setNameErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );

  const taxIdentifierType = "PAN";
  const country = "India";

  const [pan, setPan] = useState("");
  const [panTouch, setPanTouch] = useState(false);
  const [panIsValid, setPanIsValid] = useState(false);
  const [panIsExist, setPanIsExist] = useState(false);
  const [panIsUpdated, setPanIsUpdated] = useState(false);
  const [panIsInprogress, setPanIsInprogress] = useState(false);
  const [panErrMsg, setPanErrMsg] = useState(
    SystemDefault.DEFAULT_ERROR_MESSAGE
  );

  const inputNameInvalid = !nameIsValid && nameTouch;
  const inputPanInvalid = !panIsValid && panTouch;
  const inputPanExistInvalid = inputPanInvalid || panIsExist;
  const nameInputChangeHandler = (event) => {
    setName(event.target.value);
    setNameTouch(true);
    if (event.target.value.trim() === "") {
      setNameIsValid(false);
      setNameErrMsg(SystemDefault.DEFAULT_ERROR_MESSAGE);
    } else {
      setNameIsValid(true);
    }
  };
  const panInputChangeHandler = (event) => {
    setPanIsExist(true);
    setPanIsUpdated(true);
    const inputPan = event.target.value.toUpperCase().trim();
    setPan(inputPan);
    setPanTouch(true);
    const isValidPan = StringUtil.isValidPan(inputPan);
    if (!isValidPan.result) {
      setPanIsValid(false);
      setPanErrMsg(isValidPan.errMsg);
    } else {
      setPanIsValid(true);
      setPanErrMsg(isValidPan.errMsg);
    }
  };

  const panFocusOutEventHandler = () => {
    if (!inputPanInvalid && panTouch && panIsUpdated) {
      setPanIsInprogress(true);
      OrgApi.isExistTaxid({ taxid: pan }).then((response) => {
        setPanIsUpdated(false);
        setPanIsExist(response.data.orgPresent);
        if (response.data.orgPresent) {
          setPanErrMsg("PAN already exists in system");
          setPanIsValid(false);
        }
        setPanIsInprogress(false);
      });
    }
  };

  // const adminEmailInputChangeHandler = (event) => {
  //   const inputAdminEmail = event.target.value.toLowerCase().trim();
  //   setAdminEmail(inputAdminEmail);
  //   setAdminEmailTouch(true);
  //
  //   const isValidAdminEmail = StringUtil.isValidEmail(inputAdminEmail);
  //   if (!isValidAdminEmail.result) {
  //     setAdminEmailIsValid(false);
  //     setAdminEmailErrMsg(isValidAdminEmail.errMsg);
  //   } else {
  //     setAdminEmailIsValid(true);
  //   }
  // };

  // const tncInputChangeHandler = (event) => {
  //   setTnc(event.target.checked);
  //   setTncTouch(true);
  //   setTncIsValid(event.target.checked);
  // };

  // const industrySectorChangeHandler = (event) => {
  //   setIndustrySector(event.target.value);
  //   setIndustrySectorTouch(true);
  //   setIndustrySectorIsValid(true);
  // };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    setNameTouch(true);
    setPanTouch(true);
    // setAdminEmailTouch(true);
    // setIndustrySectorTouch(true);
    // setTncTouch(true);

    if (
      !nameIsValid ||
      !panIsValid
      // !adminEmailIsValid ||
      // !industrySectorIsValid
      // !tncIsValid
    ) {
      return;
    }
    dispatch(loaderActions.startLoader());
    BusinessApi.createBusiness({
      name,
      pan,
      // adminEmail,
      // industrySectorId: industrySector,
    })
      .then((response) => {
        dispatch(loaderActions.stopLoader());
        AuthApi.updateToken().then((response) => {
          AppUtil.setJwtToken(response.data.token);
        });
        history.push(RoutePath.KEY_HOME);
      })
      .catch((reject) => {
        dispatch(loaderActions.stopLoader());
      });
  };

  const panVerificationComponent = () => {
    if (panIsInprogress) {
      return <CircularProgress />;
    } else if (!inputPanInvalid) {
      return <DoneIcon color="success" />;
    }
  };
  return (
    <Paper elevation={3}>
      <Container component="main" maxWidth="sm">
        <Box px={5} pb={10} pt={5}>
          {/*<img src="/assets/images/vay.png" alt="mobile" />*/}

          <Typography variant="h5" mb={5} align="center">
            Create Organisation
          </Typography>

          <form onSubmit={onSubmitHandler} noValidate>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="stretch"
              // alignContent="center"
              spacing={2}
            >
              <Grid item>
                <TextField
                  error={inputNameInvalid}
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  helperText={inputNameInvalid && nameErrMsg}
                  onChange={nameInputChangeHandler}
                />
              </Grid>
              <Grid item>
                <TextField
                  // error={inputNameInvalid}
                  variant="outlined"
                  required
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                  autoFocus
                  defaultValue={country}
                  disabled
                />
              </Grid>
              <Grid item>
                <TextField
                  // error={inputNameInvalid}
                  variant="outlined"
                  required
                  fullWidth
                  id="taxIdentifierType"
                  label="Tax Identifier Type"
                  name="Tax Identifier Type"
                  autoFocus
                  defaultValue={taxIdentifierType}
                  disabled
                />
              </Grid>
              <Grid item>
                <Grid container spacing={1} direction="row" alignItems="center">
                  <Grid item xs={11}>
                    <TextField
                      error={inputPanExistInvalid}
                      variant="outlined"
                      required
                      fullWidth
                      id="pan"
                      label="PAN"
                      name="pan"
                      autoComplete="pan"
                      autoFocus
                      value={pan}
                      helperText={inputPanExistInvalid && panErrMsg}
                      onChange={panInputChangeHandler}
                      onBlur={panFocusOutEventHandler}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {panVerificationComponent()}
                  </Grid>
                </Grid>
              </Grid>
              {/*<Grid item>*/}
              {/*  <TextField*/}
              {/*    type="email"*/}
              {/*    error={inputAdminEmailInvalid}*/}
              {/*    value={adminEmail}*/}
              {/*    required*/}
              {/*    id="adminEmail"*/}
              {/*    label="Admin Email"*/}
              {/*    fullWidth*/}
              {/*    helperText={inputAdminEmailInvalid && adminEmailErrMsg}*/}
              {/*    onChange={adminEmailInputChangeHandler}*/}
              {/*  />*/}
              {/*</Grid>*/}
              {/* <Grid item>
                <FormControl required fullWidth>
                  <InputLabel id="industrySectorLabel">
                    Industry Sector
                  </InputLabel>
                  <Select
                    error={inputIndustrySectorInvalid}
                    labelId="demo-simple-select-label"
                    id="industrySector"
                    label="Industry Sector"
                    value={industrySector}
                    onChange={industrySectorChangeHandler}
                  >
                    {industrySectorList.map((item) => {
                      return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText error={true}>
                    {inputIndustrySectorInvalid && industrySectorErrMsg}
                  </FormHelperText>
                </FormControl>
              </Grid> */}
              <Grid item>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {/*<Checkbox*/}
                  {/*  checked={tnc}*/}
                  {/*  inputProps={{ "aria-label": "primary checkbox" }}*/}
                  {/*  onChange={tncInputChangeHandler}*/}
                  {/*/>*/}
                  <TncTxtComponent />
                </Box>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isDisabled}
                >
                  Create{" "}
                  {isDisabled && <CircularProgress sx={{ mx: 2 }} size={25} />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Paper>
  );
};

export default CreateOrgComponent;
