import { createSlice } from "@reduxjs/toolkit";

const StepperSlice = createSlice({
  name: "stepper",
  initialState: {activeStep: 0, steps: {}},
  reducers: {
    setActiveStep(state, action){
        state.activeStep = action.payload.value;
    },
    addStep(state, action){
        state.steps = {
            ...state.steps,
            [action.payload.step]: {
                label: action.payload.label,
                accordianId: "",
                completed: false,
                skipped: false,
                skippable: true
            }
        }
    },
    setStepCompletedOrNot(state, action){
        state.steps[action.payload.step].completed = action.payload.completed;
    },
    setStepSkippedOrNot(state, action){
        state.steps[action.payload.step].skipped = action.payload.skipped;
    },
    setStepSkippableOrNot(state,action){
        state.steps[action.payload.step].skippable = action.payload.skippable;
    },
    resetSteps(state){
        state = {activeStep: 0, steps: {}};
    },
    changeAccordianId(state, action) {
        state.steps[state.activeStep].accordianId = action.payload.value;
    }
  },
});

export const stepperActions = StepperSlice.actions;
export default StepperSlice.reducer;
